import { Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import DeleteIcon from '@material-ui/icons/Delete';
// import SaveAsIcon from '@material-ui/icons/FileCopy';
// import PrintIcon from '@material-ui/icons/Print';
// import ExportIcon from '@material-ui/icons/Reply';
import SaveIcon from '@material-ui/icons/Save';
import SettingsIcon from '@material-ui/icons/Settings';
import React, { useRef, useState } from 'react';
import styled from 'styled-components';

interface Props {
	onSaveClick: () => void;
	onDeleteClick: () => void;
	onPrintViewClick: () => void;
	onSettingsClick: () => void;
}

const SaveButtonWithMenu = ({
	onSaveClick,
	onDeleteClick,
	onPrintViewClick,
	onSettingsClick,
}: Props) => {
	const anchorRef = useRef(null);
	const [buttonMenuOpen, setButtonMenuOpen] = useState(false);
	const handleSaveClick = () => {
		onSaveClick();
	};
	const handleDeleteClick = () => {
		onDeleteClick();
		setButtonMenuOpen(false);
	};
	const handleSettingsClick = () => {
		onSettingsClick();
		setButtonMenuOpen(false);
	};
	const handleToggle = () => {
		setButtonMenuOpen(!buttonMenuOpen);
	};
	const handleMenuClose = () => {
		setButtonMenuOpen(false);
	};
	const handlePrintView = () => {
		onPrintViewClick();
		setButtonMenuOpen(false);
	};
	return (
		<>
			<ButtonGroup
				variant="contained"
				color="primary"
				ref={anchorRef}
				aria-label="split button">
				<Button startIcon={<SaveIcon />} onClick={handleSaveClick}>
					SAVE
				</Button>
				<Button color="primary" size="small" onClick={handleToggle}>
					<ArrowDropDownIcon />
				</Button>
			</ButtonGroup>
			<Popper
				open={buttonMenuOpen}
				anchorEl={anchorRef.current}
				transition
				disablePortal
				style={{ zIndex: 1 }}>
				{({ TransitionProps, placement }) => (
					<Grow
						{...TransitionProps}
						style={{
							transformOrigin:
								placement === 'bottom'
									? 'center top'
									: 'center bottom',
						}}>
						<Paper>
							<ClickAwayListener onClickAway={handleMenuClose}>
								<MenuList id="split-button-menu">
									{/* <MenuItem>
										<ListItemIcon>
											<SaveAsIcon fontSize="small" />
										</ListItemIcon>
										<Typography variant="body2">
											Save as...
										</Typography>
									</MenuItem>
									<MenuItem>
										<ListItemIcon>
											<PrintIcon fontSize="small" />
										</ListItemIcon>
										<Typography
											variant="body2"
											onClick={handlePrintView}>
											Print View
										</Typography>
									</MenuItem>
									<MenuItem>
										<ListItemIcon>
											<ExportIcon
												style={{
													transform: 'scaleX(-1)',
												}}
												fontSize="small"
											/>
										</ListItemIcon>
										<Typography variant="body2">
											Export
										</Typography>
									</MenuItem> */}
									<MenuItem onClick={handleSettingsClick}>
										<ListItemIcon>
											<SettingsIcon fontSize="small" />
										</ListItemIcon>
										<Typography variant="body2">
											Settings
										</Typography>
									</MenuItem>
									<MenuItem onClick={handleDeleteClick}>
										<ListItemIcon>
											<DeleteIcon fontSize="small" />
										</ListItemIcon>
										<Typography variant="body2">
											Delete
										</Typography>
									</MenuItem>
								</MenuList>
							</ClickAwayListener>
						</Paper>
					</Grow>
				)}
			</Popper>
		</>
	);
};

const ListItemIcon = styled.div`
	color: rgba(0, 0, 0, 0.54);
	display: inline-flex;
	min-width: 40px;
	flex-shrink: 0;
`;

export default SaveButtonWithMenu;
