import DataService from './DataService';

import { StyleDataType, ServiceLayer } from './types';

export default class StyleDataService extends DataService {
	get layers(): ServiceLayer[] {
		return (this.data as StyleDataType)?.layers as ServiceLayer[];
	}

	validData(data: StyleDataType): boolean {
		if (
			typeof data === 'object' &&
			'layers' in data &&
			Array.isArray(data.layers) &&
			'sources' in data &&
			typeof data.sources === 'object'
		) {
			return true;
		}
		return false;
	}
}
