import { Channel } from '../../../types/workspace';

export interface DataSource {
	name: string;
	id: number;
}

export interface Series {
	name: string;
	displayName: string;
	id: number;
}

export interface ChartDisplayType {
	name: string;
	id: number;
}

export interface ChartColorPalette {
	name: string;
	id: number;
	color: string;
}

export const dataSources: Channel[] = [
	{
		id: 1911,
		displayName: 'test-Channel',
		tagName: 'Integrated-Oldham DMZ.Lowbrook.BP1.NPS 1.Pump 1.PU_RN',
		assetID: 278,
		channelType: 1,
	},
	{
		id: 1860,
		displayName: 'Wiki_Channel_1',
		tagName: 'Wiki_Channel_1_Tag',
		assetID: 264,
		channelType: 1,
	},
	{
		id: 1830,
		displayName: 'a',
		tagName: 'a',
		assetID: 224,
		channelType: 1,
	},
	{
		id: 1783,
		displayName: 'Morannon Station Cost of Operation',
		tagName: 'opex_morannon_station223',
		assetID: 242,
		channelType: 1,
	},
];

export const seriesData: Series[] = [
	{ name: 'average', displayName: 'Average', id: 1 },
	{ name: 'close', displayName: 'Close', id: 2 },
	{ name: 'open', displayName: 'Open', id: 3 },
	{ name: 'high', displayName: 'High', id: 4 },
	{ name: 'low', displayName: 'Low', id: 5 },
];

export const charDisplayTypeData: ChartDisplayType[] = [
	{ name: 'Line', id: 1 },
	{ name: 'Bar', id: 2 },
];

export const charLineDashStyleData: ChartDisplayType[] = [
	{ name: 'Solid', id: 1 },
	{ name: 'Dash', id: 2 },
	{ name: 'Dot', id: 2 },
];

export const ChartColorPalette: ChartColorPalette[] = [
	{ name: '', id: 1, color: '#ed7836' },
	{ name: '', id: 2, color: '#1ebaa5' },
	{ name: '', id: 3, color: '#fbb03b' },
	{ name: '', id: 4, color: '#f15a24' },
	{ name: '', id: 5, color: '#7892a6' },
	{ name: '', id: 6, color: '#f6bb9a' },
	{ name: '', id: 7, color: '#05b1d1' },
	{ name: '', id: 8, color: '#f6bb9a' },
];

export const gaugeChartDisplayTypeData: ChartDisplayType[] = [
	//{ name: 'Bar Gauge', id: 1 },
	{ name: 'Circular Gauge', id: 2 },
	//{ name: 'Linear Gauge', id: 3 },
];

export const menuData = [
	{ text: 'Properties', id: 1 },
	{ text: 'Reference Chart', id: 2 },
	{ text: 'Display Options', id: 3 },
	{ text: 'Toggle View', id: 4 },
];

export enum ChartType {
	Line = 'Line',
	Bar = 'Bar',
}

export enum GaugeType {
	CircularGauge = 'Circular Gauge',
}
