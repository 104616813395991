import MapboxLayer, { UniqueLayerProps, MapboxLayerProps } from './MapboxLayer';
import { ServiceLayer } from '../services/types';
import { assetUnknown } from '../services/AssetRegistry';

interface ClusterLayerProps extends MapboxLayerProps {
	color: ServiceLayer['color'];
}

export default class ClusterLayer extends MapboxLayer {
	protected _type: ServiceLayer['type'] = 'circle';
	protected _color: string;

	constructor({ color, ...other }: ClusterLayerProps) {
		super(other);
		this._color = color ?? assetUnknown.color;
	}

	layerProperties(): UniqueLayerProps {
		return {
			paint: {
				'circle-color': this._color,
				'circle-radius': [
					'step',
					['get', 'point_count'],
					10,
					100,
					15,
					750,
					20,
				],
				'circle-stroke-color': this._color,
				'circle-stroke-opacity': 0.5,
				'circle-stroke-width': 6,
			},
		};
	}
}
