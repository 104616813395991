import { Snackbar, withStyles } from '@material-ui/core';
import Alert, { Color } from '@material-ui/lab/Alert';
import React from 'react';

export interface AlertInfo {
	text: string;
	severity?: Color;
}

interface Props {
	alert?: AlertInfo;
	onClose: () => void;
}

//Component
const AlertSnackbar = ({ alert, onClose }: Props): JSX.Element => {
	if (!alert) {
		return <div />;
	}
	return (
		<StyledSnackbar
			open={alert !== undefined}
			anchorOrigin={{
				vertical: 'top',
				horizontal: 'center',
			}}>
			<Alert variant="filled" onClose={onClose} severity={alert.severity}>
				{alert.text}
			</Alert>
		</StyledSnackbar>
	);
};

//Styles
const StyledSnackbar = withStyles({
	anchorOriginTopCenter: {
		top: '3.5rem',
	},
})(Snackbar);

export default AlertSnackbar;
