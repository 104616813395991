import React from 'react';
import {
	BackgroundTypes,
	MapBounds,
	MapPanelItems,
	ReactMap,
	SourceType,
	Background,
	MapHiddenLayers,
} from '@innovyze/inno-map';
import { PropertyPane } from '@innovyze/ui';
import { useMapState } from '../../services/MapState';
import { useAuth0 } from '../Auth0/Auth0';
import { useSiteConfig } from '../SiteConfiguration/SiteConfigProvider';
import StyledMap from './Map.styles';

const Map = () => {
	const { config } = useSiteConfig();
	const { token, defaultSite } = useAuth0();

	const {
		bounds,
		setBounds,
		background,
		setBackground,
		selected,
		setSelected,
		hiddenLayers,
		setHiddenLayers,
	} = useMapState();

	const onBoundsChange = (bounds: MapBounds) => {
		setBounds(bounds);
	};

	const onBackgroundChanged = (background: Background) => {
		setBackground(background.key as BackgroundTypes);
	};

	const onSelectedAssetsChange = (items: MapPanelItems) => {
		setSelected(items?.map(i => i.id as string) || []);
	};

	const onHiddenLayersChange = (hiddenLayers: MapHiddenLayers) => {
		setHiddenLayers(hiddenLayers);
	};

	return (
		<StyledMap>
			<ReactMap
				mapKey={config.mapbox.key || ''}
				dataServices={[
					{
						type: SourceType.GEOJSON,
						id: 'pipes',
						displayName: 'Pipes',
						url: `https://innovyzecloud.com/api/sites/${defaultSite}/pipes.json`,
						layerGrouping: [
							{
								filterBy: 'asset_id',
								displayName: 'Pipes',
								type: 'line',
							},
						],
					},
					{
						type: SourceType.GEOJSON,
						id: 'customer-points',
						displayName: 'Customer Points',
						url: `https://innovyzecloud.com/api/sites/${defaultSite}/customer-points.json`,
					},
					{
						type: SourceType.GEOJSON,
						id: 'valves',
						displayName: 'Valves',
						url: `https://innovyzecloud.com/api/sites/${defaultSite}/valves.json`,
					},
					{
						type: SourceType.GEOJSON,
						id: 'non-return-valves',
						displayName: 'Non-return Valves',
						url: `https://innovyzecloud.com/api/sites/${defaultSite}/non-return-valves.json`,
					},
					{
						type: SourceType.GEOJSON,
						id: 'float-valves',
						displayName: 'Float Valves',
						url: `https://innovyzecloud.com/api/sites/${defaultSite}/float-valves.json`,
					},
					{
						type: SourceType.GEOJSON,
						id: 'transfer-nodes',
						displayName: 'Transfer Nodes',
						url: `https://innovyzecloud.com/api/sites/${defaultSite}/transfer-nodes.json`,
					},
					{
						type: SourceType.HARVI,
						id: 'assets',
						displayName: 'Schematic',
						url: `${config.harvi.api}restful/site/${defaultSite}/asset_geos/`,
						requestHeaders: [['emaginauth', `Bearer,${token}`]],
						layerGrouping: [
							{
								groupBy: 'asset_type_id',
								displayName: 'asset_type_name',
								type: 'symbol',
							},
							{
								filterBy: 'sensor_id',
								displayName: 'Sensors',
								type: 'symbol',
							},
						],
					},
				]}
				summaryPanel={<PropertyPane />}
				bounds={bounds}
				background={background as BackgroundTypes}
				onBoundsChange={onBoundsChange}
				onBackgroundChanged={onBackgroundChanged}
				selectedAssets={selected}
				onSelectedAssetsChange={onSelectedAssetsChange}
				hiddenLayers={hiddenLayers}
				onHiddenLayersChange={onHiddenLayersChange}
			/>
		</StyledMap>
	);
};

export default Map;
