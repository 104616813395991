import React from 'react';
import {
	SidePanelContent,
	SidePanelOption,
	WorkspaceItem,
	WorkspaceGridMode,
} from '../../../types/workspace';
import {
	defaultChartFormData,
	defaultGaugeFormData,
	defaultMapFormData,
} from '../WorkspaceGrid/WorkspaceGridService';
import ChartDashletForm from './ChartDashletForm';
import MapDashletForm from './CreateMapForm';
import DashletGridView from './DashletGridView';
import GaugeDashletForm from './GaugeDashletForm';

export interface Props {
	sidePanelOption: SidePanelOption;
	handelItemDrawerAction: (sidePanelOption: SidePanelOption) => void;
	handelUpdateWsItemOptions?: (wsItem: WorkspaceItem) => void;
	handelInitWSItemToFocus: (wsItem: WorkspaceItem) => void;
}

const CreateDashletContent = ({
	sidePanelOption,
	handelItemDrawerAction,
}: Props) => {
	const handelChartCreate = (): void => {
		const newSidePanelOption: SidePanelOption = {
			...sidePanelOption,
			displayMode: SidePanelContent.CreateChart,
		};
		if (sidePanelOption.action === WorkspaceGridMode.ADD_TO_WORKSPACE) {
			newSidePanelOption.gaugeFormData = undefined;
			newSidePanelOption.mapFormData = undefined;
			//initial values when creating new chart
			newSidePanelOption.chartFormData = defaultChartFormData;
		}
		handelItemDrawerAction(newSidePanelOption);
	};

	const handelGaugeCreate = (): void => {
		const newSidePanelOption: SidePanelOption = {
			...sidePanelOption,
			displayMode: SidePanelContent.CreateGauge,
		};
		if (sidePanelOption.action === WorkspaceGridMode.ADD_TO_WORKSPACE) {
			newSidePanelOption.chartFormData = undefined;
			newSidePanelOption.mapFormData = undefined;
			//initial values when creating new gauge
			newSidePanelOption.gaugeFormData = defaultGaugeFormData;
		}
		handelItemDrawerAction(newSidePanelOption);
	};

	const handelMapCreate = (): void => {
		const newSidePanelOption: SidePanelOption = {
			...sidePanelOption,
			displayMode: SidePanelContent.CreateMap,
		};
		if (sidePanelOption.action === WorkspaceGridMode.ADD_TO_WORKSPACE) {
			newSidePanelOption.chartFormData = undefined;
			newSidePanelOption.gaugeFormData = undefined;
			//initial values when creating new map
			newSidePanelOption.mapFormData = defaultMapFormData;
		}
		handelItemDrawerAction(newSidePanelOption);
	};

	const {
		displayMode,
		contentDisplayMode,
		mapFormData,
		chartFormData,
		gaugeFormData,
		action,
	} = sidePanelOption;

	if (displayMode == SidePanelContent.MainGrid) {
		return (
			<DashletGridView
				onChartClick={handelChartCreate}
				onGaugeClick={handelGaugeCreate}
				onMapClick={handelMapCreate}
			/>
		);
	} else if (displayMode == SidePanelContent.CreateChart && chartFormData) {
		return (
			<ChartDashletForm
				menuDisplayMode={contentDisplayMode}
				action={action}
				chartFormData={chartFormData}
			/>
		);
	} else if (displayMode == SidePanelContent.CreateGauge && gaugeFormData) {
		return (
			<GaugeDashletForm
				menuDisplayMode={contentDisplayMode}
				action={action}
				gaugeFormData={gaugeFormData}
			/>
		);
	} else if (displayMode == SidePanelContent.CreateMap && mapFormData) {
		return (
			<MapDashletForm
				menuDisplayMode={contentDisplayMode}
				action={action}
				mapFormData={mapFormData}
			/>
		);
	} else {
		return <></>;
	}
};

export default CreateDashletContent;
