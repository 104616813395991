import MapboxLayer, { UniqueLayerProps } from './MapboxLayer';
import { ServiceLayer } from '../services/types';

export default class SymbolLayer extends MapboxLayer {
	protected _type: ServiceLayer['type'] = 'symbol';

	layerProperties(): UniqueLayerProps {
		return {
			layout: {
				// get the icon name from the source's "icon" property
				'icon-image': this._selected
					? ['concat', ['get', 'icon'], '-selected']
					: ['get', 'icon'],
				'icon-size': [
					'interpolate',
					['linear'],
					['zoom'],
					// zoom 10 (or less)
					10,
					0.5,
					// zoom is 13 (or greater)
					13,
					1,
				],
				'icon-allow-overlap': true,
			},
		};
	}
}
