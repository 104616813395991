import React, {
	useRef,
	useEffect,
	ReactElement,
	useState,
	useCallback,
	MutableRefObject,
} from 'react';
import { DataServiceType } from '../map/services/types';
import { FeatureItems } from '../map';
import { Background } from '../map/BackgroundControl';
import { BackgroundTypes } from '../map/BackgroundTypes';
import { MapModes } from '../map/MapModes';
import { MapHiddenLayers } from '../map/layers/LayerManager';
import { MapElement } from '../types';

export type MapPanelItems = FeatureItems[] | null;
export type MapBounds = number[][];
export type MapSelectedAssets = string[];

type MapProps = {
	mapKey: string;
	dataServices?: DataServiceType[];
	summaryPanel?: ReactElement;
	onSelectedAssetsChange?: (items: MapPanelItems) => void;
	background?: BackgroundTypes;
	onBackgroundChanged?: (background: Background) => void;
	bounds?: MapBounds;
	onBoundsChange?: (bounds: MapBounds) => void;
	mode?: MapModes;
	selectedAssets?: MapSelectedAssets;
	hiddenLayers?: MapHiddenLayers;
	onHiddenLayersChange?: (layers: MapHiddenLayers) => void;
};

const useEventListener = (
	eventName: string,
	handler: EventListener,
	element: MutableRefObject<MapElement | null>,
): void => {
	// save event handler
	const savedHandler = useRef<EventListener>();

	// update ref with latest handler if it changes
	useEffect(() => {
		savedHandler.current = handler;
	}, [handler]);

	useEffect(() => {
		const eventListener = (event: Event): void =>
			savedHandler.current?.(event);

		element.current?.addEventListener(eventName, eventListener);

		// remove event listener on cleanup
		return (): void => {
			element.current?.removeEventListener(eventName, eventListener);
		};
	}, [eventName, element]);
};

const ReactMap = ({
	mapKey,
	dataServices,
	summaryPanel,
	onSelectedAssetsChange,
	background,
	onBackgroundChanged,
	bounds,
	onBoundsChange,
	mode,
	selectedAssets,
	hiddenLayers,
	onHiddenLayersChange,
}: MapProps): ReactElement => {
	const map = useRef<MapElement | null>(null);
	const [items, setItems] = useState(null);

	// only runs when the dataSources change
	useEffect(() => {
		if (dataServices) {
			map?.current?.setDataServices?.(dataServices);
		}
	}, [dataServices]);

	const selectedAssetsChanged = useCallback(
		e => {
			const items = e.detail.length ? e.detail : null;
			if (onSelectedAssetsChange) onSelectedAssetsChange(items);
			if (summaryPanel) setItems(items);
		},
		[summaryPanel, onSelectedAssetsChange],
	);

	useEventListener('selectedassets', selectedAssetsChanged, map);

	useEffect(() => {
		if (bounds) {
			map?.current?.setBounds?.(bounds);
		}
	}, [bounds]);

	const boundsChanged = useCallback(e => onBoundsChange?.(e.detail), [
		onBoundsChange,
	]);

	useEventListener('bounds', boundsChanged, map);

	const backgroundChanged = useCallback(
		e => onBackgroundChanged?.(e.detail),
		[onBackgroundChanged],
	);

	useEventListener('background', backgroundChanged, map);

	useEffect(() => {
		if (selectedAssets) {
			map?.current?.setSelectedAssets?.(selectedAssets);
		}
	}, [selectedAssets]);

	useEffect(() => {
		if (hiddenLayers) {
			map.current?.setHiddenLayers?.(hiddenLayers);
		}
	}, [hiddenLayers]);

	const hiddenLayersChange = useCallback(
		e => onHiddenLayersChange?.(e.detail),
		[onHiddenLayersChange],
	);

	useEventListener('hiddenlayers', hiddenLayersChange, map);

	return (
		<>
			<inno-map
				mapKey={mapKey}
				ref={map}
				background={background}
				mode={mode}
				panel={!!summaryPanel}></inno-map>
			{summaryPanel && React.cloneElement(summaryPanel, { items })}
		</>
	);
};
export default ReactMap;
