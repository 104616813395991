import DataGrid, {
	Column,
	Paging,
	Selection,
} from 'devextreme-react/data-grid';
import React from 'react';
import { Workspace } from '../../types/workspace';

interface Props {
	dataSource: Workspace[];
	onSelectionChanged?: (e: { selectedRowsData?: Workspace[] }) => void;
}

const WorkspaceList = ({ dataSource, onSelectionChanged }: Props) => {
	// default page size is 20, set defaultPageSize to 999999999 to disable paging
	return (
		<DataGrid
			allowColumnResizing
			columnAutoWidth
			dataSource={dataSource}
			keyboardNavigation={{ enabled: false }}
			onSelectionChanged={onSelectionChanged}
			selectedRowKeys={[]}
			showBorders>
			<Selection mode="single" />
			<Paging defaultPageSize={999999999} />
			<Column
				caption="Workspace Name"
				dataField="name"
				dataType="string"
			/>
			<Column
				caption="Description"
				dataField="description"
				dataType="string"
			/>
			<Column caption="Owner" dataField="ownerName" dataType="string" />
			<Column
				caption="Last Modified"
				dataField="modifiedAt"
				dataType="date"
				width={136}
			/>
		</DataGrid>
	);
};

export default WorkspaceList;
