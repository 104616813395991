export const debounce = <F extends (...args: unknown[]) => unknown>(
	func: F,
	waitFor: number,
) => {
	let timeout = 0;

	const debounced = (...args: unknown[]) => {
		clearTimeout(timeout);
		timeout = window.setTimeout(() => func(...args), waitFor);
	};

	return debounced;
};
