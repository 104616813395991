import styled from 'styled-components';

//Styles Generic Dashlet flex container

export const WidgetContent = styled.div`
	height: 90%;
	width: 100%;
	width: inherit;
	position: relative;
	overflow: hidden;
	padding: 0px;
`;

export const ChartView = styled.div`
	left: 21px;
	right: 21px;
	background: #ffffff;
	top: 95px;
	bottom: 20px;
	height: 381px;
	width: 100%;
	position: absolute;
`;

export const GaugeView = styled.div`
	left: 21px;
	right: 21px;
	background: #ffffff;
	top: 35px;
	bottom: 20px;
	width: 100%;
	position: absolute;
`;

export const GaugetRangeView = styled.div`
	left: 21px;
	right: 21px;
	bottom: 20px;
	height: 50px;
`;

export const ChartRangeView = styled.div`
	left: 21px;
	right: 21px;
	bottom: 20px;
	height: 70px;
`;

export const WidgetMenu = styled.span`
	font-size: 16px;
	height: 22px;
	position: absolute;
	float: right;
	min-width: 350px;
	right: -180px;
`;

export const WidgetContainer = styled.div`
	background: #ffffff;
	border: 1px solid #a5bdc6;
	box-sizing: border-box;
	border-radius: 4px;
	height: 100%;
	width: 100%;
`;

export const WidgetHeader = styled.div`
	height: 40px;
	min-width: 500px;
	width: 100%;
	margin-left: 0px;
	top: 209px;
	background: #ffffff;
	border: 1px solid #a5bdc6;
	box-sizing: border-box;
	border-radius: 4px 4px 0px 0px;
`;

export const WidgetTitle = styled.span`
	height: 21.33px;
	min-width: 200px;
	width: auto;
	margin-left: 16px;
	margin-top: 8px;
	font-weight: 600;
	font-size: 18px;
	color: #252f33;
	position: absolute;
`;

export const ChartHeader = styled.span`
	margin-top: 66px;
	margin-bottom: 46.01px;
	margin-left: 20px;
	margin-right: 50px;
`;

export const DateFromBox = styled.div`
	height: 32px;
	margin-left: 20px;
	right: 0.48px;
	top: 0px;
`;

export const DateToBox = styled.div`
	height: 32px;
	left: 20px;
	right: 148px;
	top: 0px;
`;

export const IntervalBox = styled.div`
	height: 32px;
	width: auto;
	min-width: 120px;
	margin-left: -95px;
	right: 318.37px;
	top: 60px;
	background: #f1f4f4;
	border-radius: 16px;
`;

export const RangeSelectorBox = styled.div`
	width: 705.55px;
	height: 89px;
	// margin-left: 102.37px;
	// margin-right: 102.37px;
	margin-bottom: 280px;
	top: 434.5px;
	background: #ffffff;
	opacity: 0.5;
`;
