import { MassBalanceElementType } from '../../types/massBalance';

export const MassBalanceElementTitleToType = (
	title: string,
): MassBalanceElementType => {
	switch (title.toLocaleLowerCase()) {
		case 'inflow':
			return MassBalanceElementType.Inflow;
		case 'outflow':
			return MassBalanceElementType.Outflow;
		case 'zone':
			return MassBalanceElementType.Zone;
		case 'storage':
			return MassBalanceElementType.Storage;
		default:
			return MassBalanceElementType.Unknown;
	}
};
