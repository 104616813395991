import { ThemeProvider } from '@material-ui/core/styles';
import {
	DashboardOutlined,
	MapOutlined,
	WorkOutlineOutlined,
	AppsOutlined,
} from '@material-ui/icons';
import 'devextreme/dist/css/dx.common.css';
import React from 'react';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import { BrowserRouter as Router } from 'react-router-dom';
import StyledApp, { GlobalStyle } from './App.styles';
import { useAuth0 } from './Components/Auth0/Auth0';
import Header from './Components/Header/Header';
import Loading from './Components/Loading/Loading';
import Map from './Components/Map/Map';
import Pages from './Containers/Pages/Pages';
import theme from './theme/index';
import './theme/innovyze-theme-devextreme.css';

const App: React.FunctionComponent = () => {
	const { isInitializing } = useAuth0();

	return (
		<Router>
			<ThemeProvider theme={theme}>
				<StyledApp>
					<GlobalStyle />
					<Loading loading={isInitializing}>
						<Header />
						<Pages
							pages={[
								{
									title: 'Dashboard',
									to: '/',
									icon: <DashboardOutlined />,
									render: <div>Dashboard</div>,
								},
								{
									title: 'Map',
									to:
										'/map/-1.3124680207068593,51.29747542629258,-1.0967133103233095,51.39507946470843/streets',
									path:
										'/map/:bounds/:background/:selectedItems?/:hiddenLayers?',
									icon: <MapOutlined />,
									render: <Map />,
								},
								{
									title: 'Workspaces',
									to: '/workspaces',
									icon: <WorkOutlineOutlined />,
									render: <div />,
								},
								{
									title: 'Tools',
									to: '/tools',
									icon: <AppsOutlined />,
									render: <div />,
								},
							]}
						/>
					</Loading>
				</StyledApp>
			</ThemeProvider>
		</Router>
	);
};

export default App;
