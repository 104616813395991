import { Grid, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Refresh from '@material-ui/icons/Refresh';
import { DropDownButton } from 'devextreme-react';
import React from 'react';
import {
	ChartDashletOptions,
	WorkspaceItem,
} from '../../../../types/workspace';
import * as Dashlet from './GenericDashlet.style';
import DashletContent from './GenericDashletContent';

interface Props {
	widgetItem: WorkspaceItem;
	itemIndex: number;
	onDeleteWidgetHandler: (key: number) => void;
	onUpdateDashletHandler: (wsItem: WorkspaceItem) => void;
	onInitDashletHandler: (wsItem: WorkspaceItem) => void;
	handelSwitchToFocusMode: (wsItem: WorkspaceItem) => void;
}

const GenericDashlet = ({
	widgetItem,
	itemIndex,
	onDeleteWidgetHandler,
	onUpdateDashletHandler,
	onInitDashletHandler,
	handelSwitchToFocusMode,
}: Props) => {
	const optionList = ['Configure', 'Search', 'View', 'Reference Charts'];
	const chartOptions: ChartDashletOptions = JSON.parse(widgetItem.options);

	const optionsBtnOnClick = (e: { itemData?: string }) => {
		const option = e.itemData ? e.itemData : '';
		if (option == 'Configure' && chartOptions.dashletInit) {
			handelSwitchToFocusMode(widgetItem);
		}
	};

	return (
		<>
			<Dashlet.WidgetContainer>
				<Dashlet.WidgetHeader className="DragHandle">
					<Grid
						container
						spacing={10}
						direction="row"
						justify="flex-end"
						alignItems="center">
						<Grid item xs>
							<Dashlet.WidgetTitle>
								{chartOptions.name}
							</Dashlet.WidgetTitle>
						</Grid>
						<Grid className="DragCancel" item xs>
							<Dashlet.WidgetMenu>
								<DropDownButton
									icon=""
									stylingMode="text"
									text="Menu"
									dropDownOptions={{ width: 200 }}
									items={optionList}
									onItemClick={optionsBtnOnClick}
								/>

								<IconButton
									aria-label="refresh"
									onClick={() =>
										onInitDashletHandler(widgetItem)
									}>
									<Refresh fontSize="small" />
								</IconButton>

								<IconButton
									aria-label="delete"
									onClick={() =>
										onDeleteWidgetHandler(itemIndex)
									}>
									<CloseIcon fontSize="small" />
								</IconButton>
							</Dashlet.WidgetMenu>
						</Grid>
					</Grid>
				</Dashlet.WidgetHeader>

				<DashletContent
					widgetItem={widgetItem}
					onUpdateDashletHandler={onUpdateDashletHandler}
				/>
			</Dashlet.WidgetContainer>
		</>
	);
};
export default GenericDashlet;
