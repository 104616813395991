export const CREATE_WORKSPACE_FAILED = 'CREATE_WORKSPACE_FAILED';
export type CREATE_WORKSPACE_FAILED = typeof CREATE_WORKSPACE_FAILED;

export const DRAWER_CANCEL_CLICKED = 'DRAWER_CANCEL_CLICKED';
export type DRAWER_CANCEL_CLICKED = typeof DRAWER_CANCEL_CLICKED;

export const DRAWER_DESCRIPTION_BLURRED = 'DRAWER_DESCRIPTION_BLURRED';
export type DRAWER_DESCRIPTION_BLURRED = typeof DRAWER_DESCRIPTION_BLURRED;

export const DRAWER_NAME_BLURRED = 'DRAWER_NAME_BLURRED';
export type DRAWER_NAME_BLURRED = typeof DRAWER_NAME_BLURRED;

export const FETCH_WORKSPACES_FAILED = 'FETCH_WORKSPACES_FAILED';
export type FETCH_WORKSPACES_FAILED = typeof FETCH_WORKSPACES_FAILED;

export const FILTER_WORKSPACES = 'FILTER_WORKSPACES';
export type FILTER_WORKSPACES = typeof FILTER_WORKSPACES;

export const NEW_WORKSPACE_CLICKED = 'NEW_WORKSPACE_CLICKED';
export type NEW_WORKSPACE_CLICKED = typeof NEW_WORKSPACE_CLICKED;

export const SEARCH_CANCELED = 'SEARCH_CANCELED';
export type SEARCH_CANCELED = typeof SEARCH_CANCELED;

export const SEARCH_VALUE_CHANGED = 'SEARCH_VALUE_CHANGED';
export type SEARCH_VALUE_CHANGED = typeof SEARCH_VALUE_CHANGED;

export const SELECTION_CHANGED = 'SELECTION_CHANGED';
export type SELECTION_CHANGED = typeof SELECTION_CHANGED;

export const WORKSPACE_CREATING = 'WORKSPACE_CREATING';
export type WORKSPACE_CREATING = typeof WORKSPACE_CREATING;

export const WORKSPACE_CREATED = 'WORKSPACE_CREATED';
export type WORKSPACE_CREATED = typeof WORKSPACE_CREATED;

export const WORKSPACES_FETCHED = 'WORKSPACES_FETCHED';
export type WORKSPACES_FETCHED = typeof WORKSPACES_FETCHED;

export const CHECK_WORKING_WORKSPACE_ERRORS = 'CHECK_WORKING_WORKSPACE_ERRORS';
export type CHECK_WORKING_WORKSPACE_ERRORS = typeof CHECK_WORKING_WORKSPACE_ERRORS;

export const FETCHING_WORKSPACES = 'FETCHING_WORKSPACES';
export type FETCHING_WORKSPACES = typeof FETCHING_WORKSPACES;

export const DISPLAY_WORKSPACE_DELETED_INFO = 'DISPLAY_WORKSPACE_DELETED_INFO';
export type DISPLAY_WORKSPACE_DELETED_INFO = typeof DISPLAY_WORKSPACE_DELETED_INFO;

export const CLOSE_ALERT = 'CLOSE_ALERT';
export type CLOSE_ALERT = typeof CLOSE_ALERT;
