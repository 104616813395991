import React, { useState } from 'react';
import ReactFlow, { Elements, Edge, Node } from 'react-flow-renderer';
import StyledMassBalanceDesigner, {
	StyledMassBalanceDesignerHeader,
	TitleAndSaveContainer,
	Title,
} from './MassBalanceDesigner.styles';
import MassBalanceDesignerNode from './MassBalanceDesignerNode';
import SaveButtonWithMenu from '../Workspace/SaveButtonWithMenu';
import {
	createStyles,
	makeStyles,
	Theme,
	AppBar,
	Tabs,
	Tab,
	Box,
	Typography,
	Card,
	CardContent,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import ArrowBackwardIcon from '@material-ui/icons/ArrowBack';
import { MassBalanceElementType } from '../../types/massBalance';
import { MassBalanceElementTitleToType } from '../../store/utils/massbalancedesigner';

interface TabPanelProps {
	children?: React.ReactNode;
	index: number;
	value: number;
}

function TabPanel(props: TabPanelProps) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`massBalanceDesigner-tabpanel-${index}`}
			aria-labelledby={`massBalanceDesigner-tab-${index}`}
			{...other}>
			{value === index && (
				<Box p={3}>
					<Typography>{children}</Typography>
				</Box>
			)}
		</div>
	);
}

function a11yProps(index: number) {
	return {
		id: `massBalanceDesigner-tab-${index}`,
		'aria-controls': `massBalanceDesigner-tabpanel-${index}`,
	};
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		goBackLink: {
			display: 'flex',
			alignItems: 'center',
			fontSize: '0.625rem',
			fontWeight: 600,
			textDecoration: 'none',
			color: theme.palette.primary.main,
		},
		goBackText: {
			textDecoration: 'none',
		},
		goBackIcon: {
			fontSize: '1rem',
		},
		designerCardRoot: {
			minWidth: 600,
			minHeight: 500,
		},
	}),
);

const MassBalanceDesigner = () => {
	const classes = useStyles();
	const [value, setValue] = useState(0);

	const [elements, setElements] = useState([
		{
			id: 'inflow',
			type: 'massBalanceDesignerNode',
			data: {
				elementType: MassBalanceElementType.Inflow,
				active: false,
				sensors: ['test1', 'test2'],
			},
			position: { x: 100, y: 100 },
		},
		{
			id: 'zone',
			type: 'massBalanceDesignerNode',
			data: {
				elementType: MassBalanceElementType.Zone,
				active: false,
				title: '',
				sensors: [],
			},
			position: { x: 300, y: 100 },
		},
		{
			id: 'outflow',
			type: 'massBalanceDesignerNode',
			data: {
				elementType: MassBalanceElementType.Outflow,
				active: false,
				sensors: [],
			},
			position: { x: 580, y: 100 },
		},
		{
			id: 'storage',
			type: 'massBalanceDesignerNode',
			data: {
				elementType: MassBalanceElementType.Storage,
				active: false,
				sensors: [],
			},
			position: { x: 340, y: 300 },
		},
		{
			id: 'e-inflow-zone',
			source: 'inflow',
			target: 'zone',
			animated: true,
		},
		{
			id: 'e-zone-outflow',
			source: 'zone__outflowHandle',
			target: 'outflow',
			animated: true,
		},
		{
			id: 'e-zone-storage',
			source: 'zone__storageHandle',
			target: 'storage',
			animated: false,
		},
	]);

	const handleSaveClick = () => {
		//TODO: handle save button click event
	};

	const handleDeleteClick = () => {
		//TODO: handle delete button click event
	};

	const handleSettingsClick = () => {
		//TODO: handle setting button click event
	};

	const handlePrintViewClick = () => {
		//TODO: handle setting button click event
	};

	const handleTabChange = (
		event: React.ChangeEvent<{}>,
		newValue: number,
	) => {
		setValue(newValue);
	};

	const nodeValueOnChange = (el: MassBalanceElementType, sensors: []) => {
		const newElements = elements.map(element => {
			if (!element.type) return element;
			const newElement = {
				id: element.id,
				type: element.type,
				data: {
					elementType: element.data.elementType,
					active: el === MassBalanceElementTitleToType(element.id),
					sensors: sensors,
				},
				position: element.position,
			};
			return newElement;
		});
		setElements(newElements);
	};

	const activateElement = (el: MassBalanceElementType) => {
		const newElements = elements.map(element => {
			if (!element.type) return element;
			const newElement = {
				id: element.id,
				type: element.type,
				data: {
					elementType: element.data.elementType,
					active: el === MassBalanceElementTitleToType(element.id),
					sensors: element.data.sensors,
				},
				position: element.position,
			};
			return newElement;
		});
		setElements(newElements);
	};

	const onElementClick = (els: Edge | Node) => {
		if (els !== null) {
			activateElement(MassBalanceElementTitleToType(els!.id));

			//demostration of how to pass in sensor data to the diagram
			//nodeValueOnChange(MassBalanceElementTitleToType(targetElement!.id), ["test1", "test2"]);
		}
	};

	return (
		<div>
			<StyledMassBalanceDesignerHeader>
				<TitleAndSaveContainer>
					<Link className={classes.goBackText} to="/tools">
						<ArrowBackwardIcon className={classes.goBackIcon} />
						<span className={classes.goBackText}>TOOLS</span>
					</Link>
					<SaveButtonWithMenu
						onSaveClick={handleSaveClick}
						onDeleteClick={handleDeleteClick}
						onSettingsClick={handleSettingsClick}
						onPrintViewClick={handlePrintViewClick}
					/>
				</TitleAndSaveContainer>
				<Title>Canimo DeBryant Mass Balance</Title>
			</StyledMassBalanceDesignerHeader>

			<AppBar position="static" color="default">
				<Tabs
					value={value}
					onChange={handleTabChange}
					indicatorColor="primary"
					textColor="primary"
					aria-label="Mass Balance Designer Tabs">
					<Tab label="Configure" {...a11yProps(0)} />
					<Tab label="Calculated Result" {...a11yProps(1)} />
				</Tabs>
			</AppBar>
			<TabPanel value={value} index={0}>
				<Card className={classes.designerCardRoot}>
					<CardContent>
						<StyledMassBalanceDesigner>
							<ReactFlow
								elements={elements}
								className=""
								isInteractive={true}
								onElementClick={onElementClick}
								nodeTypes={{
									massBalanceDesignerNode: MassBalanceDesignerNode,
								}}></ReactFlow>
						</StyledMassBalanceDesigner>
					</CardContent>
				</Card>
			</TabPanel>
			<TabPanel value={value} index={1}>
				Calc Result Panel
			</TabPanel>
		</div>
	);
};

export default MassBalanceDesigner;
