import { GeoJsonDataType, ServiceLayer } from './types';
import { AnySourceData } from 'mapbox-gl';

export type MapboxDataSourceProps = Pick<
	ServiceLayer,
	'displayName' | 'type' | 'icon' | 'color'
> & {
	id: string;
	data: GeoJsonDataType;
};

export default class MapboxDataSource {
	private _id: MapboxDataSourceProps['id'];
	private _data: MapboxDataSourceProps['data'];
	private _displayName: MapboxDataSourceProps['displayName'];
	private _type: MapboxDataSourceProps['type'];
	private _icon: MapboxDataSourceProps['icon'];
	private _color: MapboxDataSourceProps['color'];
	private _cluster: ServiceLayer['cluster'];

	constructor({
		id,
		data,
		displayName,
		type,
		icon,
		color,
	}: MapboxDataSourceProps) {
		this._id = id;
		this._data = data;
		this._displayName = displayName;
		this._type = type;
		this._cluster = type !== 'line';
		this._icon = icon;
		this._color = color;
	}

	get id(): MapboxDataSourceProps['id'] {
		return this._id;
	}

	get data(): MapboxDataSourceProps['data'] {
		return this._data;
	}

	get displayName(): MapboxDataSourceProps['displayName'] {
		return this._displayName;
	}

	get type(): MapboxDataSourceProps['type'] {
		return this._type;
	}

	get icon(): MapboxDataSourceProps['icon'] {
		return this._icon;
	}

	get color(): MapboxDataSourceProps['color'] {
		return this._color;
	}

	get source(): AnySourceData {
		return {
			type: 'geojson',
			data: this.data,
			cluster: this._cluster,
			clusterRadius: 25,
		} as AnySourceData;
	}
}
