export interface RecentWorkspace {
	id: number;
	lastVisited: string;
	workspace: {
		id: number;
		name: string;
	};
}
export interface SystemLog {
	id: number;
	lastVisited: string;
	systemLog: {
		id: number;
		name: string;
		status: string;
	};
}

export interface LoggedInUser {
	userName: string;
	aliasName: string;
}

export enum SystemStatus {
	Healthy,
	Error,
}
