import { AnyAction, applyMiddleware, createStore } from 'redux';
import thunkMiddleware, { ThunkAction, ThunkDispatch } from 'redux-thunk';
import rootReducer from './reducers/root';
import { RootState } from './types/root';

const store = createStore(rootReducer, applyMiddleware(thunkMiddleware));

export type AppThunk<ReturnType = void> = ThunkAction<
	ReturnType,
	RootState,
	unknown,
	AnyAction
>;

export type AppDispatch = ThunkDispatch<RootState, unknown, AnyAction>;

export default store;
