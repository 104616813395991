import React, { forwardRef } from 'react';

import StyledRoundButton from './RoundButton.styles';

interface Props {
	children: React.ReactNode;
	active?: boolean;
	onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
	title?: string;
}

// issue with styled components refs: https://github.com/DefinitelyTyped/DefinitelyTyped/issues/28884
// HTMLButtonElement - used to match type of styled component
type refType = React.MutableRefObject<HTMLButtonElement>;

const RoundButton: React.RefForwardingComponent<{}, Props> = (
	{ children, ...other }: Props,
	ref,
) => (
	<StyledRoundButton ref={ref as refType} {...other}>
		{children}
	</StyledRoundButton>
);

export default forwardRef(RoundButton);
