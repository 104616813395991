export const CLOSE_ALERT = 'CLOSE_ALERT';
export type CLOSE_ALERT = typeof CLOSE_ALERT;

export const DELETE_WORKSPACE_FAILED = 'DELETE_WORKSPACE_FAILED';
export type DELETE_WORKSPACE_FAILED = typeof DELETE_WORKSPACE_FAILED;

export const FETCH_WORKSPACE_FAILED = 'FETCH_WORKSPACE_FAILED';
export type FETCH_WORKSPACE_FAILED = typeof FETCH_WORKSPACE_FAILED;

export const OPEN_SETTINGS_DRAWER = 'OPEN_SETTINGS_DRAWER';
export type OPEN_SETTINGS_DRAWER = typeof OPEN_SETTINGS_DRAWER;

export const WORKSPACE_DELETED = 'WORKSPACE_DELETED';
export type WORKSPACE_DELETED = typeof WORKSPACE_DELETED;

export const WORKSPACE_FETCHED = 'WORKSPACE_FETCHED';
export type WORKSPACE_FETCHED = typeof WORKSPACE_FETCHED;

export const SAVING_WORKSPACE = 'SAVING_WORKSPACE';
export type SAVING_WORKSPACE = typeof SAVING_WORKSPACE;

export const WORKSPACE_SAVED = 'WORKSPACE_SAVED';
export type WORKSPACE_SAVED = typeof WORKSPACE_SAVED;

export const SAVE_WORKSPACE_FAILED = 'SAVE_WORKSPACE_FAILED';
export type SAVE_WORKSPACE_FAILED = typeof SAVE_WORKSPACE_FAILED;

export const WORKSPACE_LAYOUT_CHANGED = 'WORKSPACE_LAYOUT_CHANGED';
export type WORKSPACE_LAYOUT_CHANGED = typeof WORKSPACE_LAYOUT_CHANGED;

export const DRAWER_NAME_BLURRED = 'DRAWER_NAME_BLURRED';
export type DRAWER_NAME_BLURRED = typeof DRAWER_NAME_BLURRED;

export const DRAWER_DESCRIPTION_BLURRED = 'DRAWER_DESCRIPTION_BLURRED';
export type DRAWER_DESCRIPTION_BLURRED = typeof DRAWER_DESCRIPTION_BLURRED;

export const DRAWER_CANCEL_CLICKED = 'DRAWER_CANCEL_CLICKED';
export type DRAWER_CANCEL_CLICKED = typeof DRAWER_CANCEL_CLICKED;

export const FETCHING_WORKSPACE = 'FETCHING_WORKSPACE';
export type FETCHING_WORKSPACE = typeof FETCHING_WORKSPACE;

export const CHECK_WORKSPACE_SETTINGS_ERRORS =
	'CHECK_WORKSPACE_SETTINGS_ERRORS';
export type CHECK_WORKSPACE_SETTINGS_ERRORS = typeof CHECK_WORKSPACE_SETTINGS_ERRORS;

export const ADD_WORKSPACE_ITEM = 'ADD_WORKSPACE_ITEM';
export type ADD_WORKSPACE_ITEM = typeof ADD_WORKSPACE_ITEM;

export const REMOVE_WORKSPACE_ITEM = 'REMOVE_WORKSPACE_ITEM';
export type REMOVE_WORKSPACE_ITEM = typeof REMOVE_WORKSPACE_ITEM;

export const FETCH_TIMESERIES_DATA = 'FETCH_TIMESERIES_DATA';
export type FETCH_TIMESERIES_DATA = typeof FETCH_TIMESERIES_DATA;

export const FETCH_TIMESERIES_DATA_FAIL = 'FETCH_TIMESERIES_DATA_FAIL';
export type FETCH_TIMESERIES_DATA_FAIL = typeof FETCH_TIMESERIES_DATA_FAIL;

export const FOCUS_MODE_FETCH_SENSOR_LIST = 'FOCUS_MODE_FETCH_SENSOR_LIST';
export type FOCUS_MODE_FETCH_SENSOR_LIST = typeof FOCUS_MODE_FETCH_SENSOR_LIST;

export const FOCUS_MODE_FETCH_SENSOR_LIST_FAIL =
	'FOCUS_MODE_FETCH_SENSOR_LIST_FAIL';
export type FOCUS_MODE_FETCH_SENSOR_LIST_FAIL = typeof FOCUS_MODE_FETCH_SENSOR_LIST_FAIL;

export const INIT_WSITEM_TO_WORKSPACE = 'INIT_WSITEM_TO_WORKSPACE';
export type INIT_WSITEM_TO_WORKSPACE = typeof INIT_WSITEM_TO_WORKSPACE;

export const INIT_WSITEM_TO_WORKSPACE_FAIL = 'INIT_WSITEM_TO_WORKSPACE_FAIL';
export type INIT_WSITEM_TO_WORKSPACE_FAIL = typeof INIT_WSITEM_TO_WORKSPACE_FAIL;

export const UPDATE_WSITEM_TO_WORKSPACE = 'UPDATE_WSITEM_TO_WORKSPACE';
export type UPDATE_WSITEM_TO_WORKSPACE = typeof UPDATE_WSITEM_TO_WORKSPACE;

export const UPDATE_WSITEM_TO_WORKSPACE_FAIL =
	'UPDATE_WSITEM_TO_WORKSPACE_FAIL';
export type UPDATE_WSITEM_TO_WORKSPACE_FAIL = typeof UPDATE_WSITEM_TO_WORKSPACE_FAIL;

export const SAVE_WSITEM_TO_WORKSPACE = 'SAVE_WSITEM_TO_WORKSPACE';
export type SAVE_WSITEM_TO_WORKSPACE = typeof SAVE_WSITEM_TO_WORKSPACE;

export const ADD_ITEM_DRAWER_ACTION = 'ADD_ITEM_DRAWER_ACTION';
export type ADD_ITEM_DRAWER_ACTION = typeof ADD_ITEM_DRAWER_ACTION;

export const INIT_WSITEM_TO_FOCUS_MODE = 'INIT_WSITEM_TO_FOCUS_MODE';
export type INIT_WSITEM_TO_FOCUS_MODE = typeof INIT_WSITEM_TO_FOCUS_MODE;

export const LOADING_WSITEM = 'LOADING_WSITEM';
export type LOADING_WSITEM = typeof LOADING_WSITEM;

export const LOADING_ACTIVE_WSITEM = 'LOADING_ACTIVE_WSITEM';
export type LOADING_ACTIVE_WSITEM = typeof LOADING_ACTIVE_WSITEM;

export const MAP_FORM_INPUT_BLURRED = 'MAP_FORM_INPUT_BLURRED';
export type MAP_FORM_INPUT_BLURRED = typeof MAP_FORM_INPUT_BLURRED;

export const CHECK_MAP_FORM_ERRORS = 'CHECK_MAP_FORM_ERRORS';
export type CHECK_MAP_FORM_ERRORS = typeof CHECK_MAP_FORM_ERRORS;

export const CHART_FORM_INPUT_BLURRED = 'CHART_FORM_INPUT_BLURRED';
export type CHART_FORM_INPUT_BLURRED = typeof CHART_FORM_INPUT_BLURRED;

export const CHECK_CHART_FORM_ERRORS = 'CHECK_CHART_FORM_ERRORS';
export type CHECK_CHART_FORM_ERRORS = typeof CHECK_CHART_FORM_ERRORS;

export const GAUGE_FORM_INPUT_BLURRED = 'GAUGE_FORM_INPUT_BLURRED';
export type GAUGE_FORM_INPUT_BLURRED = typeof GAUGE_FORM_INPUT_BLURRED;

export const CHECK_GAUGE_FORM_ERRORS = 'CHECK_GAUGE_FORM_ERRORS';
export type CHECK_GAUGE_FORM_ERRORS = typeof CHECK_GAUGE_FORM_ERRORS;

export const UPDATE_ACTIVE_WSITEM_CHART_FORM_DATA =
	'UPDATE_ACTIVE_WSITEM_CHART_FORM_DATA';
export type UPDATE_ACTIVE_WSITEM_CHART_FORM_DATA = typeof UPDATE_ACTIVE_WSITEM_CHART_FORM_DATA;

export const UPDATE_ACTIVE_WSITEM_GAUGE_FORM_DATA =
	'UPDATE_ACTIVE_WSITEM_GAUGE_FORM_DATA';
export type UPDATE_ACTIVE_WSITEM_GAUGE_FORM_DATA = typeof UPDATE_ACTIVE_WSITEM_GAUGE_FORM_DATA;

export const UPDATE_ACTIVE_WSITEM_MAP_FORM_DATA =
	'UPDATE_ACTIVE_WSITEM_MAP_FORM_DATA';
export type UPDATE_ACTIVE_WSITEM_MAP_FORM_DATA = typeof UPDATE_ACTIVE_WSITEM_MAP_FORM_DATA;
