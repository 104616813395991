import styled from 'styled-components';

const StyledMap = styled.div`
	height: 100%;
	position: relative;
	overflow: hidden;

	inno-map {
		+ div {
			z-index: 2;
		}
	}
`;

export default StyledMap;
