import { Evented } from 'mapbox-gl';
import DataService from './DataService';
import GeoJsonDataService from './GeoJsonDataService';
import StyleDataService from './StyleDataService';
import {
	DataServiceType,
	SourceType,
	ServiceError,
	ServiceLoadIcon,
} from './types';
import HarviDataService from './HarviDataService';

export type ServicesType =
	| DataService
	| GeoJsonDataService
	| StyleDataService
	| HarviDataService;

type ServiceClassType =
	| typeof DataService
	| typeof GeoJsonDataService
	| typeof StyleDataService
	| typeof HarviDataService;

export default class ServiceManger extends Evented {
	private _dataServices: ServicesType[] = [];

	private _getServiceClass(type: SourceType): ServiceClassType {
		switch (type) {
			case SourceType.GEOJSON:
				return GeoJsonDataService;
			case SourceType.HARVI:
				return HarviDataService;
			case SourceType.STYLE:
				return StyleDataService;
			default:
				return DataService;
		}
	}

	addOne(dataService: DataServiceType): void {
		const { type } = dataService;
		const Service = this._getServiceClass(type);
		const service = new Service(dataService);
		if (service.isValid()) {
			this._dataServices.push(service);
			service.on('service-loaded', () => {
				this.fire('service-loaded');
			});
			service.on('service-error', (args: ServiceError) => {
				this.fire('service-error', args);
			});
			service.on('load-icon', (args: ServiceLoadIcon) => {
				this.fire('load-icon', args);
			});
			service.loadData();
		} else {
			console.warn('Supplied dataService is invalid', dataService);
		}
	}

	addMany(dataServices: DataServiceType[]): void {
		if (!Array.isArray(dataServices)) return;
		dataServices.map(service => this.addOne(service));
	}

	deleteAll(): void {
		this._dataServices = [];
	}

	getAll(): DataService[] {
		return this._dataServices;
	}

	getByType(types: (SourceType | null)[]): ServicesType[] {
		return this._dataServices.filter(service =>
			types.includes(service.type),
		);
	}
}
