import { TextField } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';
import { MapFormData, Display } from '../../../types/workspace';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../store';
import { mapFormInputBlurred } from '../../../store/actions/workspace';

interface Props {
	menuDisplayMode: number;
	action: string;
	mapFormData: MapFormData;
	//handelOnMapOptionChange: (chartOption: MapDashletOptions) => void;
}

enum MapFormInputTypeEnum {
	name = 1,
	description = 2,
}

const MapDashletForm = ({ menuDisplayMode, mapFormData }: Props) => {
	const dispatch = useDispatch<AppDispatch>();
	const foundNameError = mapFormData.errors?.find(
		err => err.fieldName === 'name',
	);
	const nameErrorText = foundNameError ? foundNameError.errorMessage : '';
	//description Error
	const foundDescriptionError = mapFormData.errors?.find(
		err => err.fieldName === 'description',
	);
	const descriptionErrorText = foundDescriptionError
		? foundDescriptionError.errorMessage
		: '';

	const onTextFiledBlur = (
		event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>,
		inputFieldType: MapFormInputTypeEnum,
	) => {
		if (inputFieldType === MapFormInputTypeEnum.name) {
			const newMapFormData: MapFormData = {
				...mapFormData,
				name: event.target.value,
			};
			dispatch(mapFormInputBlurred(newMapFormData));
		} else if (inputFieldType === MapFormInputTypeEnum.description) {
			const newMapFormData: MapFormData = {
				...mapFormData,
				description: event.target.value,
			};
			dispatch(mapFormInputBlurred(newMapFormData));
		}
	};

	switch (menuDisplayMode) {
		case Display.Properties:
			return (
				<>
					<Content>
						<TextField
							defaultValue={mapFormData.name}
							error={Boolean(foundNameError)}
							helperText={nameErrorText}
							label="Map Name"
							onBlur={event =>
								onTextFiledBlur(
									event,
									MapFormInputTypeEnum.name,
								)
							}
							required
							variant="outlined"></TextField>
						<TextField
							defaultValue={mapFormData.description}
							error={Boolean(foundDescriptionError)}
							helperText={descriptionErrorText}
							label="Description"
							onBlur={event =>
								onTextFiledBlur(
									event,
									MapFormInputTypeEnum.description,
								)
							}
							required
							variant="outlined"
						/>
					</Content>
				</>
			);
		default:
			return <></>;
	}
};

export default MapDashletForm;

const Content = styled.div`
	padding: 12px;
	display: flex;
	flex-direction: column;
	flex: 1;
	& > div {
		width: 100%;
		margin-bottom: 24px;
	}
`;
