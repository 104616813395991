import styled, { css } from 'styled-components';
import { rem, em } from '../../util/styles';
import { Link } from 'react-router-dom';

interface NavButtonProps {
	active?: boolean;
}
const borderWidth = 5;
const StyledNavButton = styled.li<NavButtonProps>`
	border-left: ${rem(borderWidth)} solid #0000;
	border-right: ${rem(borderWidth)} solid #0000;
	height: ${rem(80)};
	color: #fff;
	font-size: ${em(10)};
	text-transform: uppercase;
	${props =>
		props.active &&
		css`
			background: #1f242650;
			border-left-color: #00abd1;
		`};
	svg {
		margin-bottom: ${rem(5)};
	}
`;

export default StyledNavButton;

export const NavLink = styled(Link)`
	color: inherit;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: calc(100% + ${rem(borderWidth * 2)});
	margin-left: -${rem(borderWidth)};
	height: 100%;
	text-decoration: none;
	&:hover,
	&:focus {
		background: #1f242625;
	}
`;
