import styled from 'styled-components';

export const Container = styled.div`
	left: 0.06%;
	right: 0.11%;
	top: 0.05%;
	bottom: 0.05%;
	background: #ffffff;
`;

export const DatasourceInput = styled.div`
	left: 6.3%;
	right: 8.26%;
	top: 10.12%;
	bottom: 84.33%;
	background: #ffffff;
`;

export const DatasourceText = styled.span`
	left: 10.23%;
	right: 72.73%;
	top: 9.1%;
	bottom: 89.26%;
	font-size: 12px;
	line-height: 16px;
	color: #768d95;
`;

export const ChartNameInput = styled.div`
	left: 6.3%;
	right: 8.26%;
	top: 18.23%;
	bottom: 76.22%;
`;

export const ChartNameText = styled.span`
	left: 10.23%;
	right: 76.79%;
	top: 17.16%;
	bottom: 81.2%;
	font-size: 12px;
	line-height: 16px;
	color: #768d95;
`;

export const DescriptionInput = styled.div`
	left: 6.3%;
	right: 8.26%;
	top: 26.45%;
	bottom: 68.01%;
	background: #ffffff;
`;

export const SeriesInput = styled.div`
	left: 6.3%;
	right: 8.26%;
	top: 34.46%;
	bottom: 60%;
	background: #ffffff;
`;

export const SeriesText = styled.span`
	left: 10.23%;
	right: 80.34%;
	top: 33.43%;
	bottom: 64.93%;
	font-size: 12px;
	line-height: 16px;
	color: #768d95;
`;

export const TypeInput = styled.div`
	left: 6.3%;
	right: 8.26%;
	top: 42.46%;
	bottom: 51.99%;
	background: #ffffff;
`;

export const TypeText = styled.span`
	left: 10.23%;
	right: 80.34%;
	top: 41.44%;
	bottom: 56.92%;
	font-size: 12px;
	line-height: 16px;
	color: #768d95;
`;

export const Configure = styled.div`
	left: 10.23%;
	right: 80.34%;
	top: 41.44%;
	bottom: 56.92%;
	font-size: 12px;
	line-height: 16px;
	display: 'inline';
	color: #768d95;
`;

export const Preview = styled.div`
	left: 10.23%;
	right: 80.34%;
	top: 41.44%;
	bottom: 56.92%;
	font-size: 12px;
	line-height: 16px;
	display: 'inline';
	color: #768d95;
`;

//Grid Styles
export const TextContent = styled.div`
	position: absolute;
	width: 187px;
	height: 14.52px;
	margin-left: 10px;
	margin-top: 6px;
	font-size: 14px;
	line-height: 20px;
`;

export const ImgContentAddIcon = styled.div`
	position: absolute;
	left: 2.72%;
	right: 89.75%;
	top: 80.94%;
	bottom: 75.2%;
	background: #ffffff;
`;

export const ImgContentMainIcon = styled.div`
	left: 20.3%;
	right: 4.7%;
	top: 18%;
	bottom: 8%;
	position: absolute;
`;
