import { Layout } from 'react-grid-layout';
import {
	ChartDashletOptions,
	DashletFooterOption,
	DashletHeaderOption,
	DashletType,
	GaugeDashletOptions,
	MapDashletOptions,
	WorkspaceItem,
	ChartData,
	ChartFormData,
	GaugeFormData,
	MapFormData,
} from '../../../types/workspace';
import { SiteConfig } from '../../SiteConfiguration/SiteConfig';
import { ChartType, GaugeType } from '../CreateDashlet/CreateDashletService';

export const defaultDashletHeaderOption: DashletHeaderOption = {
	dashletId: -1,
	visible: true,
	dateTimeFrom: '03/05/2020',
	dateTimeTo: '05/05/2020',
	isLiveFeed: true,
	interval: 2000,
};

export const defaultDashletFooterOption: DashletFooterOption = {
	dashletId: -1,
	visible: true,
	rangeStartDateTime: '10/10/2018',
	rangeEndDateTime: '05/20/2020',
	navData: [],
};

export const defaultChartData: ChartDashletOptions = {
	sensorId: '12',
	name: 'Channel 12',
	description: '',
	series: 'average',
	chartType: ChartType.Line,
	dashletTypeId: DashletType.Chart,
	referenceId: 85,
	dateTimeFrom: '3/3/2020',
	dateTimeTo: '5/5/2020',
	color: 'rgba(0, 124, 160, 1)',
	interval: 2000,
};

export const defaultGaugeData: GaugeDashletOptions = {
	sensorId: '12',
	name: 'Channel 12',
	gaugeValue: 130,
	description: '',
	series: 'average',
	chartType: GaugeType.CircularGauge,
	dashletTypeId: DashletType.Gauge,
	referenceId: 85,
	dateTimeFrom: '3/5/2020',
	dateTimeTo: '5/5/2020',
	color: '#ffaa66',
	interval: 2000,
};

export const defaultMapData: MapDashletOptions = {
	name: 'Channel 12',
	description: '',
	dashletTypeId: DashletType.Map,
	referenceId: 85,
};

export const defaultWsItem: WorkspaceItem = {
	columnPosition: 0,
	createBy: '',
	dashletId: -1,
	dashletTypeId: DashletType.Chart,
	height: 4,
	id: -1,
	modifiedBy: '',
	options: JSON.stringify({
		sensorId: '12',
		name: 'Channel 12',
		description: '',
		series: 'average',
		chartType: ChartType.Line,
		dashletTypeId: DashletType.Chart,
		referenceId: 85,
		dateTimeFrom: '01/01/2020',
		dateTimeTo: '05/05/2020',
		color: 'rgba(0, 124, 160, 1)',
		interval: 2000,
	}),
	rowPosition: 0,
	status: '',
	width: 4,
};

export const defaultGaugeWsItem: WorkspaceItem = {
	columnPosition: 0,
	createBy: '',
	dashletId: -1,
	dashletTypeId: DashletType.Gauge,
	height: 4,
	id: -1,
	modifiedBy: '',
	options: JSON.stringify(defaultGaugeData),
	rowPosition: 0,
	status: '',
	width: 4,
};

export const defaultMapWsItem: WorkspaceItem = {
	columnPosition: 0,
	createBy: '',
	dashletId: -1,
	dashletTypeId: DashletType.Map,
	height: 4,
	id: -1,
	modifiedBy: '',
	options: JSON.stringify(defaultMapData),
	rowPosition: 0,
	status: '',
	width: 4,
};

export const defaultLayout: Layout = {
	i: '1',
	w: 4,
	h: 4,
	x: 0,
	y: 1,
	isDraggable: true,
	isResizable: true,
};

export const BaseUnitEnum = [
	{ Name: 'Raw', ID: 1 },
	{ Name: 'Minute 1', ID: 1000 },
	{ Name: 'Minute 5', ID: 2000 },
	{ Name: 'Minute 10', ID: 2500 },
	{ Name: 'Minute 15', ID: 3000 },
	{ Name: 'Minute 30', ID: 3500 },
	{ Name: 'Hourly', ID: 4000 },
	{ Name: 'Daily', ID: 4500 },
];

export const defaultMapConfig: SiteConfig = {
	mapbox: {
		key:
			'pk.eyJ1IjoiaW5ub3Z5emUiLCJhIjoiY2s2aHI1bGpkMmsxMDNtcnpvaWpybHI3bSJ9._oKB33CJCA5tqCSua1nvBA',
	},
	auth0: {
		domain: 'harvi-staging.auth0.com',
		clientId: 'bc0pbuBj76avsqgAqMQonkjzlhAub7vX',
		audience: 'https://harvi-api-access.com/',
		tokenKeyPrefix: '',
	},
	harvi: {
		api: 'https://dev.eu.myharviapp.com/',
	},
	remoteUrl: {
		baseUrl:
			'https://5u1a21gg4c.execute-api.us-west-1.amazonaws.com/Stage/api/',
	},
};

export const defaultMapToken =
	'foqLFusNFgj37oq78Rri9g43Wt18CVCrXtHLFos3vm7JVvZ0Pb_VTVhksf2pKXa570LNNt6wxqCa3xHG_9kWuVU2KkHIhcUvGaJthTp3obvaL6_vqkSgcVKrTBqiPWvPhjvYVo7hFezV9mP7MaHx_lAH9YaOSB1D8nmc0hGuaVknHXDvck1zj8ZsP8gsxbw41g0ufQoWs0iRAJvcJI4SNDvQF3T__T2S0znC1MhcQjl62EaGf1ZlhFUWQRG7FIqMhTOUoMnDkLBEJRNoFFv_MhHgO3JgGnvYNw';

export const filterNavData = (
	navData: Array<ChartData>,
): Promise<Array<ChartData>> => {
	if (navData.length > 1000) {
		return new Promise(resolve => {
			const newNavData: Array<ChartData> = [];
			navData.map((data: ChartData, index: number) => {
				if (index % 100 === 0) {
					newNavData.push(data);
				}
			});
			resolve(newNavData);
		});
	} else {
		return new Promise(resolve => {
			resolve(navData);
		});
	}
};

export const defaultChartFormData: ChartFormData = {
	channelId: 12,
	name: '',
	description: '',
	chartType: ChartType.Line,
	series: 'average',
};
export const defaultGaugeFormData: GaugeFormData = {
	channelId: 12,
	name: '',
	description: '',
	gaugeType: GaugeType.CircularGauge,
	needleColor: '#00ABD1',
	showLast24Hr: true,
};
export const defaultMapFormData: MapFormData = {
	name: '',
	description: '',
};
