import styled from 'styled-components';

export const HomePage = styled.div`
	padding: 1rem;
`;

export const DatetimeStampLabel = styled.label`
	display: flex;
	font-size: 0.75rem;
	line-height: 1rem;
`;

export const GreetingMessageLabel = styled.label`
	font-size: 1.5rem;
	line-height: 2rem;
`;

export const SystemStatusLabel = styled.label`
	display: flex;
	font-style: normal;
	font-weight: normal;
	font-size: 0.875rem;
	line-height: 1.25rem;
	text-align: right;
`;

export const SystemStatusIndicator = styled.label`
	font-size: 0.625rem;
	line-height: 1rem;
	color: #219653;
	border: 1px solid #19ab4f;
	box-sizing: border-box;
	border-radius: 2px;
	align-items: center;
	text-align: center;
	text-transform: uppercase;
	float: right;
	width: 4rem;
`;

export const BorderlessTile = styled.div``;

export const BorderlessTileFloatRight = styled(BorderlessTile)`
	float: right;
`;

export const ContainerTile = styled.div`
	padding: 1rem 1.25rem;
	box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2), 0px 2px 1px rgba(0, 0, 0, 0.12),
		0px 1px 1px rgba(0, 0, 0, 0.14);
`;

export const SmallContainerTile = styled(ContainerTile)`
	height: 6.5rem;
`;

export const LargeContainerTile = styled(ContainerTile)`
	height: 18rem;
`;

export const NewAlertSmallContainer = styled(SmallContainerTile)`
	border-top: 0.25rem solid #efb304;
`;

export const UnresolvedAlertSmallContainer = styled(SmallContainerTile)`
	border-top: 0.25rem solid #e66b45;
`;

export const OutageSmallContainer = styled(SmallContainerTile)`
	border-top: 0.25rem solid #83bc40;
`;

export const TileTitle = styled.label`
	display: flex;
	font-style: normal;
	font-weight: 600;
	font-size: 0.75rem;
	line-height: 1.5rem;
`;

export const AlertTileTile = styled(TileTitle)`
	font-size: 0.75rem;
	line-height: 1rem;
`;

export const AlertTileContent = styled(TileTitle)`
	font-size: 1.5rem;
	line-height: 2rem;
`;

export default HomePage;
