import {
	Workspace,
	WorkspaceError,
	MapFormDataError,
	MapFormData,
	ChartFormDataError,
	ChartFormData,
	GaugeFormData,
	GaugeFormDataError,
} from '../../types/workspace';

export const checkWorkspaceErrors = ({
	name,
}: Workspace): Array<WorkspaceError> => {
	let errors: Array<WorkspaceError> = [];
	if (name === '') {
		const nameError = {
			fieldName: 'name',
			errorMessage: 'Workspace name is required.',
		};
		errors = [...errors, nameError];
	}
	return errors;
};

export const checkMapFormDataErrors = ({
	name,
	description,
	errors,
}: MapFormData): Array<MapFormDataError> => {
	let newErrors: Array<MapFormDataError> = errors ? errors : [];

	//check for name empty error
	if (name === '') {
		//to add error when name is empty
		const nameError = {
			fieldName: 'name',
			errorMessage: 'Map name is required.',
		};
		if (!newErrors.find(err => err.fieldName === 'name'))
			newErrors = [...newErrors, nameError];
	} else {
		//to remove error when name is valid
		newErrors.map((error: MapFormDataError, index: number) => {
			if (error.fieldName === 'name') newErrors.splice(index, 1);
		});
	}

	if (description === '') {
		//to add error when description is empty
		const descriptionError = {
			fieldName: 'description',
			errorMessage: 'Map Description is required.',
		};
		if (!newErrors.find(err => err.fieldName === 'description'))
			newErrors = [...newErrors, descriptionError];
	} else {
		//to remove error when description is valid
		newErrors.map((error: MapFormDataError, index: number) => {
			if (error.fieldName === 'description') newErrors.splice(index, 1);
		});
	}

	return newErrors;
};

export const checkChartFormDataErrors = ({
	name,
	selectedChannel,
	errors,
}: ChartFormData): Array<ChartFormDataError> => {
	let newErrors: Array<ChartFormDataError> = errors ? errors : [];

	//check for name empty error
	if (name === '') {
		//to add error when name is empty
		const nameError = {
			fieldName: 'name',
			errorMessage: 'Chart name is required.',
		};
		if (!newErrors.find(err => err.fieldName === 'name'))
			newErrors = [...newErrors, nameError];
	} else {
		//to remove error when name is valid
		newErrors.map((error: ChartFormDataError, index: number) => {
			if (error.fieldName === 'name') newErrors.splice(index, 1);
		});
	}

	if (
		!selectedChannel ||
		selectedChannel.displayName === '' ||
		selectedChannel.id === -1
	) {
		//to add error when name is empty
		const nameError = {
			fieldName: 'channel',
			errorMessage: 'Chart data source is required.',
		};
		if (!newErrors.find(err => err.fieldName === 'channel'))
			newErrors = [...newErrors, nameError];
	} else {
		//to remove error when name is valid
		newErrors.map((error: ChartFormDataError, index: number) => {
			if (error.fieldName === 'channel') newErrors.splice(index, 1);
		});
	}

	return newErrors;
};

export const checkGaugeFormDataErrors = ({
	name,
	selectedChannel,
	thresholdFrom,
	thresholdTo,
	errors,
}: GaugeFormData): Array<GaugeFormDataError> => {
	let newErrors: Array<GaugeFormDataError> = errors ? errors : [];

	//check for name empty error
	if (name === '') {
		//to add error when name is empty
		const nameError = {
			fieldName: 'name',
			errorMessage: 'Gauge name is required.',
		};
		newErrors = [...newErrors, nameError];
	} else {
		//to remove error when name is valid
		newErrors.map((error: GaugeFormDataError, index: number) => {
			if (error.fieldName === 'name') newErrors.splice(index, 1);
		});
	}

	if (
		!selectedChannel ||
		selectedChannel.displayName === '' ||
		selectedChannel.id === -1
	) {
		//to add error when name is empty
		const nameError = {
			fieldName: 'channel',
			errorMessage: 'Gauge data source is required.',
		};
		if (!newErrors.find(err => err.fieldName === 'channel'))
			newErrors = [...newErrors, nameError];
	} else {
		//to remove error when name is valid
		newErrors.map((error: GaugeFormDataError, index: number) => {
			if (error.fieldName === 'channel') newErrors.splice(index, 1);
		});
	}

	//check if thresholdFrom exist and thresholdTo is null
	if (thresholdFrom && !thresholdTo) {
		const nameError = {
			fieldName: 'thresholdTo',
			errorMessage: 'Gauge threshold to cannot be empty.',
		};
		if (!newErrors.find(err => err.fieldName === 'thresholdTo'))
			newErrors = [...newErrors, nameError];
	} else {
		//to remove error when name is valid
		newErrors.map((error: GaugeFormDataError, index: number) => {
			if (error.fieldName === 'thresholdTo') newErrors.splice(index, 1);
		});
	}

	//check if thresholdTo exist and thresholdFrom is null
	if (!thresholdFrom && thresholdTo) {
		const nameError = {
			fieldName: 'thresholdFrom',
			errorMessage: 'Gauge threshold from cannot be empty.',
		};
		if (!newErrors.find(err => err.fieldName === 'thresholdFrom'))
			newErrors = [...newErrors, nameError];
	} else {
		//to remove error when name is valid
		newErrors.map((error: GaugeFormDataError, index: number) => {
			if (error.fieldName === 'thresholdFrom') newErrors.splice(index, 1);
		});
	}

	//check if thresholdTo is greater than thresholdFrom
	if (
		thresholdFrom &&
		thresholdTo &&
		!isNaN(thresholdFrom) &&
		!isNaN(thresholdTo) &&
		thresholdTo > thresholdFrom
	) {
		const nameError = {
			fieldName: 'thresholdTo',
			errorMessage: 'Gauge threshold to cannot be greater.',
		};
		if (!newErrors.find(err => err.fieldName === 'thresholdTo'))
			newErrors = [...newErrors, nameError];
	}

	return newErrors;
};
