import {
	Channel,
	Workspace,
	WorkspaceItem,
	WorkspaceGridMode,
	MapFormData,
	ChartFormData,
	GaugeFormData,
} from '../../types/workspace';
import { Action } from '../actions/workspace';
import {
	ADD_ITEM_DRAWER_ACTION,
	ADD_WORKSPACE_ITEM,
	CHECK_WORKSPACE_SETTINGS_ERRORS,
	CLOSE_ALERT,
	DELETE_WORKSPACE_FAILED,
	DRAWER_CANCEL_CLICKED,
	DRAWER_DESCRIPTION_BLURRED,
	DRAWER_NAME_BLURRED,
	FETCHING_WORKSPACE,
	FETCH_WORKSPACE_FAILED,
	FOCUS_MODE_FETCH_SENSOR_LIST,
	FOCUS_MODE_FETCH_SENSOR_LIST_FAIL,
	INIT_WSITEM_TO_FOCUS_MODE,
	INIT_WSITEM_TO_WORKSPACE,
	OPEN_SETTINGS_DRAWER,
	REMOVE_WORKSPACE_ITEM,
	SAVE_WORKSPACE_FAILED,
	SAVING_WORKSPACE,
	UPDATE_WSITEM_TO_WORKSPACE,
	WORKSPACE_DELETED,
	WORKSPACE_FETCHED,
	WORKSPACE_LAYOUT_CHANGED,
	WORKSPACE_SAVED,
	LOADING_WSITEM,
	MAP_FORM_INPUT_BLURRED,
	CHART_FORM_INPUT_BLURRED,
	GAUGE_FORM_INPUT_BLURRED,
	LOADING_ACTIVE_WSITEM,
} from '../constants/workspace';
import { WorkspaceState } from '../types/workspace';
import {
	checkWorkspaceErrors,
	checkMapFormDataErrors,
	checkChartFormDataErrors,
	checkGaugeFormDataErrors,
} from '../utils/workspace';
import {
	defaultMapFormData,
	defaultChartFormData,
	defaultGaugeFormData,
} from '../../Components/Info360/WorkspaceGrid/WorkspaceGridService';

export const initialWorkspaceState: WorkspaceState = {
	deleted: false,
	errors: [],
	fetching: true,
	isDirty: false,
	settingsDrawerOpen: false,
	settingsDrawerWorkspace: {
		id: 0,
		name: '',
		description: '',
		ownerId: '',
		permission: 0,
		options: '',
		status: '',
		createBy: '',
		modifiedBy: '',
	},
	workspace: {
		id: 0,
		name: '',
		description: '',
		ownerId: '',
		permission: 0,
		options: '',
		status: '',
		createBy: '',
		modifiedBy: '',
	},
	sidePanelOption: {
		displayMode: 1,
		addItemDrawerOpen: false,
		action: WorkspaceGridMode.ADD_TO_WORKSPACE,
		contentDisplayMode: 1,
		mapFormData: undefined,
		chartFormData: undefined,
		gaugeFormData: undefined,
	},
};

//Reducer
const workspaceReducer = (
	state: WorkspaceState = initialWorkspaceState,
	action: Action,
): WorkspaceState => {
	switch (action.type) {
		case CLOSE_ALERT: {
			return {
				...state,
				alert: undefined,
			};
		}
		case WORKSPACE_FETCHED: {
			const workspace = action.payload;
			return {
				...state,
				fetching: false,
				workspace,
				sidePanelOption: {
					...state.sidePanelOption,
					addItemDrawerOpen: false,
				},
			};
		}
		case FETCH_WORKSPACE_FAILED: {
			return {
				...state,
				alert: {
					text: 'Unable to load workspace. Please reload this page.',
					severity: 'error',
				},
			};
		}
		case WORKSPACE_DELETED: {
			return {
				...initialWorkspaceState,
				deleted: true,
			};
		}
		case DELETE_WORKSPACE_FAILED: {
			return {
				...state,
				alert: {
					text: 'Unable to delete workspace. Please try again.',
					severity: 'error',
				},
			};
		}
		case SAVING_WORKSPACE: {
			return {
				...state,
				alert: undefined,
			};
		}
		case WORKSPACE_SAVED: {
			const newWorkspace: Workspace = state.settingsDrawerOpen
				? // saved from "settings" drawer, copy name and description to workspace
				  {
						...state.workspace,
						name: state.settingsDrawerWorkspace.name,
						description: state.settingsDrawerWorkspace.description,
				  }
				: // not saved from "settings" drawer
				  state.workspace;
			return {
				...state,
				alert: {
					text: 'Workspace saved.',
					severity: 'success',
				},
				errors: [],
				isDirty: false,
				settingsDrawerOpen: false,
				settingsDrawerWorkspace:
					initialWorkspaceState.settingsDrawerWorkspace,
				workspace: newWorkspace,
			};
		}
		case SAVE_WORKSPACE_FAILED: {
			return {
				...state,
				alert: {
					text: 'Unable to save workspace. Please try again.',
					severity: 'error',
				},
			};
		}
		case OPEN_SETTINGS_DRAWER: {
			return {
				...state,
				alert: undefined,
				settingsDrawerOpen: true,
				settingsDrawerWorkspace: state.workspace,
			};
		}
		case DRAWER_CANCEL_CLICKED: {
			return {
				...state,
				errors: [],
				settingsDrawerOpen: false,
				settingsDrawerWorkspace:
					initialWorkspaceState.settingsDrawerWorkspace,
			};
		}
		case DRAWER_DESCRIPTION_BLURRED: {
			return {
				...state,
				settingsDrawerWorkspace: {
					...state.settingsDrawerWorkspace,
					description: action.payload,
				},
			};
		}
		case DRAWER_NAME_BLURRED: {
			const newWorkspace = {
				...state.settingsDrawerWorkspace,
				name: action.payload,
			};
			return {
				...state,
				errors: checkWorkspaceErrors(newWorkspace),
				settingsDrawerWorkspace: newWorkspace,
			};
		}
		case FETCHING_WORKSPACE: {
			return initialWorkspaceState;
		}
		case CHECK_WORKSPACE_SETTINGS_ERRORS: {
			return {
				...state,
				errors: checkWorkspaceErrors(state.settingsDrawerWorkspace),
			};
		}
		case WORKSPACE_LAYOUT_CHANGED: {
			const { workspaceItem } = state.workspace;
			const newLayouts = action.payload;
			let newIsDirty = false;
			let newItems: Array<WorkspaceItem> = [];
			let newAlert = state.alert;
			if (workspaceItem) {
				newItems = workspaceItem.map((item, index) => {
					const targetLayout = newLayouts.find(
						lay => Number(lay.i) === index,
					);
					let newItem = item;
					if (
						targetLayout &&
						(targetLayout.x !== item.rowPosition ||
							targetLayout.y !== item.columnPosition ||
							targetLayout.w !== item.width ||
							targetLayout.h !== item.height)
					) {
						newAlert = undefined;
						newIsDirty = true;
						newItem = {
							...item,
							columnPosition: targetLayout.y,
							rowPosition: targetLayout.x,
							width: targetLayout.w,
							height: targetLayout.h,
						};
					}
					return newItem;
				});
			}
			return {
				...state,
				alert: newAlert,
				isDirty: newIsDirty,
				workspace: {
					...state.workspace,
					workspaceItem: newItems,
				},
			};
		}
		case REMOVE_WORKSPACE_ITEM: {
			const workspaceItem = state.workspace.workspaceItem?.filter(
				(item, index) => index !== action.payload,
			);
			return {
				...state,
				workspace: {
					...state.workspace,
					workspaceItem,
				},
			};
		}
		case ADD_WORKSPACE_ITEM: {
			const newWorkspaceItem = action.payload;

			const { workspace, sidePanelOption } = state;
			let wsItemList = workspace.workspaceItem;
			if (
				workspace &&
				workspace.workspaceItem &&
				workspace.workspaceItem.length > 0
			) {
				wsItemList = workspace.workspaceItem;
				wsItemList.push(newWorkspaceItem);
			} else {
				wsItemList = [];
				wsItemList.push(newWorkspaceItem);
			}

			return {
				...state,
				sidePanelOption: {
					...sidePanelOption,
					addItemDrawerOpen: false,
				},
				workspace: {
					...state.workspace,
					workspaceItem: wsItemList,
				},
			};
		}
		case FOCUS_MODE_FETCH_SENSOR_LIST: {
			const channelList: Channel[] = action.payload;
			return {
				...state,
				workspace: {
					...state.workspace,
					channelList: channelList,
				},
			};
		}
		case FOCUS_MODE_FETCH_SENSOR_LIST_FAIL: {
			return state;
		}
		case INIT_WSITEM_TO_WORKSPACE: {
			const itemToUpdate: WorkspaceItem = action.payload;
			const newWorkspaceItem = state.workspace.workspaceItem?.map(item =>
				item.id === itemToUpdate.id ? itemToUpdate : item,
			);
			return {
				...state,
				workspace: {
					...state.workspace,
					workspaceItem: newWorkspaceItem,
				},
			};
		}
		case UPDATE_WSITEM_TO_WORKSPACE: {
			const itemToUpdate: WorkspaceItem = action.payload;
			const newWorkspaceItem = state.workspace.workspaceItem?.map(item =>
				item.id === itemToUpdate.id ? itemToUpdate : item,
			);
			return {
				...state,
				workspace: {
					...state.workspace,
					workspaceItem: newWorkspaceItem,
				},
			};
		}
		case ADD_ITEM_DRAWER_ACTION: {
			const sidePanelOption = action.payload;
			return {
				...state,
				sidePanelOption: {
					...sidePanelOption,
				},
			};
		}
		case INIT_WSITEM_TO_FOCUS_MODE: {
			//const sidePanelOption = state.sidePanelOption;
			const wsItem = action.payload;

			return {
				...state,
				activeWorkspaceItem: wsItem,
			};
		}
		case LOADING_WSITEM: {
			const itemToUpdate: WorkspaceItem = action.payload;
			const newWorkspaceItem = state.workspace.workspaceItem?.map(item =>
				item.id === itemToUpdate.id ? itemToUpdate : item,
			);
			return {
				...state,
				workspace: {
					...state.workspace,
					workspaceItem: newWorkspaceItem,
				},
			};
		}
		case LOADING_ACTIVE_WSITEM: {
			const itemToUpdate: WorkspaceItem = action.payload;
			const newWorkspaceItem = state.workspace.workspaceItem?.map(item =>
				item.id === itemToUpdate.id ? itemToUpdate : item,
			);
			return {
				...state,
				workspace: {
					...state.workspace,
					workspaceItem: newWorkspaceItem,
				},
			};
		}
		//map form events
		case MAP_FORM_INPUT_BLURRED: {
			const mapFormData: MapFormData | undefined =
				state.sidePanelOption.mapFormData;

			let newMapFormData: MapFormData = defaultMapFormData;
			if (mapFormData) {
				newMapFormData = {
					...action.payload,
				};
			}
			return {
				...state,
				sidePanelOption: {
					...state.sidePanelOption,
					mapFormData: {
						...newMapFormData,
						errors: checkMapFormDataErrors(newMapFormData),
					},
				},
			};
		}
		case CHART_FORM_INPUT_BLURRED: {
			const chartFormData: ChartFormData | undefined =
				state.sidePanelOption.chartFormData;

			let newChartFormData: ChartFormData = defaultChartFormData;
			if (chartFormData) {
				newChartFormData = {
					...action.payload,
				};
			}
			return {
				...state,
				sidePanelOption: {
					...state.sidePanelOption,
					chartFormData: {
						...newChartFormData,
						errors: checkChartFormDataErrors(newChartFormData),
					},
				},
			};
		}
		case GAUGE_FORM_INPUT_BLURRED: {
			const gaugeFormData: GaugeFormData | undefined =
				state.sidePanelOption.gaugeFormData;

			let newGaugeFormData: GaugeFormData = defaultGaugeFormData;
			if (gaugeFormData) {
				newGaugeFormData = {
					...action.payload,
				};
			}
			return {
				...state,
				sidePanelOption: {
					...state.sidePanelOption,
					gaugeFormData: {
						...newGaugeFormData,
						errors: checkGaugeFormDataErrors(newGaugeFormData),
					},
				},
			};
		}
		default:
			return state;
	}
};

export default workspaceReducer;
