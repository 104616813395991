import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import CreateDashletContent from '../../Components/Info360/CreateDashlet/CreateDashletContent';
import {
	ChartDashletOptions,
	DashletType,
	Display,
	GaugeDashletOptions,
	MapDashletOptions,
	SidePanelContent,
	SidePanelOption,
	WorkspaceItem,
	WorkspaceGridMode,
} from '../../types/workspace';
import {
	defaultChartData,
	defaultGaugeData,
	defaultMapData,
} from '../Info360/WorkspaceGrid/WorkspaceGridService';

interface Props {
	sidePanelOption: SidePanelOption;
	activeWorkspaceItem: WorkspaceItem;
	onButtonCancel: () => void;
	onButtonOk: () => void;
	handelAddWSItemToWorkspace: (
		chartData:
			| ChartDashletOptions
			| GaugeDashletOptions
			| MapDashletOptions,
	) => void;
	handelUpdateDashletOption: (wsItem: WorkspaceItem) => void;
	handelInitWSItemToFocus: (wsItem: WorkspaceItem) => void;
	handelItemDrawerAction: (sidePanelOption: SidePanelOption) => void;
}

const useStyles = makeStyles({
	drawer: {
		width: 552,
	},
	drawerPaper: {
		width: 552,
	},
});

const AddItemDrawer = ({
	onButtonCancel,
	sidePanelOption,
	activeWorkspaceItem,
	handelAddWSItemToWorkspace,
	handelItemDrawerAction,
	handelUpdateDashletOption,
	handelInitWSItemToFocus,
}: Props) => {
	const classes = useStyles();
	const _onButtonOk = () => {
		//normal adding to to workspace
		if (sidePanelOption.action == WorkspaceGridMode.ADD_TO_WORKSPACE) {
			const {
				displayMode,
				gaugeFormData,
				mapFormData,
				chartFormData,
			} = sidePanelOption;
			//add when display mode is chart
			if (displayMode === SidePanelContent.CreateChart && chartFormData) {
				const chartOption: ChartDashletOptions = {
					...defaultChartData,
					name: chartFormData.name,
					description: chartFormData.description,
					series: chartFormData.series,
					chartType: chartFormData.chartType,
				};
				//only add if no errors in gaugeFormData
				if (!chartFormData.errors || chartFormData.errors.length === 0)
					handelAddWSItemToWorkspace(chartOption);
			}
			//add when display mode is gauge
			else if (
				displayMode === SidePanelContent.CreateGauge &&
				gaugeFormData
			) {
				const gaugeOption: GaugeDashletOptions = {
					...defaultGaugeData,
					name: gaugeFormData.name,
					description: gaugeFormData.description,
					chartType: gaugeFormData.gaugeType,
					thresholdTo: gaugeFormData.thresholdTo,
					thresholdFrom: gaugeFormData.thresholdFrom,
					color: gaugeFormData.needleColor,
				};
				//only add if no errors in gaugeFormData
				if (!gaugeFormData.errors || gaugeFormData.errors.length === 0)
					handelAddWSItemToWorkspace(gaugeOption);
			}
			//add when display mode is map
			else if (
				displayMode === SidePanelContent.CreateMap &&
				mapFormData
			) {
				const mapOption: MapDashletOptions = {
					...defaultMapData,
					name: mapFormData.name,
					description: mapFormData.description,
				};
				//only add if no errors in mapFormData
				if (!mapFormData.errors || mapFormData.errors.length === 0)
					handelAddWSItemToWorkspace(mapOption);
			}
		}
		//to close the side panel and update the update item to workspace
		else if (
			sidePanelOption.action === WorkspaceGridMode.UPDATE_TO_WORKSPACE
		) {
			const {
				displayMode,
				gaugeFormData,
				mapFormData,
				chartFormData,
			} = sidePanelOption;

			//closing side panel and init side panel state
			const newSidePanelOption: SidePanelOption = {
				...sidePanelOption,
				action: WorkspaceGridMode.ADD_TO_WORKSPACE,
				addItemDrawerOpen: false,
				contentDisplayMode: Display.Properties,
				displayMode: SidePanelContent.MainGrid,
			};
			//change side panel state for close
			handelItemDrawerAction(newSidePanelOption);

			if (displayMode === SidePanelContent.CreateChart && chartFormData) {
				const chartOption: ChartDashletOptions = JSON.parse(
					activeWorkspaceItem.options,
				);
				const newChartOption: ChartDashletOptions = {
					...chartOption,
					name: chartFormData.name,
					description: chartFormData.description,
					series: chartFormData.series,
					chartType: chartFormData.chartType,
					//dashletInit: false,
				};
				const wsItem: WorkspaceItem = {
					...activeWorkspaceItem,
					dashletTypeId: DashletType.Chart,
					options: JSON.stringify(newChartOption),
				};
				handelUpdateDashletOption(wsItem);
			} else if (
				displayMode === SidePanelContent.CreateGauge &&
				gaugeFormData
			) {
				const gaugeOption: GaugeDashletOptions = JSON.parse(
					activeWorkspaceItem.options,
				);
				const newGaugeOption: GaugeDashletOptions = {
					...gaugeOption,
					name: gaugeFormData.name,
					description: gaugeFormData.description,
					chartType: gaugeFormData.gaugeType,
					thresholdTo: gaugeFormData.thresholdTo,
					thresholdFrom: gaugeFormData.thresholdFrom,
					color: gaugeFormData.needleColor,
					//dashletInit: false,
				};
				const wsItem: WorkspaceItem = {
					...activeWorkspaceItem,
					dashletTypeId: DashletType.Gauge,
					options: JSON.stringify(newGaugeOption),
				};
				handelUpdateDashletOption(wsItem);
			} else if (
				displayMode === SidePanelContent.CreateMap &&
				mapFormData
			) {
				const mapOption: MapDashletOptions = JSON.parse(
					activeWorkspaceItem.options,
				);
				const newMapOption: MapDashletOptions = {
					...mapOption,
					name: mapFormData.name,
					description: mapFormData.description,
				};
				const wsItem: WorkspaceItem = {
					...activeWorkspaceItem,
					dashletTypeId: DashletType.Map,
					options: JSON.stringify(newMapOption),
				};
				handelUpdateDashletOption(wsItem);
			}
		}
	};
	return (
		<Drawer
			anchor="right"
			className={classes.drawer}
			open={sidePanelOption.addItemDrawerOpen}
			classes={{ paper: classes.drawerPaper }}>
			<Title>Add to Workspace</Title>
			<Divider />
			<Content>
				<CreateDashletContent
					sidePanelOption={sidePanelOption}
					handelItemDrawerAction={handelItemDrawerAction}
					handelInitWSItemToFocus={
						handelInitWSItemToFocus
					}></CreateDashletContent>
			</Content>
			<Divider />
			<DrawerActions>
				<Button variant="contained" onClick={onButtonCancel}>
					CANCEL
				</Button>
				<Button
					variant="contained"
					onClick={_onButtonOk}
					color="primary">
					OK
				</Button>
			</DrawerActions>
		</Drawer>
	);
};

const Title = styled.div`
	padding: 24px;
	font-size: 18px;
	color: #00abd1;
`;

const Content = styled.div`
	padding: 24px;
	display: flex;
	flex-direction: column;
	flex: 1;
	& > div {
		width: 100%;
		margin-bottom: 24px;
	}
`;

const DrawerActions = styled.div`
	display: flex;
	justify-content: flex-end;
	padding: 24px;
	& > button {
		margin-left: 16px;
	}
`;

//Default Props
AddItemDrawer.defaultProps = {
	open: false,
};

AddItemDrawer.propTypes = {
	onButtonCancel: PropTypes.func,
	onButtonOk: PropTypes.func,
	onDescriptionBlur: PropTypes.func,
	onNameBlur: PropTypes.func,
	open: PropTypes.bool,
	workspace: PropTypes.shape({
		id: PropTypes.number,
		name: PropTypes.string,
		description: PropTypes.string,
	}),
	workspaceErrors: PropTypes.arrayOf(
		PropTypes.shape({
			fieldName: PropTypes.string,
			errorMessage: PropTypes.string,
		}),
	),
};

export default AddItemDrawer;
