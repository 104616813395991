import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import { History } from 'history';
import SearchBar from 'material-ui-search-bar';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import AlertSnackbar, {
	AlertInfo,
} from '../../Components/AlertSnackbar/AlertSnackbar';
import { SiteConfigContext } from '../../Components/SiteConfiguration/SiteConfigProvider';
import WorkspaceList from '../../Components/Workspaces/WorkspaceList';
import WorkspacePropertiesDrawer from '../../Components/Workspaces/WorkspacePropertiesDrawer';
import {
	closeAlert,
	drawerCancelClicked,
	drawerDescriptionBlurred,
	drawerNameBlurred,
	drawerOkClicked,
	fetchWorkspaces,
	newWorkspaceClicked,
	searchCanceled,
	searchValueChanged,
	selectionChanged,
	displayWorkspaceDeletedInfo,
} from '../../store/actions/workspaces';
import { RootState } from '../../store/types/root';
import {
	Workspace,
	WorkspaceError,
	WorkspacesMode,
} from '../../types/workspaces';
import Loading from '../../Components/Loading/Loading';

export interface Props {
	alert?: AlertInfo;
	workspaceDeleted: boolean;
	history: History;
	mode: WorkspacesMode;
	searchValue: string;
	newWorkspaceDrawerOpen: boolean;
	workingWorkspace: Workspace;
	workingWorkspaceErrors: Array<WorkspaceError>;
	workspacesInDataGrid: Array<Workspace>;
	closeAlert: () => void;
	deleteConfirmationNoClicked: () => void;
	deleteWorkspace: (id: number) => void;
	drawerCancelClicked: () => void;
	drawerDescriptionBlurred: (value: string) => void;
	drawerNameBlurred: (value: string) => void;
	drawerOkClicked: (
		workspace: Workspace,
		history: History,
		baseUrl: string,
	) => void;
	fetchWorkspaces: (baseUrl: string) => void;
	listDeleteClicked: (id: number) => void;
	newWorkspaceClicked: () => void;
	searchCanceled: () => void;
	searchValueChanged: (value: string) => void;
	selectionChanged: (id: number) => void;
	displayWorkspaceDeletedInfo: () => void;
}

//Component
export class Workspaces extends PureComponent<Props> {
	static contextType = SiteConfigContext;
	constructor(props: Props) {
		super(props);
	}

	componentDidMount() {
		if (this.props.workspaceDeleted) {
			this.props.displayWorkspaceDeletedInfo();
		}
		this.props.fetchWorkspaces(this.context.config.remoteUrl.baseUrl);
	}

	render() {
		const onSelectionChanged = (e: {
			selectedRowsData?: Workspace[];
		}): void => {
			if (e.selectedRowsData && e.selectedRowsData[0]) {
				const workspaceId = e.selectedRowsData[0].id;
				this.props.selectionChanged(workspaceId);
				this.props.history.push(
					`/workspace/${this.props.workingWorkspace.id}`,
				);
			}
		};

		const handleDrawerOk = (): void => {
			this.props.drawerOkClicked(
				this.props.workingWorkspace,
				this.props.history,
				this.context.config.remoteUrl.baseUrl,
			);
		};

		const handleDrawerCancel = (): void => {
			this.props.drawerCancelClicked();
		};

		const handleDrawerNameBlur = (
			event: React.FocusEvent<HTMLInputElement>,
		): void => {
			this.props.drawerNameBlurred(event.target.value);
		};

		const handleDrawerDescriptionBlur = (
			event: React.FocusEvent<HTMLInputElement>,
		): void => {
			this.props.drawerDescriptionBlurred(event.target.value);
		};

		return (
			<>
				<WorkspacesContainer>
					<Title>Workspaces</Title>
					<Loading
						loading={this.props.mode === WorkspacesMode.Fetching}>
						<WorkspacesActions>
							<Button
								id="new-workspace"
								color="primary"
								variant="contained"
								startIcon={<AddIcon />}
								onClick={() =>
									this.props.newWorkspaceClicked()
								}>
								NEW WORKSPACE
							</Button>
							<SearchBar
								onCancelSearch={() =>
									this.props.searchCanceled()
								}
								onChange={(value: string): void =>
									this.props.searchValueChanged(value)
								}
								placeholder={'Search Workspace'}
								value={this.props.searchValue}
								style={{
									boxShadow: 'none',
									width: 420,
									border: '1px solid rgba(165, 189, 198, 1)',
								}}
							/>
						</WorkspacesActions>
						<WorkspaceList
							dataSource={this.props.workspacesInDataGrid}
							onSelectionChanged={onSelectionChanged}
						/>
						<WorkspacePropertiesDrawer
							buttonOkDisabled={
								// disable create button while creating new workspace
								this.props.mode === WorkspacesMode.Creating
							}
							open={this.props.newWorkspaceDrawerOpen}
							workspace={this.props.workingWorkspace}
							workspaceErrors={this.props.workingWorkspaceErrors}
							onButtonOk={handleDrawerOk}
							onButtonCancel={handleDrawerCancel}
							onNameBlur={handleDrawerNameBlur}
							onDescriptionBlur={handleDrawerDescriptionBlur}
						/>
					</Loading>
				</WorkspacesContainer>
				<AlertSnackbar
					alert={this.props.alert}
					onClose={() => this.props.closeAlert()}
				/>
			</>
		);
	}
}

//Styles
const WorkspacesContainer = styled.div`
	padding: 2rem 1.25rem;
`;

const Title = styled.div`
	font-size: 1.5rem;
	font-weight: 600;
`;

const WorkspacesActions = styled.div`
	display: flex;
	margin: 32px 0 24px 0;
	align-items: center;
	justify-content: space-between;
	flex-grow: 1;
`;

//Map State
const mapStateToProps = (state: RootState) => {
	const { workspace, workspaces } = state;
	return {
		alert: workspaces.alert,
		newWorkspaceDrawerOpen: workspaces.newWorkspaceDrawerOpen,
		workspaceDeleted: workspace.deleted,
		mode: workspaces.mode,
		searchValue: workspaces.searchValue,
		workingWorkspace: workspaces.workingWorkspace,
		workingWorkspaceErrors: workspaces.workingWorkspaceErrors,
		workspaces: workspaces.workspaces,
		workspacesInDataGrid: workspaces.workspacesInDataGrid,
	};
};

//Map dispatch
const mapDispatchToProps = {
	closeAlert,
	drawerCancelClicked,
	drawerDescriptionBlurred,
	drawerNameBlurred,
	drawerOkClicked,
	fetchWorkspaces,
	newWorkspaceClicked,
	searchCanceled,
	searchValueChanged,
	selectionChanged,
	displayWorkspaceDeletedInfo,
};

//Export
export default connect(mapStateToProps, mapDispatchToProps)(Workspaces);
