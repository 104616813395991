import styled from 'styled-components';
import { Button } from '@material-ui/core';

export const ErrorButton = styled(Button)`
	&& {
		background-color: gray;
		&:hover {
			background-color: lightgray;
		}
	}
`;

export const StyledErrorPage = styled.div`
	background: #252f33;
	color: white;
	height: 100%;
	width: 100%;
	padding-top: 5%;
	padding-left: 15%;
	padding-right: 15%;
	margin: 0;
	overflow: auto;
`;

export const StyledErrorHeading = styled.div`
	float: left;
	width: 70%;
	padding: 1rem;
	padding-bottom: 2rem;
	h1 {
		margin: 0;
		font-size: 5vw;
	}
	h2 {
		margin: 0;
		font-size: 2vw;
	}
	hr {
		border: 0;
		height: 2px;
		background-image: linear-gradient(
			to right,
			rgba(255, 255, 255, 1),
			rgba(255, 255, 255, 0.75),
			rgba(255, 255, 255, 0)
		);
	}
`;

export const StyledErrorImage = styled.img`
	float: right;
	width: 30%;
	vertical-align: top;
`;

export const StyledErrorResolution = styled.div`
	padding: 1rem;
	width: 75%;
`;

export const StyledErrorDetail = styled.div`
	padding: 1rem;
	width: 100%;
	textarea {
		height: 12rem;
		width: 100%;
		font-family: 'Courier New', Courier, monospace;
		background-color: rgb(255, 240, 240);
		color: red;
	}
`;
