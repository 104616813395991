/**
@namespace Action_Types
*/
// Dashboard Types
export const RECENT_WORKSPACES_FETCHED = 'RECENT_WORKSPACES_FETCHED';
export type RECENT_WORKSPACES_FETCHED = typeof RECENT_WORKSPACES_FETCHED;

export const FETCH_RECENT_WORKSPACES_FAILED = 'FETCH_RECENT_WORKSPACES_FAILED';
export type FETCH_RECENT_WORKSPACES_FAILED = typeof FETCH_RECENT_WORKSPACES_FAILED;

export const SYSTEM_LOGS_FETCHED = 'SYSTEM_LOGS_FETCHED';
export type SYSTEM_LOGS_FETCHED = typeof SYSTEM_LOGS_FETCHED;

export const FETCH_SYSTEM_LOGS_FAILED = 'FETCH_SYSTEM_LOGS_FAILED';
export type FETCH_SYSTEM_LOGS_FAILED = typeof FETCH_SYSTEM_LOGS_FAILED;

export const CLOSE_ALERT = 'CLOSE_ALERT';
export type CLOSE_ALERT = typeof CLOSE_ALERT;
export const LATEST_ALERTS_FETCHED = 'LATEST_ALERTS_FETCHED';
export type LATEST_ALERTS_FETCHED = typeof LATEST_ALERTS_FETCHED;

export const FETCH_LATEST_ALERTS_FAILED = 'FETCH_LATEST_ALERTS_FAILED';
export type FETCH_LATEST_ALERTS_FAILED = typeof FETCH_LATEST_ALERTS_FAILED;
