import RangeSelector, {
	Background,
	Chart,
	CommonSeriesSettings,
	Margin,
	MaxRange,
	MinorTickInterval,
	Scale,
	Series,
	Size,
	SliderMarker,
	TickInterval,
} from 'devextreme-react/range-selector';
import moment from 'moment';
import React from 'react';
import {
	ChartDashletOptions,
	DashletFooterOption,
	WorkspaceItem,
} from '../../../../types/workspace';
import { defaultDashletFooterOption } from '../../WorkspaceGrid/WorkspaceGridService';
import Loading from '../../../Loading/Loading';

export interface Props {
	item: WorkspaceItem;
	onRangeOptionsChange: (wsItem: WorkspaceItem) => void;
	isFocusModeOn?: boolean;
}

const ChartRange = ({ item, onRangeOptionsChange, isFocusModeOn }: Props) => {
	const chartDashletOption: ChartDashletOptions = JSON.parse(item.options);
	//let gDateTimeFrom: string | number | Date  = moment.now();
	//let gDateTimeTo: string | number | Date  = moment.now();
	let footerOption: DashletFooterOption = defaultDashletFooterOption;
	if (chartDashletOption.dashletFooterOption != null) {
		footerOption = chartDashletOption.dashletFooterOption;
	}

	const processRange = (event: {
		value?: (string | number | Date)[] | undefined;
	}) => {
		if (
			event.value &&
			event.value[0] &&
			event.value[1] &&
			chartDashletOption.dashletInit &&
			(chartDashletOption.dateTimeFrom !=
				moment(event.value[0]).format('MM/DD/YYYY') ||
				chartDashletOption.dateTimeTo !=
					moment(event.value[1]).format('MM/DD/YYYY'))
		) {
			const options: ChartDashletOptions = {
				...chartDashletOption,
				dateTimeFrom: moment(event.value[0]).format('MM/DD/YYYY'),
				dateTimeTo: moment(event.value[1]).format('MM/DD/YYYY'),
			};
			const newWsItem: WorkspaceItem = {
				...item,
				options: JSON.stringify(options),
			};
			onRangeOptionsChange(newWsItem);
		}
	};

	const marginFactor = 7;
	//const heightFactor = 80;
	const widthFactor = 130;
	let chartRangeWidth = item.width * widthFactor;
	let chartRangeMargin = item.width * marginFactor;
	if (isFocusModeOn) {
		chartRangeWidth = 1040;
		chartRangeMargin = 56;
	}
	return (
		<div>
			<Loading loading={chartDashletOption.isLoading ? true : false}>
				<RangeSelector
					id="range-selector"
					dataSource={footerOption.navData}
					//defaultValue={defaultRange}
					value={[
						chartDashletOption.dateTimeFrom,
						chartDashletOption.dateTimeTo,
					]}
					onValueChanged={processRange}>
					<Size width={chartRangeWidth} />
					<Margin top={20} left={chartRangeMargin} />
					<Background color="#FFFFFF" />
					<Chart palette="material">
						<Size width={chartRangeWidth} />
						<CommonSeriesSettings
							type="line"
							argumentField="time"
						/>
						<Series valueField="close" />
					</Chart>
					<Scale
						valueType="datetime"
						startValue={footerOption.rangeStartDateTime}
						endValue={footerOption.rangeEndDateTime}>
						<TickInterval days={7} />
						<MinorTickInterval days={1} />
						<MaxRange months={3} />
					</Scale>
					<SliderMarker format="MM-dd-yyyy" />
				</RangeSelector>
			</Loading>
		</div>
	);
};

const ChartRangeSelector = React.memo(ChartRange);

export default ChartRangeSelector;
