import React from 'react';
import { Handle, Position } from 'react-flow-renderer';
import { MassBalanceElementType } from '../../types/massBalance';
import { Typography, makeStyles, Theme, createStyles } from '@material-ui/core';
import { MassBalanceDesignerNodeData } from '../../store/types/massbalancedesigner';

const MassBalanceDesignerNode = ({
	data,
}: {
	data: MassBalanceDesignerNodeData;
}) => {
	const getMassBalanceElementTitle = (
		elementType: MassBalanceElementType,
	) => {
		if (data.sensors && data.sensors.length > 0) {
			let content = '';
			for (let i = 0; i < 2; i++) {
				content += data.sensors[i] + ',\r\n';
			}
			if (data.sensors.length > 2) {
				content = content.trim();
				content += ', .etc';
			} else {
				content = content.trim();
				content = content.substring(0, content.length - 1);
			}
			return (
				<div>
					<Typography>{content}</Typography>
				</div>
			);
		} else {
			return (
				<div>
					<Typography>{elementType}</Typography>
					<Typography>(not set)</Typography>
				</div>
			);
		}
	};

	const getMassBalanceElementBgColor = (
		data: MassBalanceDesignerNodeData,
	) => {
		if (
			(data.sensors && data.sensors.length > 0) ||
			(data.title && data.title.length > 0)
		) {
			switch (data.elementType) {
				case MassBalanceElementType.Zone:
					return '#A5BDC6';
				case MassBalanceElementType.Inflow:
					return '#FF9C72';
				case MassBalanceElementType.Outflow:
					return '#FF9C72';
				case MassBalanceElementType.Storage:
					return '#60DDFF';
			}
		} else {
			return '#F1F4F4';
		}
	};

	const getMassBalanceElementColor = (data: MassBalanceDesignerNodeData) => {
		if (
			(data.sensors && data.sensors.length > 0) ||
			(data.title && data.title.length > 0)
		) {
			return '#252F33';
		} else {
			return '#A5BDC6';
		}
	};

	const getMassBalanceElementBorder = (data: MassBalanceDesignerNodeData) => {
		if (data.active) {
			return '0.1875rem solid #00ABD1';
		} else {
			return '0.0625rem solid #A5BDC6';
		}
	};

	const getMassBalanceElementWidth = (
		elementType: MassBalanceElementType,
	) => {
		switch (elementType) {
			case MassBalanceElementType.Zone:
				return '11.25rem';
			default:
				return '6.25rem';
		}
	};

	const useStyles = makeStyles((theme: Theme) =>
		createStyles({
			nodeContainer: {
				background: getMassBalanceElementBgColor(data),
				color: getMassBalanceElementColor(data),
				border: getMassBalanceElementBorder(data),
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				padding: '0.625rem',
				borderRadius: '0.3125rem',
				width: getMassBalanceElementWidth(data.elementType),
				height: '6.25rem',
				boxShadow: 'none !important',
			},
		}),
	);
	const classes = useStyles();

	return (
		<div className={classes.nodeContainer}>
			<div>{getMassBalanceElementTitle(data.elementType)}</div>
			<div>
				{(() => {
					switch (data.elementType) {
						case MassBalanceElementType.Zone:
							return (
								<div>
									<Handle
										type="target"
										position={Position.Left}
									/>
									<Handle
										type="source"
										position={Position.Right}
										id="outflowHandle"
									/>
									<Handle
										type="source"
										position={Position.Bottom}
										id="storageHandle"
									/>
								</div>
							);
						case MassBalanceElementType.Inflow:
							return (
								<div>
									<Handle
										type="source"
										position={Position.Right}
									/>
								</div>
							);
						case MassBalanceElementType.Outflow:
							return (
								<div>
									<Handle
										type="target"
										position={Position.Left}
									/>
								</div>
							);
						case MassBalanceElementType.Storage:
							return (
								<div>
									<Handle
										type="target"
										position={Position.Top}
									/>
								</div>
							);
					}
				})()}
			</div>
		</div>
	);
};

export default MassBalanceDesignerNode;
