import React, { ErrorInfo, useRef } from 'react';
import {
	StyledErrorPage,
	StyledErrorHeading,
	StyledErrorImage,
	StyledErrorResolution,
	StyledErrorDetail,
	ErrorButton,
} from './ErrorPage.styles';
import StyledApp, { GlobalStyle } from '../../App.styles';
import earth from './earth.png';

interface ErrorPageProps {
	error: Error;
	errorInfo: ErrorInfo;
}

const ErrorPage = (props: ErrorPageProps) => {
	const [showDetail, setShowDetail] = React.useState<boolean>(false);
	const textAreaRef = useRef<HTMLTextAreaElement>(null);
	const reloadPage = () => {
		location.reload();
	};
	const toggleDetail = () => {
		setShowDetail(!showDetail);
	};

	const copyToClipboard = (
		e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
	) => {
		textAreaRef?.current?.select();
		document.execCommand('copy');
		e.currentTarget.focus();
	};

	return (
		<StyledApp>
			<GlobalStyle />
			<StyledErrorPage>
				<StyledErrorImage alt="Earth" src={earth}></StyledErrorImage>
				<StyledErrorHeading>
					<h1>Oh Dear...</h1>
					<hr />
					<h2>Houston, we have a problem.</h2>
				</StyledErrorHeading>
				<StyledErrorResolution>
					To attempt to resolve this issue, you can try to:
					<ul>
						<li>
							<ErrorButton
								size="small"
								data-testing-id="page-refresh-button"
								onClick={reloadPage}>
								Refresh
							</ErrorButton>{' '}
							the page.
						</li>
						<li>
							Go back to the <a href="/">main page</a> of the
							site.
						</li>
						<li>
							To see more/less detail:
							<ErrorButton
								size="small"
								data-testing-id="error-detail-button"
								onClick={toggleDetail}>
								{showDetail ? 'Hide' : 'Show'}
							</ErrorButton>
						</li>
					</ul>
				</StyledErrorResolution>
				{showDetail === true && (
					<StyledErrorDetail data-testing-id="error-detail-view">
						<div>
							If you are able to log a bug with us, please grab
							the following information:
							<ErrorButton
								size="small"
								data-testing-id="copy-error-button"
								onClick={copyToClipboard}>
								Copy
							</ErrorButton>
						</div>
						<textarea
							ref={textAreaRef}
							readOnly={true}
							value={props?.error?.stack}></textarea>
					</StyledErrorDetail>
				)}
			</StyledErrorPage>
		</StyledApp>
	);
};

export default ErrorPage;
