//Styles

import styled from 'styled-components';

export const WidgetHeader = styled.div`
	height: 40px;
	margin-left: 0px;
	top: 209px;
	background: #ffffff;
	border: 1px solid #a5bdc6;
	padding: 10px;
	box-sizing: border-box;
	border-radius: 4px 4px 0px 0px;
`;

export const WidgetTitle = styled.span`
	height: 21.33px;
	min-width: 200px;
	width: auto;
	margin-left: 16px;
	margin-top: 8px;
	font-weight: 600;
	font-size: 18px;
	color: #252f33;
`;

export const FocusToolBarContainer = styled.div`
	width: 68%;
	height: 48px;
	margin-top: 26px;
	background: #f1f4f4;
`;

export const ChooseDSInfo = styled.div`
	width: 479.46px;
	height: 51px;
	margin-left: 113.54px;
	margin-top: 35px;
	padding-top: 9px;
	background: #f1f4f4;
	border-radius: 4px;
`;

export const DSInfoText = styled.span`
	width: 389px;
	height: 32px;
	margin-left: 20px;
	font-size: 24px;
	line-height: 32px;
	color: #a5bdc6;
`;

export const WidgetContainer = styled.div`
	min-height: 550px;
	margin-left: 15px;
	margin-top: 35.89px;
	height: 100%;
	background: #ffffff;
`;

export const ChartContainer = styled.div`
	margin-left: 50px;
	margin-right: 100.84px;
	margin-top: 100.84px;
	margin-bottom: 20px;
	height: 100%;
	width: 100%;
`;

export const GaugeContainer = styled.div`
	margin-left: 100.84px;
	margin-right: 100.84px;
	margin-top: 100.84px;
	margin-bottom: 100.84px;
	height: 100%;
	width: 100%;
`;

export const RangeContainer = styled.div`
	margin-left: 50px;
	margin-right: 100.84px;
	margin-bottom: 100.84px;
	width: 100%;
	bottom: 20px;
	height: 140px;
`;

export const MapContainer = styled.div`
	height: 90%;
	width: 100%;
	width: inherit;
	position: relative;
	overflow: hidden;
	padding: 0px;
`;

export const StyledDashletDesigner = styled.div`
	width: 100%;
	height: 100%;
	background: #fff;
	margin: 0.625rem;
`;
