import { SiteConfig } from '../Components/SiteConfiguration/SiteConfig';

export interface Workspace {
	id: number;
	name: string;
	description: string;
	ownerId: string;
	ownerName?: string;
	permission: number;
	options: string;
	status: string;
	createAt?: Date;
	createBy: string;
	modifiedAt?: Date;
	modifiedBy: string;
	workspaceItem?: Array<WorkspaceItem>;
	channelList?: Channel[];
	activeWorkspaceItem?: WorkspaceItem;
}

export interface WorkspaceItem {
	id: number;
	columnPosition: number;
	rowPosition: number;
	width: number;
	height: number;
	dashletId: number;
	dashletTypeId: DashletType;
	options: string;
	status: string;
	createBy: string;
	modifiedBy: string;
}

export enum DashletType {
	Chart = 1,
	Gauge = 2,
	Map = 3,
}

export interface WorkspaceError {
	errorMessage: string;
	fieldName?: string;
}

export interface MapFormDataError {
	errorMessage: string;
	fieldName?: string;
}

export interface ChartFormDataError {
	errorMessage: string;
	fieldName?: string;
}

export interface GaugeFormDataError {
	errorMessage: string;
	fieldName?: string;
}

export interface Channel {
	id: number;
	displayName: string;
	tagName: string;
	assetID: number;
	channelType: number;
	inputValue?: string;
}

export interface MapFormData {
	name: string;
	description: string;
	errors?: MapFormDataError[];
}

export interface GaugeFormData {
	channelId: number;
	selectedChannel?: Channel;
	name: string;
	description: string;
	gaugeType: string;
	thresholdFrom?: number;
	thresholdTo?: number;
	needleColor: string;
	showLast24Hr: boolean;
	errors?: GaugeFormDataError[];
}

export interface ChartFormData {
	channelId: number;
	selectedChannel?: Channel;
	name: string;
	description: string;
	series: string;
	chartType: string;
	errors?: ChartFormDataError[];
}

export interface SidePanelOption {
	//activeWorkspaceItem?: WorkspaceItem;
	mapFormData?: MapFormData;
	gaugeFormData?: GaugeFormData;
	chartFormData?: ChartFormData;
	displayMode: SidePanelContent;
	action: WorkspaceGridMode;
	addItemDrawerOpen: boolean;
	contentDisplayMode: Display;
}

export enum Display {
	Properties = 1,
	ReferenceChart = 2,
	DisplayOptions = 3,
	ToggleView = 4,
}

export enum SidePanelContent {
	MainGrid = 1,
	CreateChart = 2,
	CreateGauge = 3,
	CreateMap = 4,
}

export interface DashletHeaderOption {
	dashletId: number;
	visible: boolean;
	isLiveFeed: boolean;
	dateTimeFrom: string;
	dateTimeTo: string;
	interval: number;
}

export interface ChartData {
	time: string;
	high: number;
	low: number;
	open: number;
	close: number;
	average: number;
	rawBarCount: number;
	total: number;
}

export interface DashletFooterOption {
	dashletId: number;
	visible: boolean;
	rangeStartDateTime: string;
	rangeEndDateTime: string;
	navData: Array<ChartData>;
}

export interface ChartDashletOptions {
	sensorId: string;
	name: string;
	description: string;
	series: string;
	chartType: string;
	interval: number;
	dateTimeFrom: string;
	dateTimeTo: string;
	color: string;
	dashletTypeId: DashletType.Chart;
	referenceId: number;
	isFreezeMode?: boolean;
	chartData?: Array<ChartData>;
	dashletHeaderOption?: DashletHeaderOption;
	dashletFooterOption?: DashletFooterOption;
	dashletInit?: boolean;
	isLoading?: boolean;
}

export interface GaugeDashletOptions {
	sensorId: string;
	name: string;
	description: string;
	gaugeValue: number;
	chartData?: Array<ChartData>;
	thresholdFrom?: number;
	thresholdTo?: number;
	showLast24Hr?: boolean;
	series: string;
	chartType: string;
	interval: number;
	dateTimeFrom: string;
	dateTimeTo: string;
	color: string;
	dashletTypeId: DashletType.Gauge;
	referenceId: number;
	dashletInit?: boolean;
	isLoading?: boolean;
}

export interface MapDashletOptions {
	name: string;
	description: string;
	dashletTypeId: DashletType.Map;
	referenceId: number;
	config?: SiteConfig;
	token?: string;
}

export enum WorkspaceGridMode {
	ADD_TO_WORKSPACE = 'ADD_TO_WORKSPACE',
	UPDATE_TO_WORKSPACE = 'UPDATE_TO_WORKSPACE',
}

export enum EventTypeFromChartForm {
	CHANGE_NAME = 'CHANGE_NAME',
	CHANGE_DESCRIPTION = 'CHANGE_DESCRIPTION',
	CHANGE_CHANNEL = 'CHANGE_CHANNEL',
	CHANGE_INTERVAL = 'CHANGE_INTERVAL',
	CHANGE_CHART_TYPE = 'CHANGE_CHART_TYPE',
}

export enum EventTypeFromGaugeForm {
	CHANGE_NAME = 'CHANGE_NAME',
	CHANGE_DESCRIPTION = 'CHANGE_DESCRIPTION',
	CHANGE_CHANNEL = 'CHANGE_CHANNEL',
	CHANGE_GAUGE_TYPE = 'CHANGE_GAUGE_TYPE',
	CHANGE_THRESHOLD_TO = 'CHANGE_THRESHOLD_TO',
	CHANGE_THRESHOLD_FROM = 'CHANGE_THRESHOLD_FROM',
	CHANGE_NEEDLE_COLOR = 'CHANGE_NEEDLE_COLOR',
}
