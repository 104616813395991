function timeSpanAlias(milliseconds: number) {
	function numberEnding(number: number) {
		return number > 1 ? 's' : '';
	}

	let temp = Math.floor(milliseconds / 1000);
	const years = Math.floor(temp / 31536000);
	if (years) {
		if (years > 10) {
			return '> 10 yrs ago';
		}
		return years + ' yr' + numberEnding(years) + ' ago';
	}
	const days = Math.floor((temp %= 31536000) / 86400);
	if (days) {
		return days + ' day' + numberEnding(days) + ' ago';
	}
	const hours = Math.floor((temp %= 86400) / 3600);
	if (hours) {
		return hours + ' hr' + numberEnding(hours) + ' ago';
	}
	const minutes = Math.floor((temp %= 3600) / 60);
	if (minutes) {
		return minutes + ' min' + numberEnding(minutes) + ' ago';
	}
	const seconds = temp % 60;
	if (seconds) {
		return 'Few secs ago';
	}
	return 'Just now';
}
export function getTimeAliasByDate(date: string) {
	const accessDateTime = new Date(date).getTime();
	const currentDateTime = new Date().getTime();
	if (accessDateTime == new Date('01/01/1800 00:00:00').getTime()) {
		return 'N/A';
	}
	let alias = '';
	if (currentDateTime >= accessDateTime) {
		alias = timeSpanAlias(currentDateTime - accessDateTime);
	} else {
		alias = timeSpanAlias(accessDateTime - currentDateTime);
	}
	return alias;
}
export function getObjectTitle(name: string, description: string) {
	if (description) {
		return description;
	}
	return name;
}
