import { Coordinates } from './services/types';
export type ObjectValues = { [name: string]: string };
export type ObjectDeserialised = { [name: string]: unknown };

export const deserialiseObjectValues = (
	input: ObjectValues,
): ObjectDeserialised => {
	if (typeof input !== 'object') return {};
	return Object.entries(input)
		.map(([key, value]) => {
			try {
				return { [key]: JSON.parse(value) };
			} catch (e) {
				return { [key]: value };
			}
		})
		.reduce(
			(prev, current) => ({
				...prev,
				...current,
			}),
			{},
		);
};

// there are different levels of nesting of the geometries
// depending on the type so correcting the structure
// to be able to get an array of coords (array of two numbers):
// [[number, number], [number, number]]
export type NestedCoords = (
	| Coordinates
	| Coordinates[]
	| Coordinates[][]
	| Coordinates[][][]
)[][];
export const normalizeCoordinates = (array: NestedCoords): Coordinates[] => {
	return array
		.filter(c => c !== undefined && c !== null)
		.flat(4)
		.map((value, i, a) =>
			i % 2
				? ([a[i - 1], value] as Coordinates)
				: // the null will be filtered out with the .filter(Boolean) below
				  ((null as unknown) as Coordinates),
		)
		.filter(Boolean);
};

export const replacePlaceholder = (
	string: string,
	...data: (string | number)[]
): string => {
	return string.replace(
		/\{[0-9]{1,}\}/g,
		match => (data.shift() as string) || match,
	);
};

export interface DedupeArray {
	[key: string]: string;
}
export const dedupe = (array: DedupeArray[], key: string): DedupeArray[] => {
	return array.filter((v, i, a) => a.findIndex(t => t[key] === v[key]) === i);
};
