import { createBrowserHistory } from 'history';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import App from './App';
import { Auth0Provider } from './Components/Auth0/Auth0';
import ErrorHandler from './Components/ErrorHandler/ErrorHandler';
import { SiteConfigProvider } from './Components/SiteConfiguration/SiteConfigProvider';
import store from './store';
import { RedirectLoginResult } from '@auth0/auth0-spa-js';

const auth0RedirectUri = window.location.origin;
const history = createBrowserHistory();
const onAuthRedirectCallback = (redirectResult?: RedirectLoginResult) => {
	history.push(
		redirectResult &&
			redirectResult.appState &&
			redirectResult.appState.targetUrl
			? redirectResult.appState.targetUrl
			: window.location.pathname,
	);
};

// Load the site configuration from the locally stored (but not packaged) configuration file.
const root = document.getElementById('root');
const url = root?.getAttribute('data-config') ?? '';

ReactDOM.render(
	<ErrorHandler>
		<SiteConfigProvider url={url}>
			<Auth0Provider
				forceLogin={true}
				redirectUri={auth0RedirectUri}
				onRedirectCallback={onAuthRedirectCallback}>
				<Provider store={store}>
					<App />
				</Provider>
			</Auth0Provider>
		</SiteConfigProvider>
	</ErrorHandler>,
	root,
);
