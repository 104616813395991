import GeoJsonDataService from './GeoJsonDataService';
import { GeoJsonDataType, HarviResponseWrapper, DataType } from './types';

export default class HarviDataService extends GeoJsonDataService {
	parseResponse(data: unknown): DataType | null {
		const harviData = data as HarviResponseWrapper<GeoJsonDataType>;

		if (harviData.status_code < 200 || harviData.status_code > 299) {
			return null;
		}

		return harviData?.content;
	}
}
