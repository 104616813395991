import axios from 'axios';
import { AppDispatch, AppThunk } from '..';
import { debounce } from '../../services/utils';
import { MassBalance, SelectedTool } from '../../types/tools';
import * as constants from '../constants/tools';

// Action Types
export type ToolsAction =
	| ChangeSelectedTool
	| FetchingMassBalances
	| MassBalancesFetched
	| MassBalancesFetchFailed
	| CreatingMassBalance
	| MassBalanceCreated
	| MassBalanceCreateFailed
	| NewMassBalanceClicked
	| SearchMassBalances
	| MassBalancesSearchValueChanged
	| MassBalancesSearchCanceled
	| MassBalancesSelectionChanged
	| CloseAlert
	| MassBalanceCancelClick
	| AddNewMassBalance
	| DrawerNameBlurred
	| DrawerDescriptionBlurred;

// Action Definition
export interface MassBalanceCancelClick {
	type: constants.MASS_BALANCE_CANCEL_CLICKED;
}

export interface AddNewMassBalance {
	type: constants.ADD_NEW_MASS_BALANCE;
}

export interface ChangeSelectedTool {
	type: constants.CHANGE_SELECTED_TOOL;
	payload: SelectedTool;
}

export interface FetchingMassBalances {
	type: constants.FETCHING_MASS_BALANCES;
}

export interface MassBalancesFetched {
	type: constants.MASS_BALANCES_FETCHED;
	payload: Array<MassBalance>;
}

export interface MassBalancesFetchFailed {
	type: constants.MASS_BALANCES_FETCH_FAILED;
}
export interface NewMassBalanceClicked {
	type: constants.NEW_MASS_BALANCE_CLICKED;
}
export interface CreatingMassBalance {
	type: constants.CREATING_MASS_BALANCE;
}
export interface MassBalanceCreated {
	type: constants.MASS_BALANCE_CREATED;
	payload: number;
}
export interface MassBalanceCreateFailed {
	type: constants.MASS_BALANCE_CREATE_FAILED;
}
export interface SearchMassBalances {
	type: constants.SEARCH_MASS_BALANCES;
}
export interface MassBalancesSearchValueChanged {
	type: constants.MASS_BALANCES_SEARCH_VALUE_CHANGED;
	payload: string;
}
export interface MassBalancesSearchCanceled {
	type: constants.MASS_BALANCES_SEARCH_CANCELED;
}
export interface MassBalancesSelectionChanged {
	type: constants.MASS_BALANCES_SELECTION_CHANGED;
	payload: number;
}
export interface CloseAlert {
	type: constants.CLOSE_ALERT;
}

export interface DrawerNameBlurred {
	type: constants.DRAWER_NAME_BLURRED;
	payload: string;
}

export interface DrawerDescriptionBlurred {
	type: constants.DRAWER_DESCRIPTION_BLURRED;
	payload: string;
}

// Action Creators
export const changeSelectedTool = (
	newSelectedTool: SelectedTool,
): ToolsAction => {
	return {
		type: constants.CHANGE_SELECTED_TOOL,
		payload: newSelectedTool,
	};
};

const fetchingMassBalances = (): ToolsAction => {
	return { type: constants.FETCHING_MASS_BALANCES };
};

const massBalancesFetched = (massBalances: Array<MassBalance>): ToolsAction => {
	return { type: constants.MASS_BALANCES_FETCHED, payload: massBalances };
};

const massBalancesFetchFailed = (): ToolsAction => {
	return { type: constants.MASS_BALANCES_FETCH_FAILED };
};

export const fetchMassBalances = (
	baseUrl: string,
): AppThunk => async dispatch => {
	dispatch(fetchingMassBalances());
	try {
		const { data } = await axios.get(`${baseUrl}MassBalance`);
		dispatch(massBalancesFetched(data));
	} catch (error) {
		dispatch(massBalancesFetchFailed());
	}
};

const creatingMassBalance = (): ToolsAction => {
	return { type: constants.CREATING_MASS_BALANCE };
};

const massBalanceCreated = (id: number): ToolsAction => {
	return { type: constants.MASS_BALANCE_CREATED, payload: id };
};

const massBalanceCreateFailed = (): ToolsAction => {
	return { type: constants.MASS_BALANCE_CREATE_FAILED };
};

export const createMassBalance = (
	baseUrl: string,
): AppThunk => async dispatch => {
	dispatch(creatingMassBalance());
	try {
		const { data } = await axios.post(`${baseUrl}MassBalance`);
		dispatch(massBalanceCreated(data.id));
	} catch (error) {
		dispatch(massBalanceCreateFailed());
	}
};

export const newMassBalanceClicked = (): ToolsAction => {
	return { type: constants.NEW_MASS_BALANCE_CLICKED };
};

const searchMassBalances = debounce(dispatch => {
	(dispatch as AppDispatch)({ type: constants.SEARCH_MASS_BALANCES });
}, 500);

const massBalancesSearchValueChanged = (value: string): ToolsAction => {
	return {
		type: constants.MASS_BALANCES_SEARCH_VALUE_CHANGED,
		payload: value,
	};
};

export const massBalancesSearchValueUpdate = (
	value: string,
): AppThunk => async dispatch => {
	dispatch(massBalancesSearchValueChanged(value));
	searchMassBalances(dispatch);
};

export const massBalancesSearchCanceled = (): ToolsAction => {
	return { type: constants.MASS_BALANCES_SEARCH_CANCELED };
};

export const massBalancesSelectionChanged = (id: number): ToolsAction => {
	return { type: constants.MASS_BALANCES_SELECTION_CHANGED, payload: id };
};

export const closeAlert = (): ToolsAction => {
	return { type: constants.CLOSE_ALERT };
};

export const massBalanceCancelClick = (): ToolsAction => {
	return { type: constants.MASS_BALANCE_CANCEL_CLICKED };
};

export const addNewMassBalance = (): ToolsAction => {
	return { type: constants.ADD_NEW_MASS_BALANCE };
};

export const drawerNameBlurred = (newName: string): ToolsAction => {
	return {
		type: constants.DRAWER_NAME_BLURRED,
		payload: newName,
	};
};

export const drawerDescriptionBlurred = (newDescription: string) => {
	return {
		type: constants.DRAWER_DESCRIPTION_BLURRED,
		payload: newDescription,
	};
};
