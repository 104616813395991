import { ServiceLayer } from '../services/types';

export type MapboxLayerProps = Pick<
	ServiceLayer,
	'id' | 'source' | 'filter'
> & { selected?: boolean };

export type UniqueLayerProps = Pick<ServiceLayer, 'paint' | 'layout'>;

export default abstract class MapboxLayer {
	static SELECTED_COLOR = '#764abc';
	static UNSELECTED_COLOR = '#4A6067';
	protected _id: MapboxLayerProps['id'];
	protected _source: MapboxLayerProps['source'];
	protected _filter: MapboxLayerProps['filter'];
	protected _selected: MapboxLayerProps['selected'];
	protected _type: ServiceLayer['type'] = 'line';

	constructor({ id, source, filter, selected = false }: MapboxLayerProps) {
		this._id = id;
		this._source = source;
		this._filter = filter;
		this._selected = selected;
	}

	get id(): MapboxLayerProps['id'] {
		return this._id;
	}

	build(): ServiceLayer {
		const layerProps = this.layerProperties();
		return {
			id: this._id,
			...(this._filter && { filter: this._filter }),
			type: this._type,
			source: this._source,
			...layerProps,
		};
	}

	abstract layerProperties(): UniqueLayerProps;
}
