import React from 'react';
import {
	Workspace,
	WorkspaceItem,
	SidePanelOption,
	WorkspaceGridMode,
} from '../../../types/workspace';
import { Layout } from 'react-grid-layout';
import './workspaceGrid.css';
import WorkspaceLayoutItems from './WorkspaceLayoutItems';
import styled from 'styled-components';
import FocusToolBar from '../FocusMode/FocusToolBar';

export interface Props {
	workspace: Workspace;
	sidePanelOption: SidePanelOption;
	activeWorkspaceItem: WorkspaceItem;
	onLayoutChange: (layout: Layout[]) => void;
	onDeleteWidgetHandler: (key: number) => void;
	onUpdateDashletHandler: (wsItem: WorkspaceItem) => void;
	onInitDashletHandler: (wsItem: WorkspaceItem) => void;
	handelMenuChangeFromFocus: (mode: number) => void;
	handelSwitchToFocusMode: (wsItem: WorkspaceItem) => void;
}

const WorkspaceGridModeSwitch = ({
	workspace,
	sidePanelOption,
	activeWorkspaceItem,
	onLayoutChange,
	onDeleteWidgetHandler,
	onUpdateDashletHandler,
	onInitDashletHandler,
	handelMenuChangeFromFocus,
	handelSwitchToFocusMode,
}: Props) => {
	if (sidePanelOption.action === WorkspaceGridMode.ADD_TO_WORKSPACE) {
		return (
			<WorkspaceContainerWrapper>
				<WorkspaceLayoutItems
					workspace={workspace}
					onLayoutChange={onLayoutChange}
					onDeleteWidgetHandler={onDeleteWidgetHandler}
					onUpdateDashletHandler={onUpdateDashletHandler}
					onInitDashletHandler={onInitDashletHandler}
					handelSwitchToFocusMode={handelSwitchToFocusMode}
				/>
			</WorkspaceContainerWrapper>
		);
	}
	return (
		<FocusToolBar
			activeWorkspaceItem={activeWorkspaceItem}
			sidePanelOption={sidePanelOption}
			handelMenuChangeFromFocus={handelMenuChangeFromFocus}
		/>
	);
};

const WorkspaceGrid = ({
	workspace,
	sidePanelOption,
	activeWorkspaceItem,
	onLayoutChange,
	onDeleteWidgetHandler,
	onUpdateDashletHandler,
	onInitDashletHandler,
	handelMenuChangeFromFocus,
	handelSwitchToFocusMode,
}: Props) => {
	return (
		<WorkspaceGridModeSwitch
			workspace={workspace}
			sidePanelOption={sidePanelOption}
			activeWorkspaceItem={activeWorkspaceItem}
			onLayoutChange={onLayoutChange}
			onDeleteWidgetHandler={onDeleteWidgetHandler}
			onUpdateDashletHandler={onUpdateDashletHandler}
			onInitDashletHandler={onInitDashletHandler}
			handelSwitchToFocusMode={handelSwitchToFocusMode}
			handelMenuChangeFromFocus={handelMenuChangeFromFocus}
		/>
	);
};

export default WorkspaceGrid;

const WorkspaceContainerWrapper = styled.div`
	padding: 0;
	margin: 0;
	display: flex;
	flex-direction: column;
	float: left;
`;
