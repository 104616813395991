import { AlertInfo } from '../../Components/AlertSnackbar/AlertSnackbar';
import { SystemStatus } from '../../types/dashboard';
import { Action } from '../actions/dashboard';
import {
	CLOSE_ALERT,
	FETCH_RECENT_WORKSPACES_FAILED,
	FETCH_SYSTEM_LOGS_FAILED,
	RECENT_WORKSPACES_FETCHED,
	SYSTEM_LOGS_FETCHED,
	FETCH_LATEST_ALERTS_FAILED,
	LATEST_ALERTS_FETCHED,
} from '../constants/dashboard';
import { DashboardState } from '../types/dashboard';

//Default state
export const initialDashboardState: DashboardState = {
	loggedInUser: { userName: '', aliasName: '' },
	systemStatus: SystemStatus.Healthy,
	recentWorkspaces: [],
	latestAlerts: [],
	systemLogs: [],
};

//Reducer
const dashboardReducer = (
	state: DashboardState = initialDashboardState,
	action: Action,
): DashboardState => {
	const failedToLoadAlert: AlertInfo = {
		text: 'Unable to load data. Please reload this page.',
		severity: 'error',
	};
	switch (action.type) {
		case RECENT_WORKSPACES_FETCHED: {
			return {
				...state,
				recentWorkspaces: action.payload,
			};
		}
		case FETCH_RECENT_WORKSPACES_FAILED: {
			return {
				...state,
				alert: failedToLoadAlert,
			};
		}
		case LATEST_ALERTS_FETCHED: {
			return {
				...state,
				latestAlerts: action.payload,
			};
		}
		case FETCH_LATEST_ALERTS_FAILED: {
			return state;
		}
		case SYSTEM_LOGS_FETCHED: {
			return {
				...state,
				systemLogs: action.payload,
			};
		}
		case FETCH_SYSTEM_LOGS_FAILED: {
			return {
				...state,
				alert: failedToLoadAlert,
			};
		}
		case CLOSE_ALERT: {
			return {
				...state,
				alert: undefined,
			};
		}
		default:
			return state;
	}
};
export default dashboardReducer;
