import React, { PureComponent } from 'react';
import { ReactMap, SourceType } from '@innovyze/inno-map';
import { PropertyPane } from '@innovyze/ui';
import { SiteConfig } from '../../../SiteConfiguration/SiteConfig';

interface MapProps {
	config?: SiteConfig;
	token?: string;
}

interface MapState {
	init: boolean;
}
export class MapComp extends PureComponent<MapProps, MapState> {
	constructor(props: MapProps) {
		super(props);
		this.state = {
			init: false,
		};
	}

	componentDidMount() {
		this.setState({
			init: true,
		});
	}

	render() {
		const { config, token } = this.props;
		if (config != undefined && token != undefined) {
			return (
				<ReactMap
					mapKey={config.mapbox.key || ''}
					dataServices={[
						{
							type: SourceType.STYLE,
							id: 'network',
							displayName: 'Network',
							url:
								'/iws-local/mo/cnn/cnn.3/mapboxstyle/thm.0/https%3A%2F%2Flocalhost%3A30003%2Fiws-local%2Fmo%2Fcnn%2Fcnn.3%2Fmaptile',
						},
						{
							type: SourceType.GEOJSON,
							id: 'pipes',
							displayName: 'Pipes',
							url:
								'https://innovyzecloud.com/api/sites/227/pipes.json',
							layerGrouping: [
								{
									filterBy: 'asset_id',
									displayName: 'Pipes',
									type: 'line',
								},
							],
						},
						{
							type: SourceType.GEOJSON,
							id: 'customer-points',
							displayName: 'Customer Points',
							url:
								'https://innovyzecloud.com/api/sites/227/customer-points.json',
						},
						{
							type: SourceType.GEOJSON,
							id: 'valves',
							displayName: 'Valves',
							url:
								'https://innovyzecloud.com/api/sites/227/valves.json',
						},
						{
							type: SourceType.GEOJSON,
							id: 'non-return-valves',
							displayName: 'Non-return Valves',
							url:
								'https://innovyzecloud.com/api/sites/227/non-return-valves.json',
						},
						{
							type: SourceType.GEOJSON,
							id: 'float-valves',
							displayName: 'Float Valves',
							url:
								'https://innovyzecloud.com/api/sites/227/float-valves.json',
						},
						{
							type: SourceType.GEOJSON,
							id: 'transfer-nodes',
							displayName: 'Transfer Nodes',
							url:
								'https://innovyzecloud.com/api/sites/227/transfer-nodes.json',
						},
						{
							type: SourceType.HARVI,
							id: 'assets',
							displayName: 'Schematic',
							url:
								config.harvi.api +
								'restful/site/227/asset_geos/', // Hard-coded site for now.
							requestHeaders: [['emaginauth', `Bearer,${token}`]],
							layerGrouping: [
								{
									groupBy: 'asset_type_id',
									displayName: 'asset_type_name',
									type: 'symbol',
								},
								{
									filterBy: 'sensor_id',
									displayName: 'Sensors',
									type: 'symbol',
								},
							],
						},
					]}
					summaryPanel={<PropertyPane />}
				/>
			);
		} else {
			return (
				<div>
					<h3> Invalid Map Options </h3>
				</div>
			);
		}
	}
}
