import { DialogTitle, styled, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import ErrorIcon from '@material-ui/icons/Error';
import React from 'react';

interface Props {
	open: boolean;
	titleText: string;
	contentText: string;
	confirmButtonText?: string;
	cancelButtonText?: string;
	onConfirm: () => void;
	onCancel: () => void;
}
const WaringDialog = ({
	open,
	titleText,
	contentText,
	cancelButtonText,
	confirmButtonText,
	onCancel,
	onConfirm,
}: Props) => {
	const StyledDialogTitle = styled(DialogTitle)({
		color: 'rgba(230, 107, 69, 1)',
		display: 'flex',
		alignItems: 'center',
	});
	const StyledErrorIcon = styled(ErrorIcon)({
		marginRight: '0.5rem',
	});
	const StyledButton = styled(Button)({ color: 'rgba(230, 107, 69, 1)' });

	return (
		<Dialog open={open}>
			<StyledDialogTitle disableTypography>
				<StyledErrorIcon />
				<Typography variant="h6">{titleText}</Typography>
			</StyledDialogTitle>
			<DialogContent>
				<DialogContentText>{contentText}</DialogContentText>
			</DialogContent>
			<DialogActions>
				<StyledButton onClick={onCancel}>
					{cancelButtonText ? cancelButtonText : 'Cancel'}
				</StyledButton>
				<StyledButton onClick={onConfirm}>
					{confirmButtonText ? confirmButtonText : 'Confirm'}
				</StyledButton>
			</DialogActions>
		</Dialog>
	);
};

export default WaringDialog;
