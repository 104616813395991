/**
@namespace Workspaces_Actions
*/

import axios from 'axios';
import { History } from 'history';
import { AppDispatch, AppThunk } from '..';
import { debounce } from '../../services/utils';
import { Workspace } from '../../types/workspaces';
import * as constants from '../constants/workspaces';

/**
 * @memberof Workspaces_Actions
 * @function getWorkspaces - Action creator/thunk to retrieve all workspaces from API
 * @returns {undefined}
 */

// Action Types
export type Action =
	| WorkspaceCreating
	| WorkspaceCreated
	| CreateWorkspaceFailed
	| WorkspacesFetched
	| FetchWorkspacesFailed
	| SelectionChanged
	| NewWorkspaceClicked
	| SearchCanceled
	| SearchValueChanged
	| FilterWorkspace
	| DrawerCancelClicked
	| DrawerDescriptionBlurred
	| DrawerNameBlurred
	| CheckWorkspaceErrors
	| FetchingWorkspaces
	| DisplayWorkspaceDeletedInfo
	| CloseAlert;

// Action Definition
export interface WorkspaceCreating {
	type: constants.WORKSPACE_CREATING;
}

export interface WorkspaceCreated {
	type: constants.WORKSPACE_CREATED;
}

export interface CreateWorkspaceFailed {
	type: constants.CREATE_WORKSPACE_FAILED;
}

export interface WorkspacesFetched {
	type: constants.WORKSPACES_FETCHED;
	payload: Array<Workspace>;
}

export interface FetchWorkspacesFailed {
	type: constants.FETCH_WORKSPACES_FAILED;
}

export interface SelectionChanged {
	type: constants.SELECTION_CHANGED;
	payload: number;
}

export interface NewWorkspaceClicked {
	type: constants.NEW_WORKSPACE_CLICKED;
}

export interface SearchCanceled {
	type: constants.SEARCH_CANCELED;
}

export interface SearchValueChanged {
	type: constants.SEARCH_VALUE_CHANGED;
	payload: string;
}

export interface FilterWorkspace {
	type: constants.FILTER_WORKSPACES;
}

export interface DrawerCancelClicked {
	type: constants.DRAWER_CANCEL_CLICKED;
}

export interface DrawerDescriptionBlurred {
	type: constants.DRAWER_DESCRIPTION_BLURRED;
	payload: string;
}

export interface DrawerNameBlurred {
	type: constants.DRAWER_NAME_BLURRED;
	payload: string;
}

export interface CheckWorkspaceErrors {
	type: constants.CHECK_WORKING_WORKSPACE_ERRORS;
}

export interface FetchingWorkspaces {
	type: constants.FETCHING_WORKSPACES;
}

export interface DisplayWorkspaceDeletedInfo {
	type: constants.DISPLAY_WORKSPACE_DELETED_INFO;
}

export interface CloseAlert {
	type: constants.CLOSE_ALERT;
}
// Action Creators
export const fetchWorkspaces = (
	baseUrl: string,
): AppThunk => async dispatch => {
	dispatch({ type: constants.FETCHING_WORKSPACES });
	try {
		const response = await axios.get(`${baseUrl}Workspace`);
		dispatch({
			type: constants.WORKSPACES_FETCHED,
			payload: response.data,
		});
	} catch (error) {
		dispatch({ type: constants.FETCH_WORKSPACES_FAILED });
	}
};

export const selectionChanged = (id: number): Action => {
	return {
		type: constants.SELECTION_CHANGED,
		payload: id,
	};
};

export const newWorkspaceClicked = (): Action => {
	return { type: constants.NEW_WORKSPACE_CLICKED };
};

export const searchCanceled = (): Action => {
	return { type: constants.SEARCH_CANCELED };
};

const debouncedFilterWorkspaces = debounce(dispatch => {
	(dispatch as AppDispatch)({ type: constants.FILTER_WORKSPACES });
}, 500);

export const searchValueChanged = (
	query: string,
): AppThunk => async dispatch => {
	dispatch({ type: constants.SEARCH_VALUE_CHANGED, payload: query });
	debouncedFilterWorkspaces(dispatch);
};

export const drawerCancelClicked = (): Action => {
	return { type: constants.DRAWER_CANCEL_CLICKED };
};

export const drawerDescriptionBlurred = (newDescription: string): Action => {
	return {
		type: constants.DRAWER_DESCRIPTION_BLURRED,
		payload: newDescription,
	};
};

export const drawerNameBlurred = (newName: string): Action => {
	return {
		type: constants.DRAWER_NAME_BLURRED,
		payload: newName,
	};
};

export const drawerOkClicked = (
	workspace: Workspace,
	history: History,
	baseUrl: string,
): AppThunk => async (dispatch, getState) => {
	dispatch({ type: constants.CHECK_WORKING_WORKSPACE_ERRORS });
	if (getState().workspaces.workingWorkspaceErrors.length === 0) {
		const { name, description } = workspace;
		try {
			dispatch({ type: constants.WORKSPACE_CREATING });
			const newWorkspace = {
				name,
				description,
				ownerId: '1',
				permission: 0,
				options: '',
				status: '',
				createBy: 'User',
				modifiedBy: 'User',
			};
			const response = await axios.post(
				`${baseUrl}Workspace`,
				newWorkspace,
			);
			dispatch({ type: constants.WORKSPACE_CREATED });
			history.push(`/workspace/${response.data.id}`);
		} catch (error) {
			dispatch({ type: constants.CREATE_WORKSPACE_FAILED });
		}
	}
};

export const displayWorkspaceDeletedInfo = (): Action => {
	return { type: constants.DISPLAY_WORKSPACE_DELETED_INFO };
};

export const closeAlert = (): Action => {
	return { type: constants.CLOSE_ALERT };
};
