import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import React from 'react';
import styled from 'styled-components';
import { Workspace, WorkspaceError } from '../../types/workspace';

const useStyles = makeStyles({
	drawer: {
		width: 552,
	},
	drawerPaper: {
		width: 552,
		paddingTop: '3.125rem',
	},
});

interface Props {
	onButtonCancel: () => void;
	onButtonOk: () => void;
	onDescriptionBlur: (e: React.FocusEvent<HTMLInputElement>) => void;
	onNameBlur: (e: React.FocusEvent<HTMLInputElement>) => void;
	buttonOkDisabled: boolean;
	open: boolean;
	workspace: Workspace;
	workspaceErrors: Array<WorkspaceError>;
}

const WorkspacePropertiesDrawer = ({
	onButtonCancel,
	onButtonOk,
	onDescriptionBlur,
	onNameBlur,
	buttonOkDisabled,
	open,
	workspace,
	workspaceErrors,
}: Props) => {
	const classes = useStyles();
	const { id, name, description } = workspace;
	const title = id === 0 ? 'New Workspace' : 'Workspace Settings';
	const foundNameError = workspaceErrors.find(
		err => err.fieldName === 'name',
	);
	const nameErrorText = foundNameError ? foundNameError.errorMessage : '';

	return (
		<Drawer
			anchor="right"
			className={classes.drawer}
			open={open}
			classes={{ paper: classes.drawerPaper }}>
			<Title>{title}</Title>
			<Divider />
			<Content>
				<TextField
					defaultValue={name}
					error={Boolean(foundNameError)}
					helperText={nameErrorText}
					label="Workspace name"
					onBlur={onNameBlur}
					required
					variant="outlined"
				/>
				<TextField
					defaultValue={description}
					label="Description"
					onBlur={onDescriptionBlur}
					variant="outlined"
				/>
			</Content>
			<Divider />
			<DrawerActions>
				<Button variant="contained" onClick={onButtonCancel}>
					CANCEL
				</Button>
				<Button
					variant="contained"
					color="primary"
					onClick={onButtonOk}
					disabled={buttonOkDisabled}>
					OK
				</Button>
			</DrawerActions>
		</Drawer>
	);
};

const Title = styled.div`
	padding: 24px;
	font-size: 18px;
	color: #00abd1;
`;

const Content = styled.div`
	padding: 24px;
	display: flex;
	flex-direction: column;
	flex: 1;
	& > div {
		width: 100%;
		margin-bottom: 24px;
	}
`;

const DrawerActions = styled.div`
	display: flex;
	justify-content: flex-end;
	padding: 24px;
	& > button {
		margin-left: 16px;
	}
`;

//Default Props
WorkspacePropertiesDrawer.defaultProps = {
	open: false,
	workspace: {
		id: 0,
		name: '',
		description: '',
	},
	workspaceErrors: [],
};

export default WorkspacePropertiesDrawer;
