import MapboxLayer, { UniqueLayerProps } from './MapboxLayer';

export default class LineLayer extends MapboxLayer {
	layerProperties(): UniqueLayerProps {
		return {
			layout: {
				'line-join': 'round',
				'line-cap': 'round',
			},
			paint: {
				'line-color': this._selected
					? MapboxLayer.SELECTED_COLOR
					: MapboxLayer.UNSELECTED_COLOR,
				'line-width': 3,
			},
		};
	}
}
