import { Button } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import { History } from 'history';
import clsx from 'clsx';
import SearchBar from 'material-ui-search-bar';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import AlertSnackbar from '../../Components/AlertSnackbar/AlertSnackbar';
import { useSiteConfig } from '../../Components/SiteConfiguration/SiteConfigProvider';
import ILIIcon from '../../Components/Tools/ILI.svg';
import MassBalanceIcon from '../../Components/Tools/mass-balance.svg';
import MassBalanceList from '../../Components/Tools/MassBalanceList';
import { AppDispatch } from '../../store';
import {
	changeSelectedTool,
	closeAlert,
	fetchMassBalances,
	massBalancesSearchCanceled,
	massBalancesSearchValueUpdate,
	newMassBalanceClicked,
	massBalanceCancelClick,
	addNewMassBalance,
	drawerNameBlurred,
	drawerDescriptionBlurred,
} from '../../store/actions/tools';
import { RootState } from '../../store/types/root';
import { MassBalance, SelectedTool } from '../../types/tools';
import AddMassBalanceDrawer from '../../Components/Tools/AddMassBalanceDrawer';

const useStyles = makeStyles({
	card: {
		paddingTop: '0.25rem',
		height: 'fit-content',
		width: '20rem',
		marginRight: '1.5rem',
		'& *': { opacity: '65%' },
		'&:hover': {
			cursor: 'pointer',
		},
	},
	selectedCard: {
		paddingTop: 0,
		height: '11.5rem',
		borderTop: '0.3125rem solid rgba(0, 171, 209, 1)',
		borderBottom: 0,
		borderBottomRightRadius: 0,
		borderBottomLeftRadius: 0,
		'& *': { opacity: '100%' },
		'&:hover': {
			cursor: 'default',
		},
		zIndex: 0,
	},
	cardContent: {
		paddingBottom: '1.5rem',
		paddingTop: '1.5rem',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		fontSize: '1.5rem',
		fontWeight: 600,
		color: 'rgba(74, 96, 103, 1)',
		'& img': {
			marginBottom: '1rem',
		},
	},
});

export interface Props {
	history: History;
}

const ToolsPage = (props: Props) => {
	const classes = useStyles();
	const { config } = useSiteConfig();
	const dispatch = useDispatch<AppDispatch>();
	const { alert, massBalanceTab, selectedTool, iLITab, errors } = useSelector(
		(state: RootState) => state.tools,
	);

	useEffect(() => {
		dispatch(fetchMassBalances(config.remoteUrl.baseUrl));
	}, [fetchMassBalances]);

	const handleToolCardClick = (newSelectedTool: SelectedTool) => {
		dispatch(changeSelectedTool(newSelectedTool));
	};

	const handleMassBalanceSelectionChange = (e: {
		selectedRowsData?: MassBalance[];
	}): void => {
		if (e.selectedRowsData && e.selectedRowsData[0]) {
			const id = e.selectedRowsData[0].id;
			props.history.push(`/massbalance/${id}`);
		}
	};

	const handleAddMBDrawerCancel = () => {
		dispatch(massBalanceCancelClick());
	};

	const handleAddMBDrawerOk = () => {
		dispatch(addNewMassBalance());
	};

	const handelMBNameBlur = (e: React.FocusEvent<HTMLInputElement>) => {
		const name = e.target.value;
		dispatch(drawerNameBlurred(name));
	};

	const handelMBDescriptionBlur = (e: React.FocusEvent<HTMLInputElement>) => {
		const description = e.target.value;
		dispatch(drawerDescriptionBlurred(description));
	};

	return (
		<PageContainer>
			<Title>Tools</Title>
			<TabContainer>
				<Card
					variant="outlined"
					onClick={() =>
						handleToolCardClick(SelectedTool.MassBalance)
					}
					className={clsx(classes.card, {
						[classes.selectedCard]:
							selectedTool === SelectedTool.MassBalance,
					})}>
					<CardContent className={classes.cardContent}>
						<img src={MassBalanceIcon} alt="Mass Balance Icon" />
						Mass Balance
					</CardContent>
				</Card>
				<Card
					variant="outlined"
					onClick={() => handleToolCardClick(SelectedTool.ILI)}
					className={clsx(classes.card, {
						[classes.selectedCard]:
							selectedTool === SelectedTool.ILI,
					})}>
					<CardContent className={classes.cardContent}>
						<img src={ILIIcon} alt="ILI Icon" />
						ILI
					</CardContent>
				</Card>
			</TabContainer>
			{selectedTool === SelectedTool.MassBalance && (
				<>
					<TabContentContainer>
						<ActionsContainer>
							<Button
								id="new-mass-balance"
								color="primary"
								variant="contained"
								startIcon={<AddIcon />}
								onClick={() =>
									dispatch(newMassBalanceClicked())
								}>
								NEW MASS BALANCE
							</Button>
							<StyledSearchBar
								onCancelSearch={() =>
									dispatch(massBalancesSearchCanceled())
								}
								onChange={(value: string): void =>
									dispatch(
										massBalancesSearchValueUpdate(value),
									)
								}
								placeholder={'Search Mass Balance'}
								value={massBalanceTab.searchValue}
							/>
						</ActionsContainer>
						<MassBalanceList
							dataSource={massBalanceTab.massBalancesInDataGrid}
							onSelectionChanged={
								handleMassBalanceSelectionChange
							}
						/>
					</TabContentContainer>
					<AddMassBalanceDrawer
						open={massBalanceTab.creatingNew}
						onButtonCancel={handleAddMBDrawerCancel}
						onButtonOk={handleAddMBDrawerOk}
						onDescriptionBlur={handelMBDescriptionBlur}
						onNameBlur={handelMBNameBlur}
						massBalanceError={errors}
					/>
				</>
			)}
			{selectedTool === SelectedTool.ILI && (
				<TabContentContainer>
					<ActionsContainer>
						<Button
							id="new-ILI"
							color="primary"
							variant="contained"
							startIcon={<AddIcon />}>
							NEW ILI
						</Button>
						<StyledSearchBar
							placeholder={'Search ILI'}
							value={iLITab.searchValue}
						/>
					</ActionsContainer>
				</TabContentContainer>
			)}
			<AlertSnackbar
				alert={alert}
				onClose={() => dispatch(closeAlert())}
			/>
		</PageContainer>
	);
};

//Styles
const PageContainer = styled.div`
	display: flex;
	flex-direction: column;
	background: #f9fafc;
	height: 100%;
`;
const Title = styled.div`
	font-size: 1.5rem;
	font-weight: 600;
	padding: 2rem 1.25rem 2.5rem 1.25rem;
`;
const TabContainer = styled.div`
	display: flex;
	padding: 0 1.25rem;
`;
const TabContentContainer = styled.div`
	display: flex;
	flex-direction: column;
	background-color: rgba(255, 255, 255, 1);
	border-top: 1px solid rgba(0, 0, 0, 0.12);
	padding: 2rem 1.25rem;
	margin-top: -1px;
	flex-grow: 1;
`;
const ActionsContainer = styled.div`
	display: flex;
	margin-bottom: 1.5rem;
	align-items: center;
	justify-content: space-between;
`;
const StyledSearchBar = styled(SearchBar)`
	box-shadow: none !important;
	width: 26.25rem;
	border: 1px solid rgba(165, 189, 198, 1);
`;

export default ToolsPage;
