import { Evented } from 'mapbox-gl';

import {
	DataType,
	DataServiceType,
	SourceType,
	LayerGrouping,
	ServiceLayer,
	GeoJsonDataType,
} from './types';
import MapboxDataSource from './MapboxDataSource';

class DataServiceRequestError extends Error {
	constructor(message?: string, public response?: Response) {
		super(message);
		Object.setPrototypeOf(this, new.target.prototype);
	}
}

export default class DataService extends Evented {
	static ERROR_MESSAGE = 'Failed to load data for {0}';
	private _endpoint: string | null = null;
	private _data: DataType | null = null;
	private _valid = false;
	private _type: SourceType | null = null;
	private _id = '';
	private _displayName = '';
	private _requestPending = false;
	private _requestHeaders: string[][] | undefined;
	protected _layerGrouping: LayerGrouping[] | undefined;
	protected _dataSources: MapboxDataSource[] | undefined;

	constructor({
		url,
		data,
		type,
		id,
		displayName,
		requestHeaders,
		layerGrouping,
	}: DataServiceType) {
		super();
		this._type = type;
		this._id = id;
		this._displayName = displayName;
		this._requestHeaders = requestHeaders;
		this._layerGrouping = layerGrouping;

		if (url) {
			this._valid = this.validUrl(url);
			this._endpoint = this._valid ? url : null;
		} else if (data) {
			this._valid = this.validData(data);
			this.data = this._valid ? data : null;
		}
	}

	get errorDescription(): string {
		return DataService.ERROR_MESSAGE.replace('{0}', this._displayName);
	}

	async loadData(force = false): Promise<void> {
		if (this._requestPending) return;
		if ((!this._data || force) && this._endpoint) {
			this._requestPending = true;
			try {
				const response = await fetch(
					this._endpoint,
					this._requestHeaders
						? { headers: this._requestHeaders }
						: {},
				);

				if (!response.ok) {
					throw new DataServiceRequestError(
						'The API call did not succeeed.',
						response,
					);
				}

				const data = this.parseResponse(await response.json());
				if (data === null) {
					throw new DataServiceRequestError(
						'The API call did not return any data',
					);
				}

				if (!this.validData(data)) {
					throw new DataServiceRequestError(
						'The API call did not return valid data.',
					);
				}

				this.data = data;
				this.fire('service-loaded');
			} catch (dataServiceRequestError) {
				this.fire('service-error', {
					error: this.errorDescription,
					status:
						dataServiceRequestError?.response?.status ??
						'http status unknown',
					statusText:
						dataServiceRequestError?.response?.statusText ??
						dataServiceRequestError.message,
				});
			} finally {
				this._requestPending = false;
			}
		}
	}

	set data(data) {
		if (this.type !== SourceType.STYLE) {
			this._data = this.remapProperties(data) as GeoJsonDataType;
			this.createDataSources(this._data);
		} else {
			this._data = data;
		}
	}

	get data(): DataType | null {
		return this._data;
	}

	get dataSources(): MapboxDataSource[] | undefined {
		return this._dataSources;
	}

	get layers(): ServiceLayer[] | undefined {
		return [] as ServiceLayer[];
	}

	isValid(): boolean {
		return this._valid;
	}

	get type(): SourceType | null {
		return this._type;
	}

	get id(): string {
		return this._id;
	}

	get displayName(): string {
		return this._displayName;
	}

	get loaded(): boolean {
		return !this._requestPending;
	}

	validUrl(url: string): boolean {
		try {
			new URL(url, window.location.href);
			return true;
		} catch (_) {
			return false;
		}
	}

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	validData(_: DataType): boolean {
		return false;
	}

	remapProperties(data: DataType | null): DataType | null {
		return data;
	}

	parseResponse(data: unknown): DataType | null {
		return data as DataType;
	}

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	createDataSources(_: GeoJsonDataType): void {
		this._dataSources = [];
	}
}
