import { Background } from './BackgroundControl';
import { BackgroundTypes } from './BackgroundTypes';

export class BackgroundRegistry {
	private static defaultStyle = BackgroundTypes.Streets;
	private static defaultBackgrounds: {
		[index: string]: Background;
	} = {
		streets: {
			title: 'Streets',
			key: BackgroundTypes.Streets,
			description: 'Best way to get started',
			uri: 'mapbox://styles/mapbox/streets-v11',
		},
		satellite: {
			title: 'Satellite',
			key: BackgroundTypes.Satellite,
			description:
				'Global imagery enhanced with road and label hierarchy',
			uri: 'mapbox://styles/mapbox/satellite-streets-v11',
		},
		outdoors: {
			title: 'Streets with contoured terrain',
			key: BackgroundTypes.Outdoors,
			description:
				'Large-scale detail and quantitative representation of relief',
			uri: 'mapbox://styles/mapbox/outdoors-v11',
		},
		dark: {
			title: 'High contrast',
			key: BackgroundTypes.Dark,
			description: 'Dark and high contrast road display',
			uri: 'mapbox://styles/mapbox/dark-v10',
		},
	};

	public static get DefaultStyle(): Background {
		return BackgroundRegistry.defaultBackgrounds[
			BackgroundRegistry.defaultStyle
		];
	}

	public static getStyleWithFallback(styleKey: string): Background {
		const background = BackgroundRegistry.defaultBackgrounds[styleKey];

		if (background) {
			return background;
		}

		console.warn(`The specified background '${styleKey}' does not exist.`);

		return BackgroundRegistry.DefaultStyle;
	}

	public static getAllStyles(): Background[] {
		return Object.values(BackgroundRegistry.defaultBackgrounds);
	}
}
