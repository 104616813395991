import axios from 'axios';
import { AppThunk } from '..';
import { RecentWorkspace, SystemLog } from '../../types/dashboard';
import * as constants from '../constants/dashboard';
import { Alert } from '../../types/alert';

// Action Types
export type Action =
	| RecentWorkspacesFetched
	| FetchRecentWorkspacesFailed
	| LatestAlertsFetched
	| FetchLatestAlertsFailed
	| SystemLogsFetched
	| FetchSystemLogsFailed
	| CloseAlert;

// Action Definition
export interface RecentWorkspacesFetched {
	type: constants.RECENT_WORKSPACES_FETCHED;
	payload: Array<RecentWorkspace>;
}

export interface FetchRecentWorkspacesFailed {
	type: constants.FETCH_RECENT_WORKSPACES_FAILED;
}
export interface SystemLogsFetched {
	type: constants.SYSTEM_LOGS_FETCHED;
	payload: Array<SystemLog>;
}

export interface LatestAlertsFetched {
	type: constants.LATEST_ALERTS_FETCHED;
	payload: Array<Alert>;
}

export interface FetchLatestAlertsFailed {
	type: constants.FETCH_LATEST_ALERTS_FAILED;
}

export interface FetchSystemLogsFailed {
	type: constants.FETCH_SYSTEM_LOGS_FAILED;
}

export interface CloseAlert {
	type: constants.CLOSE_ALERT;
}

// Action Creators
const recentWorkspacesFetched = (
	workspaces: Array<RecentWorkspace>,
): Action => {
	return {
		type: constants.RECENT_WORKSPACES_FETCHED,
		payload: workspaces,
	};
};
const fetchRecentWorkspacesFailed = (): Action => {
	return { type: constants.FETCH_RECENT_WORKSPACES_FAILED };
};
const systemLogsFetched = (systemLogs: Array<SystemLog>): SystemLogsFetched => {
	return {
		type: constants.SYSTEM_LOGS_FETCHED,
		payload: systemLogs,
	};
};

// This is not used because there's no end point for systemLog yet
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const fetchSystemLogsFailed = (): Action => {
	return { type: constants.FETCH_SYSTEM_LOGS_FAILED };
};

export const fetchRecentWorkspaces = (
	baseUrl: string,
): AppThunk => async dispatch => {
	try {
		const response = await axios.get(`${baseUrl}WorkspaceVisit`, {
			params: { count: 4 },
		});
		dispatch(recentWorkspacesFetched(response.data));
	} catch (error) {
		dispatch(fetchRecentWorkspacesFailed());
	}
};

export const fetchLatestAlerts = (
	baseUrl: string,
): AppThunk => async dispatch => {
	try {
		const response = await axios.get(`${baseUrl}Alarm/site/7`, {
			params: { count: 4 },
		});
		dispatch({
			type: constants.LATEST_ALERTS_FETCHED,
			payload: response.data,
		});
	} catch (error) {
		dispatch({ type: constants.FETCH_LATEST_ALERTS_FAILED });
	}
};
export const fetchSystemLogs = (
	// This is not used because there's no end point for systemLog yet
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	baseUrl: string,
): AppThunk => async dispatch => {
	// try {
	// const response = await axios.get(`${baseUrl}SystemLog`, {
	// 	params: { count: 4 },
	// });
	const response: { data: Array<SystemLog> } = {
		data: [
			{
				id: 1,
				lastVisited: '2020-05-17T09:09:49.537225+00:00',
				systemLog: {
					id: 1,
					name: 'systemLog 1',
					status: 'Running',
				},
			},
			{
				id: 2,
				lastVisited: '2020-04-28T21:09:49.537225+00:00',
				systemLog: {
					id: 5,
					name: 'systemLog 5',
					status: 'Error',
				},
			},
			{
				id: 3,
				lastVisited: '2020-04-25T01:07:49.537225+00:00',
				systemLog: {
					id: 2,
					name: 'systemLog 2',
					status: 'Success',
				},
			},
			{
				id: 4,
				lastVisited: '2020-04-17T21:09:49.537225+00:00',
				systemLog: {
					id: 6,
					name: 'systemLog 6',
					status: 'Running',
				},
			},
		],
	};
	dispatch(systemLogsFetched(response.data));
	// } catch (error) {
	// 	dispatch(fetchSystemLogsFailed());
	// }
};

export const closeAlert = (): Action => {
	return { type: constants.CLOSE_ALERT };
};
