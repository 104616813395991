"use strict";
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const styled_components_1 = __importStar(require("styled-components"));
const styles_1 = require("../utils/styles");
const StyledPropertyPane = styled_components_1.default.div `
	${styles_1.borderBox};
	background: #fff;
	position: absolute;
	top: 0;
	left: 0;
	width: ${styles_1.rem(470)};
	height: 100%;
	overflow-y: auto;
	transform: translate(-100%, 0);
	transition: transform 0.5s;
	box-shadow: 0 ${styles_1.rem(4)} ${styles_1.rem(4)} rgba(0, 0, 0, 0.25);
	border-radius: ${styles_1.rem(4)} 0 0 ${styles_1.rem(4)};
	padding: ${styles_1.rem(24)};
	${(props) => props.open &&
    styled_components_1.css `
			transform: translate(0%, 0);
		`};
`;
exports.default = StyledPropertyPane;
exports.SectionHeading = styled_components_1.default.div `
	font-size: ${styles_1.em(12)};
	line-height: ${styles_1.em(16, 12)};
	color: #007ca0;
	display: flex;
	justify-content: space-between;
	margin-bottom: ${styles_1.rem(10)};
	cursor: pointer;
	svg {
		color: #43575d;
	}
`;
exports.SectionContent = styled_components_1.default.div `
	display: none;
	${(props) => props.open &&
    styled_components_1.css `
			display: block;
		`}
`;
exports.Prop = styled_components_1.default.div `
	margin: ${styles_1.rem(12)} 0;
`;
const propHeading = styled_components_1.css `
	font-size: ${styles_1.em(12)};
	line-height: ${styles_1.em(16, 12)};
	color: #768d95;
`;
exports.PropTitle = styled_components_1.default.div `
	${propHeading}
`;
exports.StyledPropertySection = styled_components_1.default.div `
	margin: ${styles_1.rem(24)} 0;
	padding-top: ${styles_1.rem(16)};
`;
exports.Tabular = styled_components_1.default.table `
	width: 100%;
	margin-top: ${styles_1.rem(12)};
	border-spacing: 0;
	th {
		${propHeading};
		font-weight: normal;
	}
	th,
	td {
		text-align: left;
		padding-right: ${styles_1.rem(14)};
		vertical-align: top;
		&:last-child {
			text-align: right;
			padding-right: 0;
		}
	}
	td {
		padding-bottom: ${styles_1.rem(16)};
	}
`;
exports.MinimalItem = styled_components_1.default.button `
	background: none;
	display: block;
	border: none;
	width: 100%;
	text-align: left;
	border-bottom: ${styles_1.rem(1)} solid #e7e7ed;
	padding: ${styles_1.rem(18)} 0;
	font: inherit;
	cursor: pointer;
`;
exports.ViewAll = styled_components_1.default.button `
	background: none;
	border: none;
	font: inherit;
	font-size: ${styles_1.em(12)};
	margin-bottom: ${styles_1.rem(15)};
	cursor: pointer;
`;
exports.Group = styled_components_1.default.div `
	border-top: ${styles_1.rem(1)} solid #e7e7ed;
`;
