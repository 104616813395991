import SymbolLayer from './SymbolLayer';
import LineLayer from './LineLayer';
import ClusterLayer from './ClusterLayer';
import ClusterCountLayer from './ClusterCountLayer';
import { MapboxLayerProps } from './MapboxLayer';
import { ServiceLayer } from '../services/types';

export type CompositeLayerProps = MapboxLayerProps &
	Required<
		Pick<
			ServiceLayer,
			'displayName' | 'type' | 'icon' | 'cluster' | 'color'
		>
	>;

export type MapboxLayerType =
	| SymbolLayer
	| LineLayer
	| ClusterLayer
	| ClusterCountLayer;

export default class CompositeLayer {
	private _map: mapboxgl.Map;
	private _layer: MapboxLayerType;
	private _selectedLayer: MapboxLayerType;
	private _clusterLayer: MapboxLayerType | undefined = undefined;
	private _clusterCountLayer: MapboxLayerType | undefined = undefined;
	private _composite: MapboxLayerType[];
	private _hasClustering: boolean;
	private _displayName: CompositeLayerProps['displayName'];
	private _icon: CompositeLayerProps['icon'];
	private _active = true;
	private _selectedItems: string[] = [];

	constructor(layerInfo: CompositeLayerProps, map: mapboxgl.Map) {
		this._map = map;
		this._hasClustering = layerInfo.cluster;
		this._displayName = layerInfo.displayName;
		this._icon = layerInfo.icon;

		this._layer = this._createMainLayer(layerInfo);
		this._selectedLayer = this._createSelectedLayer(layerInfo);
		this._createClusterLayers(layerInfo);

		this._composite = [
			this._layer,
			this._selectedLayer,
			...(this._hasClustering ? [this._clusterLayer] : []),
			...(this._hasClustering ? [this._clusterCountLayer] : []),
		] as MapboxLayerType[];

		this.draw();
	}

	get id(): CompositeLayerProps['id'] {
		return this._layer.id;
	}

	get selectedId(): CompositeLayerProps['id'] {
		return this._selectedLayer.id;
	}

	get clusterId(): CompositeLayerProps['id'] | undefined {
		return this._clusterLayer?.id;
	}

	get displayName(): CompositeLayerProps['displayName'] {
		return this._displayName;
	}

	get icon(): CompositeLayerProps['icon'] {
		return this._icon;
	}

	get active(): boolean {
		return this._active;
	}

	get selectedItems(): string[] {
		return this._selectedItems;
	}

	set selectedItems(items: string[]) {
		this._selectedItems = items;
		this._setHighlightedItemsOnMap();
	}

	clearSelectedItems(): void {
		this.selectedItems = [];
	}

	toggleLayerVisibility(): void {
		this._active = !this._active;
		this.refreshLayerVisibility();
	}

	draw(): void {
		this._composite.map(layer => {
			this._drawLayer(layer);
		});
		this.refreshLayerVisibility();
		this._setHighlightedItemsOnMap();
	}

	refreshLayerVisibility(): void {
		if (this._active) {
			this.show();
		} else {
			this.hide();
		}
	}

	hide(): void {
		this._composite.map(layer => this._hideLayerOnMap(layer.id));
	}

	show(): void {
		this._composite.map(layer => this._showLayerOnMap(layer.id));
	}

	private _createMainLayer(
		layerInfo: CompositeLayerProps,
	): SymbolLayer | LineLayer {
		const layerProps = {
			...layerInfo,
			filter: this._getMainLayerFilter(),
		};
		if (layerInfo.type === 'symbol') {
			return new SymbolLayer(layerProps);
		} else {
			return new LineLayer(layerProps);
		}
	}

	private _createSelectedLayer(
		layerInfo: CompositeLayerProps,
	): SymbolLayer | LineLayer {
		const selectedLayerProps = {
			...layerInfo,
			filter: this._getSelectedLayerFilter(),
			id: `${layerInfo.id}-selected`,
			selected: true,
		};
		if (layerInfo.type === 'symbol') {
			return new SymbolLayer(selectedLayerProps);
		} else {
			return new LineLayer(selectedLayerProps);
		}
	}

	private _createClusterLayers(layerInfo: CompositeLayerProps): void {
		if (this._hasClustering) {
			const clusterFilter = this._getClusterLayerFilter();
			this._clusterLayer = new ClusterLayer({
				...layerInfo,
				filter: clusterFilter,
				id: `${layerInfo.id}-cluster`,
			});
			this._clusterCountLayer = new ClusterCountLayer({
				...layerInfo,
				filter: clusterFilter,
				id: `${layerInfo.id}-cluster-count`,
			});
		}
	}

	private _layerOnMap(layerId: CompositeLayerProps['id']): boolean {
		return !!this._map?.getLayer(layerId);
	}

	private _drawLayer(layer: MapboxLayerType): void {
		const mapLayer = layer.build();
		if (!this._layerOnMap(layer.id)) {
			this._map?.addLayer(mapLayer);
		}
	}

	private _hideLayerOnMap(layerId: CompositeLayerProps['id']): void {
		if (this._layerOnMap(layerId)) {
			this._map?.setLayoutProperty(layerId, 'visibility', 'none');
		}
	}

	private _showLayerOnMap(layerId: CompositeLayerProps['id']): void {
		if (this._layerOnMap(layerId)) {
			this._map?.setLayoutProperty(layerId, 'visibility', 'visible');
		}
	}

	private _setHighlightedItemsOnMap(): void {
		if (!this._selectedLayer || !this._layerOnMap(this._selectedLayer.id))
			return;
		this._map?.setFilter(
			this._selectedLayer.id,
			this._getSelectedLayerFilter(),
		);
	}

	private _getMainLayerFilter(): CompositeLayerProps['filter'] {
		return this._hasClustering ? ['!', ['has', 'point_count']] : undefined;
	}

	private _getSelectedLayerFilter(): CompositeLayerProps['filter'] {
		return [
			'in',
			'id',
			...(this.selectedItems.length ? this.selectedItems : ['']),
		];
	}

	private _getClusterLayerFilter(): CompositeLayerProps['filter'] {
		return ['has', 'point_count'];
	}
}
