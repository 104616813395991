import { ToolsAction } from '../actions/tools';
import {
	CHANGE_SELECTED_TOOL,
	CREATING_MASS_BALANCE,
	FETCHING_MASS_BALANCES,
	MASS_BALANCES_FETCHED,
	MASS_BALANCES_FETCH_FAILED,
	MASS_BALANCES_SEARCH_CANCELED,
	MASS_BALANCES_SEARCH_VALUE_CHANGED,
	MASS_BALANCES_SELECTION_CHANGED,
	MASS_BALANCE_CREATED,
	MASS_BALANCE_CREATE_FAILED,
	NEW_MASS_BALANCE_CLICKED,
	SEARCH_MASS_BALANCES,
	MASS_BALANCE_CANCEL_CLICKED,
	ADD_NEW_MASS_BALANCE,
	DRAWER_DESCRIPTION_BLURRED,
	DRAWER_NAME_BLURRED,
} from '../constants/tools';
import { ToolsState } from '../types/tools';
import { CLOSE_ALERT } from '../constants/tools';
import { SelectedTool } from '../../types/tools';
import { checkMassBalanceErrors } from '../utils/tools';

export const initialToolsState: ToolsState = {
	alert: undefined,
	selectedTool: SelectedTool.MassBalance,
	massBalanceTab: {
		creatingNew: false,
		searchValue: '',
		massBalances: [],
		massBalancesInDataGrid: [],
		workingMassBalance: null,
	},
	iLITab: {
		creatingNew: false,
		searchValue: '',
		iLIs: [],
		iLIsInDataGrid: [],
		workingILI: null,
	},
	addDrawerMassBalance: {
		id: 0,
		name: '',
		description: '',
		ownerId: 1,
	},
	errors: [],
};

//Reducer
const toolsReducer = (
	state: ToolsState = initialToolsState,
	action: ToolsAction,
): ToolsState => {
	switch (action.type) {
		case MASS_BALANCE_CANCEL_CLICKED: {
			return {
				...state,
				massBalanceTab: {
					...state.massBalanceTab,
					creatingNew: false,
				},
			};
		}
		case ADD_NEW_MASS_BALANCE: {
			return {
				...state,
				massBalanceTab: {
					...state.massBalanceTab,
					creatingNew: false,
				},
			};
		}
		case CHANGE_SELECTED_TOOL: {
			return {
				...state,
				selectedTool: action.payload,
			};
		}
		case FETCHING_MASS_BALANCES: {
			return {
				...state,
				alert: undefined,
				massBalanceTab: {
					...initialToolsState.massBalanceTab,
				},
			};
		}
		case MASS_BALANCES_FETCHED: {
			return {
				...state,
				alert: undefined,
				massBalanceTab: {
					...state.massBalanceTab,
					massBalances: action.payload,
					massBalancesInDataGrid: action.payload,
				},
			};
		}
		case MASS_BALANCES_FETCH_FAILED: {
			return {
				...state,
				alert: {
					text: 'Unable to load data. Please reload this page.',
					severity: 'error',
				},
			};
		}
		case CREATING_MASS_BALANCE: {
			return {
				...state,
				alert: undefined,
			};
		}
		case MASS_BALANCE_CREATED: {
			const workingMassBalance = state.massBalanceTab.workingMassBalance
				? {
						...state.massBalanceTab.workingMassBalance,
						id: action.payload,
				  }
				: null;
			return {
				...state,
				alert: undefined,
				massBalanceTab: {
					...state.massBalanceTab,
					creatingNew: false,
					workingMassBalance,
				},
			};
		}
		case MASS_BALANCE_CREATE_FAILED: {
			return {
				...state,
				alert: {
					text: 'Unable to create mass balance. Please try again.',
					severity: 'error',
				},
			};
		}
		case NEW_MASS_BALANCE_CLICKED: {
			return {
				...state,
				massBalanceTab: {
					...state.massBalanceTab,
					creatingNew: true,
				},
			};
		}
		case SEARCH_MASS_BALANCES: {
			const reg = new RegExp(state.massBalanceTab.searchValue, 'gi');
			const massBalancesInDataGrid = state.massBalanceTab.massBalances.filter(
				massBalance => {
					const { name, description, ownerId } = massBalance;
					return (
						name.match(reg) ||
						description.match(reg) ||
						ownerId.toString().match(reg)
					);
				},
			);
			return {
				...state,
				massBalanceTab: {
					...state.massBalanceTab,
					massBalancesInDataGrid,
				},
			};
		}
		case MASS_BALANCES_SEARCH_CANCELED: {
			return {
				...state,
				massBalanceTab: {
					...state.massBalanceTab,
					searchValue: '',
					massBalancesInDataGrid: state.massBalanceTab.massBalances,
				},
			};
		}
		case MASS_BALANCES_SEARCH_VALUE_CHANGED: {
			return {
				...state,
				massBalanceTab: {
					...state.massBalanceTab,
					searchValue: action.payload,
				},
			};
		}
		case MASS_BALANCES_SELECTION_CHANGED: {
			const workingMassBalance =
				state.massBalanceTab.massBalances.find(
					massBalance => massBalance.id === action.payload,
				) || null;
			return {
				...state,
				massBalanceTab: {
					...state.massBalanceTab,
					workingMassBalance,
				},
			};
		}
		case CLOSE_ALERT: {
			return {
				...state,
				alert: initialToolsState.alert,
			};
		}
		case DRAWER_DESCRIPTION_BLURRED: {
			return {
				...state,
				addDrawerMassBalance: {
					...state.addDrawerMassBalance,
					description: action.payload,
				},
			};
		}
		case DRAWER_NAME_BLURRED: {
			const newMassBalance = {
				...state.addDrawerMassBalance,
				name: action.payload,
			};
			return {
				...state,
				errors: checkMassBalanceErrors(newMassBalance),
				addDrawerMassBalance: newMassBalance,
			};
		}
		default:
			return state;
	}
};

export default toolsReducer;
