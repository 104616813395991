import * as QueryString from 'query-string';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { Pages } from '../../Containers/Pages/Pages';
import StyledNav, { NavButtonGroup } from './Nav.styles';
import NavButton from './NavButton';

interface Props {
	pages: Pages[];
}

const Nav = ({ pages }: Props) => {
	if (QueryString.parse(useLocation().search).printView === 'true') {
		// hide when in print view is true
		return null;
	}
	return (
		<StyledNav>
			<NavButtonGroup>
				{pages.map(({ icon, to, title, path }, i) => (
					<NavButton key={i} to={to} icon={icon} path={path}>
						{title}
					</NavButton>
				))}
			</NavButtonGroup>
		</StyledNav>
	);
};

export default Nav;
