import styled, { createGlobalStyle } from 'styled-components';
import { rem } from './util/styles';

const StyledApp = styled.div`
	height: 100%;
`;

export default StyledApp;

export const GlobalStyle = createGlobalStyle`
	* {
		box-sizing: border-box;
	}
	html, body, #root {
		height: 100%;
	}
	body {
		margin: 0;
		padding: 0;
		font: 100%/normal 'Roboto', sans-serif;
	}
`;

export const MainWithNav = styled.div`
	display: flex;
	height: calc(100% - ${rem(50)});
`;

export const Main = styled.div`
	width: 100%;
`;
