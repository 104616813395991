import { Evented } from 'mapbox-gl';
import CompositeLayer, { CompositeLayerProps } from './CompositeLayer';

export interface DataLayer {
	displayName: string | undefined;
	active: boolean;
	id: string;
	icon: string | undefined;
}

export type MapHiddenLayers = string[];

export default class LayerManager extends Evented {
	private _layers: CompositeLayer[] = [];
	private _map: mapboxgl.Map | null = null;

	constructor(map: mapboxgl.Map) {
		super();
		this._map = map;
	}

	get layers(): DataLayer[] {
		return this._layers.map(({ displayName, active, id, icon }) => ({
			displayName,
			active,
			id,
			icon,
		}));
	}

	get layerIds(): string[] {
		return this._layers.map(({ id }) => id);
	}

	get selectedLayerIds(): string[] {
		return this._layers.map(({ selectedId }) => selectedId);
	}

	get clusterLayerIds(): string[] {
		return this._layers
			.map(({ clusterId }) => clusterId)
			.filter(Boolean) as string[];
	}

	get hiddenLayers(): MapHiddenLayers {
		return this._layers.filter(({ active }) => !active).map(({ id }) => id);
	}

	addOne(layerInfo: CompositeLayerProps, fireEvent = true): void {
		const layerId = layerInfo.id;
		if (this.layerIds.includes(layerId)) {
			const layer = this._getLayer(layerId);
			if (layer) layer.draw();
			return;
		}

		const layer = new CompositeLayer(layerInfo, this._map as mapboxgl.Map);
		this._layers.push(layer);
		fireEvent && this.fire('layers-loaded');
	}

	addMany(layers: CompositeLayerProps[]): void {
		if (!layers.length) return;
		const layerCount = this._layers.length;
		layers.map(layer => this.addOne(layer, false));
		// only fire the layers loaded event if the number of layers has actually changed
		if (layerCount !== this._layers.length) this.fire('layers-loaded');
	}

	toggleLayer(layerId: CompositeLayerProps['id']): boolean | undefined {
		const layer = this._getLayer(layerId);
		if (!layer) return;
		layer.toggleLayerVisibility();
		return layer.active;
	}

	setLayerVisibility(hiddenLayers: MapHiddenLayers): void {
		this._layers.map(layer => {
			const visible = !hiddenLayers.includes(layer.id);
			if (layer.active !== visible) {
				layer.toggleLayerVisibility();
			}
		});
	}

	clearSelectedLayers(): void {
		this._layers.map(layer => layer.clearSelectedItems());
	}

	redraw(): void {
		this._layers.map(layer => layer.draw());
	}

	setSelectedItemsOnLayer(
		layerId: CompositeLayerProps['id'],
		ids: string[],
	): void {
		const layer = this._getLayer(layerId);
		if (!layer) return;
		layer.selectedItems = ids;
	}

	private _getLayer(
		layerId: CompositeLayerProps['id'],
	): CompositeLayer | undefined {
		return this._layers.find(({ id }) => id === layerId);
	}
}
