import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Nav from '../../Components/Nav/Nav';
import Workspace from '../../Containers/Pages/Workspace';
import Workspaces from '../../Containers/Pages/Workspaces';
import Dashboard from './Dashboard';
import ToolsPage from './Tools';
import StyledPages, { Page } from './Pages.styles';
import MassBalanceDesigner from '../../Components/MassBalance/MassBalanceDesigner';

export interface Pages {
	title: string;
	to: string;
	path?: string | string[];
	icon: React.ReactNode;
	render: React.ReactNode;
}

interface Props {
	pages: Pages[];
}

const Pages = ({ pages }: Props) => {
	return (
		<StyledPages>
			<Nav pages={pages} />
			<Page>
				<Switch>
					<Route path="/" exact component={Dashboard} />
					<Route path="/workspaces" exact component={Workspaces} />
					<Route path="/workspace/:id" exact component={Workspace} />
					<Route
						path="/massbalance/:id"
						exact
						component={MassBalanceDesigner}
					/>
					<Route path="/tools" exact component={ToolsPage} />
					{pages.map(({ path, to, render }, i) => (
						<Route key={i} exact path={path || to}>
							{render}
						</Route>
					))}
					<Route path="*">Not found</Route>
				</Switch>
			</Page>
		</StyledPages>
	);
};

export default Pages;
