import React, { useState, useEffect, useContext } from 'react';
import createAuth0Client, {
	PopupLoginOptions,
	IdToken,
	RedirectLoginResult,
	getIdTokenClaimsOptions,
	RedirectLoginOptions,
	GetTokenSilentlyOptions,
	GetTokenWithPopupOptions,
	LogoutOptions,
	Auth0Client,
} from '@auth0/auth0-spa-js';
import { useSiteConfig } from '../SiteConfiguration/SiteConfigProvider';

export interface Auth0RedirectState {
	targetUrl?: string;
}

export type Auth0User = Omit<IdToken, '__raw'>;
export interface Auth0Context {
	user?: Auth0User;
	isAuthenticated: boolean;
	isInitializing: boolean;
	isPopupOpen: boolean;
	token?: string;
	defaultSite?: string;
	loginWithPopup(o?: PopupLoginOptions): Promise<void>;
	handleRedirectCallback(): Promise<RedirectLoginResult>;
	getIdTokenClaims(o?: getIdTokenClaimsOptions): Promise<IdToken>;
	loginWithRedirect(o?: RedirectLoginOptions): Promise<void>;
	getTokenSilently(o?: GetTokenSilentlyOptions): Promise<string | undefined>;
	getTokenWithPopup(
		o?: GetTokenWithPopupOptions,
	): Promise<string | undefined>;
	logout(o?: LogoutOptions): void;
}

interface Auth0ProviderOptions {
	forceLogin?: boolean;
	children: React.ReactElement;
	redirectUri: string;
	onRedirectCallback(result: RedirectLoginResult): void;
}

export const Auth0Context = React.createContext<Auth0Context>(
	{} as Auth0Context,
);

export const useAuth0 = () => useContext(Auth0Context);
export const Auth0Provider = ({
	forceLogin,
	children,
	redirectUri,
	onRedirectCallback,
	...initOptions
}: Auth0ProviderOptions) => {
	const { config } = useSiteConfig();

	const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
	const [isInitializing, setIsInitializing] = useState<boolean>(true);
	const [isPopupOpen, setIsPopupOpen] = useState<boolean>(false);
	const [user, setUser] = useState<Auth0User>();
	const [auth0Client, setAuth0Client] = useState<Auth0Client>();
	const [token, setToken] = useState<string>();
	const [defaultSite, setDefaultSite] = useState<string>();

	const warnForSitesManaged = (roles: { [index: string]: object }): void => {
		let envsVisible = 0;

		if (roles !== undefined && roles !== null) {
			Object.keys(roles).forEach(k =>
				Object.keys(roles[k]).forEach(() => {
					envsVisible++;
				}),
			);
		}

		// only the default_site is current supported.
		// If the user manages more than one site - warn in the console for now.
		if (envsVisible > 1) {
			console.warn(
				'This user account can administer more than one Harvi site.',
			);
			console.warn(
				'Only the default site for the current user is displayed, currently.',
			);
		}
	};

	useEffect(() => {
		const initAuth0 = async () => {
			const auth0FromHook = await createAuth0Client({
				...initOptions,
				// eslint-disable-next-line @typescript-eslint/camelcase
				redirect_uri: redirectUri,
				domain: config.auth0.domain,
				// eslint-disable-next-line @typescript-eslint/camelcase
				client_id: config.auth0.clientId,
				audience: config.auth0.audience,
			});

			setAuth0Client(auth0FromHook);

			if (window.location.search.includes('code=')) {
				let appState: RedirectLoginResult = {};
				try {
					({
						appState,
					} = await auth0FromHook.handleRedirectCallback());
				} finally {
					onRedirectCallback(appState);
				}
			}

			const authed = await auth0FromHook.isAuthenticated();

			if (authed) {
				const userProfile = await auth0FromHook.getUser();
				setUser(userProfile);

				const defaultSite =
					userProfile[`${config.auth0.tokenKeyPrefix}default_site`];
				setDefaultSite(defaultSite);

				const managedSites =
					userProfile[
						`${config.auth0.tokenKeyPrefix}site_user_roles`
					];
				warnForSitesManaged(managedSites);

				const token = await auth0FromHook.getTokenSilently();
				setToken(token);

				setIsAuthenticated(true);
				setIsInitializing(false);
			} else if (!authed && forceLogin) {
				await auth0FromHook.loginWithRedirect();
			} else {
				setIsInitializing(false);
			}
		};

		initAuth0();
	}, []);

	const loginWithPopup = async (options?: PopupLoginOptions) => {
		if (!auth0Client) {
			throw Error('Auth0Client state not initialized.');
		}

		setIsPopupOpen(true);

		try {
			await auth0Client.loginWithPopup(options);
		} catch (error) {
			console.error(error);
		} finally {
			setIsPopupOpen(false);
		}

		const userProfile = await auth0Client?.getUser();
		setUser(userProfile);
		setIsAuthenticated(true);
	};

	const handleRedirectCallback = async () => {
		if (!auth0Client) {
			throw Error('Auth0Client state not initialized.');
		}

		setIsInitializing(true);

		const result = await auth0Client.handleRedirectCallback();
		const userProfile = await auth0Client.getUser();

		setIsInitializing(false);
		setIsAuthenticated(true);
		setUser(userProfile);

		return result;
	};

	const loginWithRedirect = (options?: RedirectLoginOptions) => {
		if (!auth0Client) {
			throw Error('Auth0Client state not initialized.');
		}

		return auth0Client.loginWithRedirect(options);
	};

	const getTokenSilently = (options?: GetTokenSilentlyOptions) => {
		if (!auth0Client) {
			throw Error('Auth0Client state not initialized.');
		}

		return auth0Client.getTokenSilently(options);
	};

	const logout = (options?: LogoutOptions) => {
		setIsAuthenticated(false);
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		auth0Client!.logout(
			options ?? {
				returnTo: redirectUri,
			},
		);
	};

	const getIdTokenClaims = (options?: getIdTokenClaimsOptions) => {
		if (!auth0Client) {
			throw Error('Auth0Client state not initialized.');
		}

		return auth0Client.getIdTokenClaims(options);
	};

	const getTokenWithPopup = (options?: GetTokenWithPopupOptions) => {
		if (!auth0Client) {
			throw Error('Auth0Client state not initialized.');
		}

		return auth0Client.getTokenWithPopup(options);
	};

	return (
		<Auth0Context.Provider
			value={{
				user,
				isAuthenticated,
				isInitializing,
				isPopupOpen,
				token,
				defaultSite,
				loginWithPopup,
				loginWithRedirect,
				logout,
				getTokenSilently,
				handleRedirectCallback,
				getIdTokenClaims,
				getTokenWithPopup,
			}}>
			{children}
		</Auth0Context.Provider>
	);
};
