import { createStyles, Grid, makeStyles, Theme } from '@material-ui/core';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import React from 'react';
import { Link } from 'react-router-dom';
import { RecentWorkspace } from '../../types/dashboard';
import { getTimeAliasByDate } from './utils';
const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: {
			height: '100%',
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'space-between',
			overflowY: 'auto',
		},
		title: {
			fontWeight: 600,
			fontSize: '1.125rem',
		},
		row: {
			alignItems: 'space-between',
		},
		workspaceNameGrid: {
			textOverflow: 'ellipsis',
			whiteSpace: 'nowrap',
			overflow: 'hidden',
		},
		workspaceNameLink: {
			color: theme.palette.common.black,
			textDecoration: 'none',
		},
		timeAlias: {
			fontSize: '0.75rem',
		},
		viewAll: {
			display: 'flex',
			alignItems: 'center',
			fontSize: '0.75rem',
			fontWeight: 600,
			textDecoration: 'none',
			color: theme.palette.primary.main,
		},
		viewAllText: {
			marginRight: theme.spacing(1),
		},
		viewAllIcon: {
			fontSize: '1rem',
		},
	}),
);

interface Props {
	dataSource: Array<RecentWorkspace>;
}

const RecentWorkspaceList = ({ dataSource }: Props) => {
	const classes = useStyles();

	const renderRecentWorkspace = (recentWorkspace: RecentWorkspace) => {
		const { id, workspace, lastVisited } = recentWorkspace;
		return (
			<Grid container className={classes.row} key={id}>
				<Grid item xs={9} className={classes.workspaceNameGrid}>
					<Link
						className={classes.workspaceNameLink}
						to={`workspace/${workspace.id}`}>
						{workspace.name}
					</Link>
				</Grid>
				<Grid item xs={3}>
					<span className={classes.timeAlias}>
						{getTimeAliasByDate(lastVisited)}
					</span>
				</Grid>
			</Grid>
		);
	};

	return (
		<div className={classes.container}>
			<div className={classes.title}>Recent Workspaces</div>
			{dataSource.map(recentWorkspace =>
				renderRecentWorkspace(recentWorkspace),
			)}
			<Link className={classes.viewAll} to="/workspaces">
				<span className={classes.viewAllText}>View all</span>
				<ArrowForwardIcon className={classes.viewAllIcon} />
			</Link>
		</div>
	);
};

export default RecentWorkspaceList;
