import mapboxgl, { Evented, IControl } from 'mapbox-gl';
import { replacePlaceholder } from './utils';
import { DataLayer } from './layers/LayerManager';
import { BackgroundRegistry } from './BackgroundRegistry';
import SymbolLoader from './symbols/SymbolLoader';

export interface Background {
	title: string;
	uri: string;
	description: string;
	key: string;
}

interface ClassNames {
	[key: string]: string;
}

interface Headings {
	[key: string]: string;
}

interface Config {
	currentStyle?: Background;
	styles?: Background[];
	classNames?: ClassNames;
	headings?: Headings;
	buttonTitle?: string;
	symbolLoader: SymbolLoader;
}

export interface BackgroundChangeEvent {
	background: Background;
}

class BackgroundControl extends Evented implements IControl {
	static readonly CLASS_NAMES = {
		button: 'mapboxgl-background-switcher',
		backgroundList: 'mapboxgl-background-list',
		backgroundButton: 'background-button',
		basemap: 'basemap',
		panel: 'mapboxgl-layer-panel',
		panelOpen: 'layer-panel-open',
		layers: 'layers',
		layerSection: 'layer-panel-section',
		layersList: 'mapboxgl-layer-list',
	};
	static readonly HEADINGS = {
		layers: 'Layers',
		basemap: 'Basemap',
	};
	static readonly LAYER_LIST = {
		activeLayer: 'Turn off {0} layer',
		disabledLayer: 'Turn on {0} layer',
	};
	private _map: mapboxgl.Map | null = null;
	private _allControlsContainer: HTMLElement | null = null;
	private _container: HTMLElement | null = null;
	private _panel: HTMLElement | null = null;
	private _backgroundList: HTMLElement | null = null;
	private _backgroundButtons: HTMLCollection | [] = [];
	private _lastStyle = '';
	private _backgrounds: Background[];
	private _classNames: ClassNames;
	private _headings: Headings;
	private _open = false;
	private _buttonTitle = 'Layers to show on the map';
	private _layers: DataLayer[] = [];
	private _layerButtons: HTMLCollection | [] = [];
	private _symbolLoader: Config['symbolLoader'];

	constructor({
		currentStyle,
		styles,
		classNames,
		headings,
		buttonTitle,
		symbolLoader,
	}: Config) {
		super();
		this._backgrounds = styles || BackgroundRegistry.getAllStyles();
		this._lastStyle =
			currentStyle?.key || BackgroundRegistry.DefaultStyle.key;
		this._classNames = { ...BackgroundControl.CLASS_NAMES, ...classNames };
		this._headings = { ...BackgroundControl.HEADINGS, ...headings };
		this._buttonTitle = buttonTitle || this._buttonTitle;
		this._symbolLoader = symbolLoader;
	}

	set layers(layers: DataLayer[]) {
		this._layers = layers;
		this.createLayersList();
	}

	get panelOpen(): boolean {
		return this._open;
	}

	onAdd(map: mapboxgl.Map): HTMLElement {
		this._map = map;
		this._container = this.createControl();
		this.attachEventListeners();
		return this._container;
	}

	onRemove(): void {
		this._container?.remove();
		this._panel?.remove();
		this._map = null;
	}

	createControl(): HTMLElement {
		const mapContainer = this._map?.getContainer();

		this._allControlsContainer = mapContainer?.getElementsByClassName(
			'mapboxgl-control-container',
		)[0] as HTMLElement;

		const container = document.createElement('div');
		container.classList.add('mapboxgl-ctrl');
		container.classList.add('mapboxgl-ctrl-group');

		container.innerHTML = this.createButton();
		this._panel = this.createPanel();
		mapContainer?.appendChild(this._panel);

		return container;
	}

	createButton(): string {
		return /* HTML */ `
			<button
				class="${this._classNames.button}"
				title="${this._buttonTitle}"
				aria-label="${this._buttonTitle}"
				aria-pressed="false"
			></button>
		`;
	}

	createPanel(): HTMLElement {
		const panel = document.createElement('section');
		panel.classList.add(this._classNames.panel);
		panel.setAttribute('aria-hidden', 'true');

		this._lastStyle =
			this._backgrounds.find(({ key }) => key === this._lastStyle)?.key ||
			'';

		const backgroundButtonHtml = ({
			title,
			uri,
			description,
			key,
		}: Background): string => /* HTML */ `
			<li>
				<div
					data-background="${uri}"
					data-key="${key}"
					class="${this._classNames.backgroundButton} ${key ===
						this._lastStyle && 'active'} ${key}"
					tabindex="-1"
				>
					<h3>
						${title}
					</h3>
					<p>${description}</p>
				</div>
			</li>
		`;

		const panelHtml = /* HTML */ `
			<div
				class="${this._classNames.layers} ${this._classNames
					.layerSection}"
			>
				<h1>${this._headings.layers}</h1>
				<ul class="${this._classNames.layersList}"></ul>
			</div>
			<div
				class="${this._classNames.basemap} ${this._classNames
					.layerSection}"
			>
				<h2>${this._headings.basemap}</h2>
				<ul class="${this._classNames.backgroundList}">
					${this._backgrounds.map(backgroundButtonHtml).join('')}
				</ul>
			</div>
		`;
		panel.innerHTML = panelHtml;
		return panel;
	}

	createLayersList(): void {
		const layersList = this._panel?.getElementsByClassName(
			this._classNames.layersList,
		)[0];

		if (!layersList) return;

		const getString = (active: boolean): string =>
			active
				? BackgroundControl.LAYER_LIST.activeLayer
				: BackgroundControl.LAYER_LIST.disabledLayer;

		const layerButtonHtml = ({
			displayName,
			active,
			id,
			icon,
		}: DataLayer): string => /* HTML */ `
			<li
				class="${!active && 'off'}"
				tabindex="-1"
				data-id="${id}"
				title="${replacePlaceholder(
					getString(active),
					displayName as string,
				)}"
			>
				<div class="icon">
					<img src="${this._symbolLoader.getSvg(icon)}" />
				</div>
				${displayName}
			</li>
		`;

		const layersListHtml = this._layers.map(layerButtonHtml).join('');

		layersList.innerHTML = layersListHtml;

		this.attachLayerToggleEvents();
	}

	attachLayerToggleEvents(): void {
		const layersList = this._panel?.getElementsByClassName(
			this._classNames.layersList,
		)[0];
		this._layerButtons = layersList?.getElementsByTagName('li') ?? [];
		[...this._layerButtons].map(button => {
			button.addEventListener('click', this.toggleLayer(button));
			button.addEventListener('keyup', this.toggleLayer(button));
		});
	}

	toggleLayer = (button: Element) => (e: Event): void => {
		const { type, key } = e as KeyboardEvent;
		// if a keyboard event only continue if 'Enter' or 'Space' has been triggered
		if (type === 'keyup' && !(key === 'Enter' || key === ' ')) return;

		const id = button.getAttribute('data-id');
		button.classList.toggle('off');
		this.fire('layer-toggle', { id });
	};

	attachEventListeners(): void {
		const menuButton = this._container?.getElementsByClassName(
			this._classNames.button,
		)[0];

		menuButton?.addEventListener('click', this.toggleMenu);

		this._backgroundList = this._panel?.getElementsByClassName(
			this._classNames.backgroundList,
		)[0] as HTMLElement;

		this._backgroundButtons = this._backgroundList.getElementsByClassName(
			this._classNames.backgroundButton,
		);

		[...this._backgroundButtons].map(button => {
			button.addEventListener('click', this.changeMap(button));
			button.addEventListener('keyup', this.changeMap(button));
		});
	}

	toggleMenu = (): void => {
		if (this._open) this.closeMenu();
		else this.openMenu();
	};

	openMenu = (): void => {
		this._open = true;
		this._panel?.classList?.add(this._classNames.panelOpen);
		this.unHideFromScreenReader();
		this._allControlsContainer?.classList.add(this._classNames.panelOpen);
	};

	closeMenu = (): void => {
		this._open = false;
		this._panel?.classList?.remove(this._classNames.panelOpen);
		this.hideFromScreenReader();
		this._allControlsContainer?.classList.remove(
			this._classNames.panelOpen,
		);
	};

	hideFromScreenReader(): void {
		this._panel?.setAttribute('aria-hidden', 'true');
		[...this._backgroundButtons, ...this._layerButtons].map(button =>
			button.setAttribute('tabindex', '-1'),
		);
	}

	unHideFromScreenReader(): void {
		this._panel?.removeAttribute('aria-hidden');
		[...this._backgroundButtons, ...this._layerButtons].map(button =>
			button.setAttribute('tabindex', '0'),
		);
	}

	changeMap = (button: Element) => (e: Event): void => {
		const { type, key } = e as KeyboardEvent;
		// if a keyboard event only continue if 'Enter' or 'Space' has been triggered
		if (type === 'keyup' && !(key === 'Enter' || key === ' ')) return;

		const styleKey = button.getAttribute('data-key');
		this.setBackground(styleKey);
	};

	setBackground(styleKey: string | null): void {
		if (!styleKey || styleKey === this._lastStyle) return;
		const background = BackgroundRegistry.getStyleWithFallback(styleKey);
		const active =
			this._backgroundList?.getElementsByClassName('active') || [];

		[...active].map(el => el.classList.remove('active'));

		const buttons = this._backgroundList?.getElementsByClassName(
			background.key,
		);

		if (buttons && buttons.length > 0) {
			buttons[0].classList.add('active');
		}

		this._map?.setStyle(background.uri);
		this._lastStyle = background.key || '';
		this.fire('backgroundChange', {
			background,
		} as BackgroundChangeEvent);
	}
}

export default BackgroundControl;
