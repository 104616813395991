import { SiteConfig } from './SiteConfig';
import { useContext, useState, useEffect } from 'react';
import React from 'react';
import Loading from '../Loading/Loading';

export interface SiteConfigContext {
	config: SiteConfig;
	configLoaded: boolean;
}

export const SiteConfigContext = React.createContext<SiteConfigContext | null>(
	null,
);
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
export const useSiteConfig = () => useContext(SiteConfigContext)!;

interface SiteConfigProviderOptions {
	url: string;
	children: React.ReactElement;
}

export const SiteConfigProvider = ({
	url,
	children,
}: SiteConfigProviderOptions) => {
	const [siteConfig, setSiteConfig] = useState<SiteConfig>();
	const [configLoaded, setConfigLoaded] = useState<boolean>(false);

	useEffect(() => {
		const configPromise = fetch(url);

		configPromise
			.then(res => res.json())
			.then((config: SiteConfig) => {
				setSiteConfig(config);
				setConfigLoaded(true);
			})
			.catch(e =>
				console.error(`Unable to load site configuration. ${e}`),
			);
	}, [url]);

	return (
		<SiteConfigContext.Provider
			value={{
				// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
				config: siteConfig!,
				configLoaded,
			}}>
			<Loading loading={configLoaded === false}>{children}</Loading>
		</SiteConfigContext.Provider>
	);
};
