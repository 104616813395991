import { TextField } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import 'devextreme/dist/css/dx.light.css';
import React, { ChangeEvent } from 'react';
import styled from 'styled-components';
import {
	Channel,
	Display,
	ChartFormData,
	EventTypeFromChartForm,
} from '../../../types/workspace';
import {
	charDisplayTypeData,
	ChartDisplayType,
	Series,
	seriesData,
} from './CreateDashletService';
import DataSourceInput from './DataSourceInput';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../store';
import {
	chartFormInputBlurred,
	updateActiveWsItemChartFormData,
} from '../../../store/actions/workspace';

interface Props {
	menuDisplayMode: number;
	action: string;
	chartFormData: ChartFormData;
}

enum ChartFormInputTypeEnum {
	name = 1,
	description = 2,
}

const ChartDashletForm = ({ menuDisplayMode, chartFormData }: Props) => {
	const dispatch = useDispatch<AppDispatch>();
	const foundNameError = chartFormData.errors?.find(
		err => err.fieldName === 'name',
	);
	const nameErrorText = foundNameError ? foundNameError.errorMessage : '';

	const onTextFieldBlur = (
		event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>,
		inputFieldType: ChartFormInputTypeEnum,
	) => {
		if (inputFieldType === ChartFormInputTypeEnum.name) {
			const newChartFormData: ChartFormData = {
				...chartFormData,
				name: event.target.value,
			};
			dispatch(chartFormInputBlurred(newChartFormData));
		} else if (inputFieldType === ChartFormInputTypeEnum.description) {
			const newChartFormData: ChartFormData = {
				...chartFormData,
				description: event.target.value,
			};
			dispatch(chartFormInputBlurred(newChartFormData));
		}
	};

	const onHandelOnDataSourceChange = (channel: Channel): void => {
		if (channel && channel.id !== -1) {
			const newChartFormData: ChartFormData = {
				...chartFormData,
				name: channel.displayName,
				selectedChannel: channel,
			};
			dispatch(chartFormInputBlurred(newChartFormData));
		}
	};

	const onChartNameChange = (
		event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
	): void => {
		const name =
			event.target && event.target.value ? event.target.value : '';
		const newChartFormData: ChartFormData = {
			...chartFormData,
			name: name,
		};
		//setChartName(name);
		dispatch(chartFormInputBlurred(newChartFormData));
	};

	const onChartSeriesChange = (
		event: React.ChangeEvent<{
			name?: string | undefined;
			value: unknown;
		}>,
	): void => {
		const seriesName =
			event.target && event.target.value ? event.target.value : '';
		const series = seriesData.find(series => series.name == seriesName);
		const newChartFormData: ChartFormData = {
			...chartFormData,
			series: series && series.name ? series.name : seriesData[0].name,
		};
		dispatch(chartFormInputBlurred(newChartFormData));
		dispatch(
			updateActiveWsItemChartFormData(
				newChartFormData,
				EventTypeFromChartForm.CHANGE_INTERVAL,
			),
		);
	};

	const onChartTypeChange = (
		event: React.ChangeEvent<{
			name?: string | undefined;
			value: unknown;
		}>,
	): void => {
		const chartTypeName =
			event.target && event.target.value ? event.target.value : '';
		const chartType = charDisplayTypeData.find(
			charDisplayType => charDisplayType.name == chartTypeName,
		);
		const newChartFormData: ChartFormData = {
			...chartFormData,
			chartType:
				chartType && chartType.name
					? chartType.name
					: charDisplayTypeData[0].name,
		};
		dispatch(chartFormInputBlurred(newChartFormData));
		dispatch(
			updateActiveWsItemChartFormData(
				newChartFormData,
				EventTypeFromChartForm.CHANGE_CHART_TYPE,
			),
		);
	};

	switch (menuDisplayMode) {
		case Display.Properties:
			return (
				<>
					<Content>
						<DataSourceInput
							handelOnDataSourceChange={
								onHandelOnDataSourceChange
							}
							errors={chartFormData.errors}
							selectedValue={chartFormData.selectedChannel}
						/>
						<TextField
							error={Boolean(foundNameError)}
							helperText={nameErrorText}
							label="Chart Name"
							value={chartFormData.name}
							onBlur={event =>
								onTextFieldBlur(
									event,
									ChartFormInputTypeEnum.name,
								)
							}
							onChange={onChartNameChange}
							required
							variant="outlined"></TextField>
						<TextField
							defaultValue={chartFormData.description}
							label="Description"
							variant="outlined"
							onBlur={event =>
								onTextFieldBlur(
									event,
									ChartFormInputTypeEnum.description,
								)
							}
						/>

						<FormControl variant="outlined" className="formControl">
							<InputLabel id="demo-simple-select-outlined-label">
								Series
							</InputLabel>
							<Select
								labelId="demo-simple-select-outlined-label"
								id="demo-simple-select-outlined"
								defaultValue={chartFormData.series}
								onChange={onChartSeriesChange}
								label="Series">
								{seriesData.map((seriesItem: Series) => {
									return (
										<MenuItem
											key={seriesItem.name}
											value={seriesItem.name}>
											{seriesItem.displayName}
										</MenuItem>
									);
								})}
							</Select>
						</FormControl>

						<FormControl variant="outlined" className="formControl">
							<InputLabel id="demo-simple-chart-type-outlined-label">
								Chart Type
							</InputLabel>
							<Select
								labelId="demo-simple-chart-type-outlined-label"
								id="demo-simple-chart-type-outlined"
								defaultValue={chartFormData.chartType}
								onChange={onChartTypeChange}
								label="Chart Type">
								{charDisplayTypeData.map(
									(chartDisplayItem: ChartDisplayType) => {
										return (
											<MenuItem
												key={chartDisplayItem.name}
												value={chartDisplayItem.name}>
												{chartDisplayItem.name}
											</MenuItem>
										);
									},
								)}
							</Select>
						</FormControl>
					</Content>
				</>
			);
		case Display.ReferenceChart:
			return (
				<>
					<h3>Reference Chart</h3>
				</>
			);
		case Display.DisplayOptions:
			return (
				<>
					<h3>Display Options</h3>
				</>
			);
		case Display.ToggleView:
			return (
				<>
					<h3>Toggle View</h3>
				</>
			);
		default:
			return <></>;
	}
};

export default ChartDashletForm;

const Content = styled.div`
	padding: 12px;
	display: flex;
	flex-direction: column;
	flex: 1;
	& > div {
		width: 100%;
		margin-bottom: 24px;
	}
`;
