import React from 'react';
import { useAuth0 } from '../Auth0/Auth0';
import RoundButton from '../RoundButton/RoundButton';
import { PermIdentity } from '@material-ui/icons';

const UserProfile = () => {
	const { logout, user } = useAuth0();

	const logoutAction = (e: React.MouseEvent) => {
		e.preventDefault();
		logout();
	};

	const userName = user?.email ?? '';

	return (
		<RoundButton
			title={`Click to sign out ${userName}`}
			onClick={logoutAction}>
			<PermIdentity />
		</RoundButton>
	);
};

export default UserProfile;
