export enum SelectedTool {
	MassBalance,
	ILI,
}

export interface MassBalance {
	id: number;
	name: string;
	description: string;
	ownerId: number;
	ownerName?: number;
	modifiedAt?: string;
}

// eslint-disable-next-line @typescript-eslint/interface-name-prefix
export interface ILI {
	id: number;
	name: string;
	description: string;
	ownerId: number;
	modifiedAt?: string;
}

export interface MassBalanceError {
	errorMessage: string;
	fieldName?: string;
}
