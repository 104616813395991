import styled from 'styled-components';
import { rem } from '../../util/styles';

const StyledNav = styled.nav`
	background: #43575d;
	width: ${rem(80)};
	z-index: 1400;
`;

export default StyledNav;

export const NavButtonGroup = styled.ul`
	display: block;
	list-style: none;
	margin: 0;
	padding: 0;
`;
