import React, { ErrorInfo } from 'react';
import ErrorPage from '../ErrorPage/ErrorPage';

interface ErrorHandlerState {
	hasError: boolean;
	error: Error;
	errorInfo: ErrorInfo;
}

// According to the React documentation, Error Bounaries must be classic components (in order to get componentDidCatch).
class ErrorHandler extends React.Component<object, ErrorHandlerState> {
	constructor(object: object) {
		super(object);
		this.state = {
			hasError: false,
			error: new Error(),
			errorInfo: { componentStack: '' },
		};
	}

	static getDerivedStateFromError(error: Error): ErrorHandlerState {
		return {
			hasError: true,
			error: error,
			errorInfo: { componentStack: '' },
		};
	}

	componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
		this.setState({
			hasError: true,
			error:
				error ||
				new Error('Unfortunately, the error information was lost.'),
			errorInfo: errorInfo,
		});
	}

	render() {
		if (this.state.hasError) {
			return (
				<ErrorPage
					error={this.state.error}
					errorInfo={this.state.errorInfo}
				/>
			);
		}

		return this.props.children;
	}
}

export default ErrorHandler;
