import Chart, {
	ArgumentAxis,
	Border,
	CommonSeriesSettings,
	Grid,
	Legend,
	Margin,
	Pane,
	Series,
	Size,
	Tooltip,
	ValueAxis,
} from 'devextreme-react/chart';
import React from 'react';
import {
	ChartDashletOptions,
	WorkspaceItem,
} from '../../../../types/workspace';
import Loading from '../../../Loading/Loading';

export interface Props {
	item: WorkspaceItem;
	isFocusModeOn?: boolean;
}

const DefaultChartWidget = ({ item, isFocusModeOn }: Props) => {
	const chartDashletOption: ChartDashletOptions = JSON.parse(item.options);

	const heightFactor = 80;
	const widthFactor = 130;
	let chartHeight = item.height * heightFactor;
	let chartWidth = item.width * widthFactor;

	if (isFocusModeOn) {
		chartHeight = 400;
		chartWidth = 1040;
	}
	return (
		<Loading loading={chartDashletOption.isLoading ? true : false}>
			<Chart palette="Material" dataSource={chartDashletOption.chartData}>
				<Pane backgroundColor="#FFFFFF">
					<Border visible={true} width={2} />
				</Pane>
				<Margin left={0} />
				<Size height={chartHeight} width={chartWidth} />
				<CommonSeriesSettings
					argumentField="time"
					type={chartDashletOption.chartType}
				/>
				<Series
					key={chartDashletOption.series}
					valueField={chartDashletOption.series}
					name={chartDashletOption.series}
					type={chartDashletOption.chartType}
					color={chartDashletOption.color}
				/>

				<ValueAxis visible={true} autoBreaksEnabled={false} />

				<ArgumentAxis
					valueMarginsEnabled={false}
					discreteAxisDivisionMode="crossLabels">
					<Grid visible={false} />
				</ArgumentAxis>
				<Legend
					verticalAlignment="top"
					horizontalAlignment="center"
					itemTextPosition="right"
				/>
				<Tooltip enabled={true} />
			</Chart>
		</Loading>
	);
};

export default DefaultChartWidget;
