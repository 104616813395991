import styled from 'styled-components';

import logo from './Info360Logo.png';
import { rem } from '../../util/styles';

interface AppSwitcherProps {
	open?: boolean;
}

const StyledHeader = styled.div`
	background: #252f33;
	color: #fff;
	padding: ${rem(5)} ${rem(20)} ${rem(5)} 0;
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: ${rem(50)};
	position: relative;
	z-index: 1500;
`;

export default StyledHeader;

export const Logo = styled.div`
	&:before {
		content: '';
		display: inline-block;
		width: ${rem(119)};
		height: ${rem(50)};
		background: url(${logo});
		vertical-align: middle;
	}
`;

export const RightAlign = styled.div``;
