import React from 'react';
import { useRouteMatch } from 'react-router-dom';

import StyledNavButton, { NavLink } from './NavButton.styles';

interface Props {
	children?: React.ReactNode;
	icon?: React.ReactNode;
	to: string;
	path?: string | string[];
}

const NavButton = ({ children, icon, to, path }: Props) => {
	const match = useRouteMatch({ path: path || to, exact: true });
	return (
		<StyledNavButton active={!!match}>
			<NavLink to={to}>
				{icon}
				{children}
			</NavLink>
		</StyledNavButton>
	);
};

export default NavButton;
