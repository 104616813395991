import styled, { css } from 'styled-components';
import { rem } from '../../util/styles';

interface RoundButtonProps {
	active?: boolean;
}

const StyledRoundButton = styled.button<RoundButtonProps>`
	background: none;
	color: inherit;
	border: none;
	border-radius: 100%;
	height: ${rem(40)};
	width: ${rem(40)};
	display: inline-flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	${props =>
		props.active &&
		css`
			background: #43575d;
		`}
	&:hover,
	&:focus {
		background: #43575d50;
	}
	& + & {
		margin-left: ${rem(10)};
	}
`;

export default StyledRoundButton;
