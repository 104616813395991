import { HelpOutline } from '@material-ui/icons';
import * as QueryString from 'query-string';
import React from 'react';
import { useLocation } from 'react-router-dom';
import RoundButton from '../RoundButton/RoundButton';
import UserProfile from '../UserProfile/UserProfile';
import StyledHeader, { Logo, RightAlign } from './Header.styles';

// removed app switcher can be restored from commit: db79717a7dcc9694f7a421619e43f0d2f1bc4c9c

const Header = () => {
	if (QueryString.parse(useLocation().search).printView === 'true') {
		// hide when in print view is true
		return null;
	}
	return (
		<StyledHeader>
			<Logo />
			<RightAlign>
				<UserProfile data-testing-id="user-profile-button" />
				<RoundButton title="Help">
					<HelpOutline />
				</RoundButton>
			</RightAlign>
		</StyledHeader>
	);
};

export default Header;
