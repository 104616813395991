import { MassBalance, MassBalanceError } from '../../types/tools';

export const checkMassBalanceErrors = ({
	name,
}: MassBalance): Array<MassBalanceError> => {
	let errors: Array<MassBalanceError> = [];
	if (name === '') {
		const nameError = {
			fieldName: 'name',
			errorMessage: 'MassBalance name is required.',
		};
		errors = [...errors, nameError];
	}
	return errors;
};
