interface AssetDetailType {
	displayName: string;
	icon: string;
	color: string;
}

export const assetUnknown: AssetDetailType = {
	displayName: 'Unknown',
	icon: 'unknown',
	color: '#4A6067',
};

export default class AssetRegistry {
	private static assetTypes: { [index: number]: AssetDetailType } = {
		19: {
			displayName: 'Flow Metre',
			icon: 'flow-metre',
			color: '#42A5F5',
		},
		20: {
			displayName: 'Valve',
			icon: 'valve',
			color: '#AB47BC',
		},
		21: {
			displayName: 'Pump',
			icon: 'pump',
			color: '#26A69A',
		},
		22: {
			displayName: 'Pump Station',
			icon: 'pump-station',
			color: '#9CCC65',
		},
		27: {
			displayName: 'Reservoir',
			icon: 'reservoir',
			color: '#29B6F6',
		},
		30: {
			displayName: 'Tank',
			icon: 'tank',
			color: '#5C6BC0',
		},
		34: {
			displayName: 'Well',
			icon: 'well',
			color: '#D4E157',
		},
		1000: {
			displayName: 'Customer Point',
			icon: 'customer-point',
			color: '#8D6E63',
		},
		1001: {
			displayName: 'Fixed Head',
			icon: 'fixed-head',
			color: '#7E57C2',
		},
		1002: {
			displayName: 'Float Valve',
			icon: 'float-valve',
			color: '#26C6DA',
		},
		1004: {
			displayName: 'Hydrant',
			icon: 'hydrant',
			color: '#EF5350',
		},
		1005: {
			displayName: 'Non-return Valve',
			icon: 'non-return-valve',
			color: '#FFA726',
		},
		1009: {
			displayName: 'Transfer Node',
			icon: 'transfer-node',
			color: '#78909C',
		},
	};

	public static getIcon(id: number): string {
		const assetType = AssetRegistry.assetTypes[id];
		return assetType?.icon || assetUnknown.icon;
	}

	public static getDisplayName(id: number): string {
		const assetType = AssetRegistry.assetTypes[id];
		return assetType?.displayName || `${id}`;
	}

	public static getColor(id: number): string {
		const assetType = AssetRegistry.assetTypes[id];
		return assetType?.color || assetUnknown.color;
	}
}
