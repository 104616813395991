import React from 'react';
import ReactGridLayout, { Layout } from 'react-grid-layout';
import styled from 'styled-components';
import {
	Workspace,
	WorkspaceItem,
	DashletType,
} from '../../../types/workspace';
import GenericDashlet from '../Dashlet/Generic/GenericDashlet';
import './workspaceGrid.css';

const StyledReactGridLayout = styled(ReactGridLayout)`
	.react-grid-item:not(.react-grid-placeholder) {
		background: #ffffff !important;
	}
`;

export interface Props {
	workspace: Workspace;
	onDeleteWidgetHandler: (key: number) => void;
	onLayoutChange: (layout: Layout[]) => void;
	onUpdateDashletHandler: (wsItem: WorkspaceItem) => void;
	onInitDashletHandler: (wsItem: WorkspaceItem) => void;
	handelSwitchToFocusMode: (wsItem: WorkspaceItem) => void;
}

const WorkspaceLayoutItems = ({
	workspace,
	onDeleteWidgetHandler,
	onLayoutChange,
	onUpdateDashletHandler,
	onInitDashletHandler,
	handelSwitchToFocusMode,
}: Props) => {
	const items = workspace.workspaceItem;

	const getLayout = (): Layout[] | undefined => {
		const newLayout = items?.map((item, index) => {
			let minW: number, minH: number;
			switch (item.dashletTypeId) {
				case DashletType.Chart:
					minW = 6;
					minH = 4;
					break;
				case DashletType.Gauge:
					minW = 3;
					minH = 4;
					break;
				default:
					minW = 3;
					minH = 3;
			}
			return {
				i: index.toString(),
				x: item.rowPosition,
				y: item.columnPosition,
				w: item.width,
				h: item.height,
				minW,
				minH,
				isResizable: true,
				isDraggable: true,
			};
		});
		return newLayout;
	};
	const layout = getLayout();

	const handleLayoutChange = (layouts: Layout[]): void => {
		onLayoutChange(layouts);
	};

	const renderItem = (item: WorkspaceItem, index: number): JSX.Element => {
		return (
			<div key={index}>
				<div className="react-grid-item-content">
					<GenericDashlet
						widgetItem={item}
						itemIndex={index}
						onUpdateDashletHandler={onUpdateDashletHandler}
						onInitDashletHandler={onInitDashletHandler}
						onDeleteWidgetHandler={onDeleteWidgetHandler}
						handelSwitchToFocusMode={handelSwitchToFocusMode}
					/>
				</div>
			</div>
		);
	};

	if (items && items.length > 0) {
		return (
			<>
				<StyledReactGridLayout
					cols={12}
					//onResizeStop={onResizeStop}
					width={1750}
					layout={layout}
					onLayoutChange={handleLayoutChange}
					autoSize={true}
					draggableHandle=".DragHandle"
					draggableCancel=".DragCancel">
					{items.map((item, index) => renderItem(item, index))}
				</StyledReactGridLayout>
			</>
		);
	} else {
		return <></>;
	}
};

export default WorkspaceLayoutItems;
