import {
	CircularGauge,
	Export,
	Label,
	Margin,
	Range,
	RangeContainer,
	Scale,
	Size,
	ValueIndicator,
} from 'devextreme-react/circular-gauge';
import Chart, {
	ArgumentAxis,
	Border,
	CommonSeriesSettings,
	Grid,
	Legend,
	Pane,
	Series,
	Tooltip,
	ValueAxis,
} from 'devextreme-react/chart';
import React from 'react';
import {
	GaugeDashletOptions,
	WorkspaceItem,
} from '../../../../types/workspace';
import Loading from '../../../Loading/Loading';
import styled from 'styled-components';

export interface Props {
	item: WorkspaceItem;
	isFocusModeOn?: boolean;
}

const DefaultGaugeWidget = ({ item, isFocusModeOn }: Props) => {
	const gaugeDashletOption: GaugeDashletOptions = JSON.parse(item.options);
	const heightFactor = 105;
	const widthFactor = 125;
	let gaugeHeight = item.height * heightFactor;
	let gaugeWidth = item.width * widthFactor;
	if (isFocusModeOn) {
		gaugeHeight = 550;
		gaugeWidth = 750;
	}
	return (
		<Loading loading={gaugeDashletOption.isLoading ? true : false}>
			<CircularGauge id="gauge" value={gaugeDashletOption.gaugeValue}>
				<Size height={gaugeHeight} width={gaugeWidth} />
				<ValueIndicator
					type="triangleNeedle"
					color={gaugeDashletOption.color}></ValueIndicator>
				<Scale startValue={0} endValue={200}>
					<Label useRangeColors={true} />
				</Scale>
				<Margin bottom={20} />
				<RangeContainer palette={gaugeDashletOption.color}>
					<Range
						startValue={gaugeDashletOption.thresholdTo}
						endValue={gaugeDashletOption.thresholdFrom}
					/>
				</RangeContainer>
				<Export enabled={false} />
			</CircularGauge>
			<GaugeRangeView>
				<Chart dataSource={gaugeDashletOption.chartData}>
					<Pane backgroundColor="#FFFFFF">
						<Border visible={true} width={2} />
					</Pane>
					<Margin bottom={60} />
					<Size height={160} width={gaugeWidth} />
					<CommonSeriesSettings argumentField="time" type="Line" />
					<Series
						valueField="average"
						color={gaugeDashletOption.color}
					/>
					<ValueAxis visible={true} autoBreaksEnabled={false} />

					<ArgumentAxis
						valueMarginsEnabled={true}
						discreteAxisDivisionMode="crossLabels">
						<Grid visible={true} />
					</ArgumentAxis>
					<Legend
						visible={false}
						verticalAlignment="top"
						horizontalAlignment="center"
						itemTextPosition="right"
					/>
					<Tooltip enabled={true} />
				</Chart>
			</GaugeRangeView>
		</Loading>
	);
};

export default DefaultGaugeWidget;

const GaugeRangeView = styled.div`
	left: 21px;
	right: 21px;
	bottom: 80px;
	height: 150px;
`;
