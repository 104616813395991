export const CHANGE_SELECTED_TOOL = 'CHANGE_SELECTED_TOOL';
export type CHANGE_SELECTED_TOOL = typeof CHANGE_SELECTED_TOOL;

export const FETCHING_MASS_BALANCES = 'FETCHING_MASS_BALANCES';
export type FETCHING_MASS_BALANCES = typeof FETCHING_MASS_BALANCES;

export const MASS_BALANCES_FETCHED = 'MASS_BALANCES_FETCHED';
export type MASS_BALANCES_FETCHED = typeof MASS_BALANCES_FETCHED;

export const MASS_BALANCES_FETCH_FAILED = 'MASS_BALANCES_FETCH_FAILED';
export type MASS_BALANCES_FETCH_FAILED = typeof MASS_BALANCES_FETCH_FAILED;

export const CREATING_MASS_BALANCE = 'CREATING_MASS_BALANCE';
export type CREATING_MASS_BALANCE = typeof CREATING_MASS_BALANCE;

export const MASS_BALANCE_CREATED = 'MASS_BALANCE_CREATED';
export type MASS_BALANCE_CREATED = typeof MASS_BALANCE_CREATED;

export const MASS_BALANCE_CREATE_FAILED = 'MASS_BALANCE_CREATE_FAILED';
export type MASS_BALANCE_CREATE_FAILED = typeof MASS_BALANCE_CREATE_FAILED;

export const NEW_MASS_BALANCE_CLICKED = 'NEW_MASS_BALANCE_CLICKED';
export type NEW_MASS_BALANCE_CLICKED = typeof NEW_MASS_BALANCE_CLICKED;

export const SEARCH_MASS_BALANCES = 'SEARCH_MASS_BALANCES';
export type SEARCH_MASS_BALANCES = typeof SEARCH_MASS_BALANCES;

export const MASS_BALANCES_SEARCH_CANCELED = 'MASS_BALANCES_SEARCH_CANCELED';
export type MASS_BALANCES_SEARCH_CANCELED = typeof MASS_BALANCES_SEARCH_CANCELED;

export const MASS_BALANCES_SEARCH_VALUE_CHANGED =
	'MASS_BALANCES_SEARCH_VALUE_CHANGED';
export type MASS_BALANCES_SEARCH_VALUE_CHANGED = typeof MASS_BALANCES_SEARCH_VALUE_CHANGED;

export const MASS_BALANCES_SELECTION_CHANGED =
	'MASS_BALANCES_SELECTION_CHANGED';
export type MASS_BALANCES_SELECTION_CHANGED = typeof MASS_BALANCES_SELECTION_CHANGED;

export const CLOSE_ALERT = 'CLOSE_ALERT';
export type CLOSE_ALERT = typeof CLOSE_ALERT;

export const MASS_BALANCE_CANCEL_CLICKED = 'MASS_BALANCE_CANCEL_CLICKED';
export type MASS_BALANCE_CANCEL_CLICKED = typeof MASS_BALANCE_CANCEL_CLICKED;

export const ADD_NEW_MASS_BALANCE = 'ADD_NEW_MASS_BALANCE';
export type ADD_NEW_MASS_BALANCE = typeof ADD_NEW_MASS_BALANCE;

export const DRAWER_NAME_BLURRED = 'DRAWER_NAME_BLURRED';
export type DRAWER_NAME_BLURRED = typeof DRAWER_NAME_BLURRED;

export const DRAWER_DESCRIPTION_BLURRED = 'DRAWER_DESCRIPTION_BLURRED';
export type DRAWER_DESCRIPTION_BLURRED = typeof DRAWER_DESCRIPTION_BLURRED;
