import React from 'react';
import { DateBox } from 'devextreme-react';
import { Grid } from '@material-ui/core';

import {
	WorkspaceItem,
	ChartDashletOptions,
} from '../../../../types/workspace';
import * as Dashlet from '../Generic/GenericDashlet.style';
import moment from 'moment';
import Loading from '../../../Loading/Loading';

export interface Props {
	item: WorkspaceItem;
	onHeaderDateTimeChange: (wsItem: WorkspaceItem) => void;
}

const DefaultChartHeader = ({ item, onHeaderDateTimeChange }: Props) => {
	const chartDashletOption: ChartDashletOptions = JSON.parse(item.options);

	const dateTimeFromChange = (event: { value?: string }) => {
		if (event.value != undefined && event.value != '') {
			if (
				chartDashletOption.dashletInit == true &&
				moment(event.value).format('MM/DD/YYYY') !=
					chartDashletOption.dateTimeFrom
			) {
				const options: ChartDashletOptions = {
					...chartDashletOption,
					dateTimeFrom: moment(event.value).format('MM/DD/YYYY'),
				};
				const newWsItem: WorkspaceItem = {
					...item,
					options: JSON.stringify(options),
				};
				onHeaderDateTimeChange(newWsItem);
			}
		}
	};

	const dateTimeToChange = (event: { value?: string }) => {
		if (event.value != undefined && event.value != '') {
			if (
				chartDashletOption.dashletInit == true &&
				moment(event.value).format('MM/DD/YYYY') !=
					chartDashletOption.dateTimeTo
			) {
				const options: ChartDashletOptions = {
					...chartDashletOption,
					dateTimeTo: moment(event.value).format('MM/DD/YYYY'),
				};
				const newWsItem: WorkspaceItem = {
					...item,
					options: JSON.stringify(options),
				};
				onHeaderDateTimeChange(newWsItem);
			}
		}
	};

	return (
		<Loading loading={chartDashletOption.isLoading ? true : false}>
			<Grid container spacing={1}>
				<Grid item xs={3}>
					<Dashlet.DateFromBox>
						<DateBox
							value={chartDashletOption.dateTimeFrom}
							onValueChanged={dateTimeFromChange}
							type="datetime"
						/>
					</Dashlet.DateFromBox>
				</Grid>
				<Grid item xs={3}>
					<Dashlet.DateToBox>
						<DateBox
							value={chartDashletOption.dateTimeTo}
							onValueChanged={dateTimeToChange}
							type="datetime"
						/>
					</Dashlet.DateToBox>
				</Grid>
			</Grid>
		</Loading>
	);
};

export default DefaultChartHeader;
