import React, { useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete, {
	createFilterOptions,
} from '@material-ui/lab/Autocomplete';
import { useDispatch, useSelector } from 'react-redux';
import { useSiteConfig } from '../../../Components/SiteConfiguration/SiteConfigProvider';
import { RootState } from '../../../store/types/root';
import { FilterOptionsState } from '@material-ui/lab/useAutocomplete';
import { fetchLatestSensorList } from '../../../store/actions/workspace';
import { Channel, ChartFormDataError } from '../../../types/workspace';
import { Select, MenuItem, FormControl, InputLabel } from '@material-ui/core';
import Loading from '../../Loading/Loading';

export interface Props {
	handelOnDataSourceChange: (channel: Channel) => void;
	handelOnDataSourceBlur?: (
		event: React.FocusEvent<HTMLInputElement>,
	) => void;
	errors?: ChartFormDataError[];
	selectedValue?: Channel;
}

const DataSourceInput = ({
	handelOnDataSourceChange,
	handelOnDataSourceBlur,
	errors,
	selectedValue,
}: Props) => {
	const { config } = useSiteConfig();
	const dispatch = useDispatch();
	const foundChannelError = errors?.find(err => err.fieldName === 'channel');
	const channelErrorText = foundChannelError
		? foundChannelError.errorMessage
		: '';

	const filter = createFilterOptions<Channel>();

	const [value, setValue] = React.useState<Channel | null>(null);
	const [init, setInit] = React.useState(false);

	if (selectedValue && value && selectedValue !== value) {
		setValue(selectedValue);
	}

	const { channelList } = useSelector(
		(state: RootState) => state.workspace.workspace,
	);

	useEffect(() => {
		if (!init && !channelList)
			dispatch(fetchLatestSensorList(config.remoteUrl.baseUrl));
		setInit(true);
	}, [fetchLatestSensorList]);

	const handelOnChange = (
		event: React.ChangeEvent<{}>,
		newValue: Channel | null,
	) => {
		// Create a new value from the user input
		if (newValue && newValue.displayName && newValue.id !== -1) {
			handelOnDataSourceChange(newValue);
			setValue({
				...newValue,
				displayName: newValue.displayName,
			});
			return;
		}

		if (newValue && newValue.id !== -1) {
			setValue(newValue);
		}
	};

	const handelOnBlur = (event: React.FocusEvent<HTMLInputElement>) => {
		if (handelOnDataSourceBlur) handelOnDataSourceBlur(event);
	};

	const getOptionLabel = (option: string | Channel): string => {
		// Value selected with enter, right from the input
		if (typeof option === 'string') {
			return option;
		}
		// Add "xxx" option created dynamically
		if (option.inputValue) {
			return option.inputValue;
		}
		// Regular option
		return option.displayName;
	};

	const filterOptions = (
		options: Channel[],
		params: FilterOptionsState<Channel>,
	) => {
		const filtered = filter(options, params) as Channel[];
		return filtered;
	};

	if (init && channelList && channelList.length > 0) {
		return (
			<>
				<div style={{ width: '100%', marginBottom: '24px' }}>
					<Autocomplete
						value={value}
						onChange={handelOnChange}
						filterOptions={filterOptions}
						selectOnFocus
						clearOnBlur
						handleHomeEndKeys
						id="free-solo-with-text-demo"
						options={channelList as Channel[]}
						getOptionLabel={getOptionLabel}
						renderOption={option => option.displayName}
						freeSolo
						renderInput={params => (
							<TextField
								{...params}
								error={Boolean(foundChannelError)}
								helperText={channelErrorText}
								onBlur={handelOnBlur}
								label="Data Source"
								variant="outlined"
							/>
						)}></Autocomplete>
				</div>
			</>
		);
	} else {
		const test: Channel[] = [
			{
				id: -1,
				displayName: 'Loading...',
				tagName: 'Loading...',
				assetID: -1,
				channelType: 1,
			},
		];
		return (
			<>
				<FormControl
					required
					variant="outlined"
					className="formControl">
					<InputLabel id="demo-simple-channel-outlined-label">
						Data Source
					</InputLabel>
					<Select
						labelId="demo-simple-channel-outlined-label"
						id="demo-simple-channel-outlined"
						label="Data Source">
						<MenuItem key={1} value={test[0].id}>
							{test[0].displayName}{' '}
							<Loading loading={true}></Loading>
						</MenuItem>
					</Select>
				</FormControl>
			</>
		);
	}
};

export default DataSourceInput;
