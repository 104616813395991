import Grid from '@material-ui/core/Grid';
import moment from 'moment';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AlertSnackbar from '../../Components/AlertSnackbar/AlertSnackbar';
import RecentWorkspaceList from '../../Components/Dashboard/RecentWorkspaceList';
import SystemLogList from '../../Components/Dashboard/SystemLogList';
import LatestAlertList from '../../Components/Dashboard/LatestAlertList';
import { useSiteConfig } from '../../Components/SiteConfiguration/SiteConfigProvider';
import {
	closeAlert,
	fetchRecentWorkspaces,
	fetchSystemLogs,
	fetchLatestAlerts,
} from '../../store/actions/dashboard';
import * as StyledHomePage from './Dashboard.styles';
import LatestAlertMap from '../../Components/Dashboard/LatestAlertMap';
import { RootState } from '../../store/types/root';

const DashboardPage = () => {
	const { config } = useSiteConfig();
	const dispatch = useDispatch();
	const { alert, recentWorkspaces, systemLogs } = useSelector(
		(state: RootState) => state.dashboard,
	);
	const { latestAlerts } = useSelector((state: RootState) => state.dashboard);
	const renderCurrentTime = () => {
		return moment().format('MMMM DD, YYYY');
	};

	useEffect(() => {
		dispatch(fetchRecentWorkspaces(config.remoteUrl.baseUrl));
		dispatch(fetchSystemLogs(config.remoteUrl.baseUrl));
	}, [fetchRecentWorkspaces, fetchSystemLogs]);

	useEffect(() => {
		dispatch(fetchLatestAlerts(config.remoteUrl.baseUrl));
	}, [fetchLatestAlerts]);

	return (
		<StyledHomePage.HomePage>
			<div>
				<StyledHomePage.DatetimeStampLabel></StyledHomePage.DatetimeStampLabel>
			</div>
			<Grid container spacing={3}>
				<Grid item xs={6}>
					<StyledHomePage.BorderlessTile>
						<StyledHomePage.DatetimeStampLabel>
							{renderCurrentTime()}
						</StyledHomePage.DatetimeStampLabel>
						<StyledHomePage.GreetingMessageLabel>
							Good Afternoon, Fiona
						</StyledHomePage.GreetingMessageLabel>
					</StyledHomePage.BorderlessTile>
				</Grid>
				<Grid item xs={6}>
					<StyledHomePage.BorderlessTileFloatRight>
						<StyledHomePage.SystemStatusLabel>
							System Status
						</StyledHomePage.SystemStatusLabel>
						<StyledHomePage.SystemStatusIndicator>
							HEALTHY
						</StyledHomePage.SystemStatusIndicator>
					</StyledHomePage.BorderlessTileFloatRight>
				</Grid>
				<Grid item xs={3}>
					<StyledHomePage.NewAlertSmallContainer>
						<StyledHomePage.AlertTileTile>
							Since Last Login
						</StyledHomePage.AlertTileTile>
						<StyledHomePage.AlertTileContent>
							8 New Alerts
						</StyledHomePage.AlertTileContent>
					</StyledHomePage.NewAlertSmallContainer>
				</Grid>
				<Grid item xs={3}>
					<StyledHomePage.UnresolvedAlertSmallContainer>
						<StyledHomePage.AlertTileTile>
							Unresolved Critical Alerts
						</StyledHomePage.AlertTileTile>
						<StyledHomePage.AlertTileContent>
							5 Critical Alerts
						</StyledHomePage.AlertTileContent>
					</StyledHomePage.UnresolvedAlertSmallContainer>
				</Grid>
				<Grid item xs={3}>
					<StyledHomePage.OutageSmallContainer>
						<StyledHomePage.AlertTileTile>
							Since Last Login
						</StyledHomePage.AlertTileTile>
						<StyledHomePage.AlertTileContent>
							0 New Outages Reported
						</StyledHomePage.AlertTileContent>
					</StyledHomePage.OutageSmallContainer>
				</Grid>
				<Grid item xs={6}>
					<StyledHomePage.LargeContainerTile>
						<LatestAlertList dataSource={latestAlerts} />
					</StyledHomePage.LargeContainerTile>
				</Grid>
				<Grid item xs={6}>
					<StyledHomePage.LargeContainerTile>
						<LatestAlertMap />
					</StyledHomePage.LargeContainerTile>
				</Grid>
				<Grid item xs={3}>
					<StyledHomePage.LargeContainerTile>
						<RecentWorkspaceList dataSource={recentWorkspaces} />
					</StyledHomePage.LargeContainerTile>
				</Grid>
				<Grid item xs={6}>
					<StyledHomePage.LargeContainerTile>
						<StyledHomePage.TileTitle>
							Daily Consumption
						</StyledHomePage.TileTitle>
					</StyledHomePage.LargeContainerTile>
				</Grid>
				<Grid item xs={3}>
					<StyledHomePage.LargeContainerTile>
						<SystemLogList dataSource={systemLogs} />
					</StyledHomePage.LargeContainerTile>
				</Grid>
			</Grid>
			<AlertSnackbar
				alert={alert}
				onClose={() => dispatch(closeAlert())}
			/>
		</StyledHomePage.HomePage>
	);
};

export default DashboardPage;
