import React from 'react';
import { ReactMap, SourceType } from '@innovyze/inno-map';
import { PropertyPane } from '@innovyze/ui';
import { useSiteConfig } from '../SiteConfiguration/SiteConfigProvider';
import { useAuth0 } from '../Auth0/Auth0';
import StyledMap from './LatestAlertMap.styles';

const LatestAlertMap = () => {
	const { config } = useSiteConfig();
	const { token } = useAuth0();

	return (
		<StyledMap>
			<ReactMap
				mapKey={config.mapbox.key || ''}
				dataServices={[
					{
						type: SourceType.STYLE,
						id: 'network',
						displayName: 'Network',
						url:
							'/iws-local/mo/cnn/cnn.3/mapboxstyle/thm.0/https%3A%2F%2Flocalhost%3A30003%2Fiws-local%2Fmo%2Fcnn%2Fcnn.3%2Fmaptile',
					},
					{
						type: SourceType.GEOJSON,
						id: 'pipes',
						displayName: 'Pipes',
						url:
							'https://innovyzecloud.com/api/sites/227/pipes.json',
						layerGrouping: [
							{
								filterBy: 'asset_id',
								displayName: 'Pipes',
								type: 'line',
							},
						],
					},
					{
						type: SourceType.GEOJSON,
						id: 'customer-points',
						displayName: 'Customer Points',
						url:
							'https://innovyzecloud.com/api/sites/227/customer-points.json',
					},
					{
						type: SourceType.GEOJSON,
						id: 'valves',
						displayName: 'Valves',
						url:
							'https://innovyzecloud.com/api/sites/227/valves.json',
					},
					{
						type: SourceType.GEOJSON,
						id: 'non-return-valves',
						displayName: 'Non-return Valves',
						url:
							'https://innovyzecloud.com/api/sites/227/non-return-valves.json',
					},
					{
						type: SourceType.GEOJSON,
						id: 'float-valves',
						displayName: 'Float Valves',
						url:
							'https://innovyzecloud.com/api/sites/227/float-valves.json',
					},
					{
						type: SourceType.GEOJSON,
						id: 'transfer-nodes',
						displayName: 'Transfer Nodes',
						url:
							'https://innovyzecloud.com/api/sites/227/transfer-nodes.json',
					},
					{
						type: SourceType.HARVI,
						id: 'assets',
						displayName: 'Schematic',
						url: config.harvi.api + 'restful/site/227/asset_geos/', // Hard-coded site for now.
						requestHeaders: [['emaginauth', `Bearer,${token}`]],
						layerGrouping: [
							{
								groupBy: 'asset_type_id',
								displayName: 'asset_type_name',
								type: 'symbol',
							},
							{
								filterBy: 'sensor_id',
								displayName: 'Sensors',
								type: 'symbol',
							},
						],
					},
				]}
				summaryPanel={<PropertyPane />}
			/>
		</StyledMap>
	);
};

export default LatestAlertMap;
