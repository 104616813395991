import React from 'react';
import './DashletGrid.css';
import { TextContent, ImgContentMainIcon } from './Dashlet.styles';
import addChartIcon from './icons/NewHistoricalChart.svg';
import addGaugeIcon from './icons/NewGaugeChart.svg';
import addMapIcon from './icons/NewMap.svg';

interface DashletGridViewProps {
	onChartClick: () => void;
	onGaugeClick: () => void;
	onMapClick: () => void;
}

class DashletGridView extends React.Component<DashletGridViewProps> {
	constructor(props: DashletGridViewProps) {
		super(props);
	}

	render() {
		const { onChartClick, onGaugeClick, onMapClick } = this.props;
		return (
			<div id="dashletGridContainer">
				<div
					className="ibox"
					onClick={onChartClick}
					onKeyDown={onChartClick}
					tabIndex={0}
					role="button">
					<div className="product-box">
						<ImgContentMainIcon>
							<img src={addChartIcon} alt="dashlet chart img" />
						</ImgContentMainIcon>
						<TextContent>New Historical Chart</TextContent>
					</div>
				</div>

				<div
					className="ibox"
					style={{ marginLeft: '38.4px' }}
					onClick={onGaugeClick}
					onKeyDown={onGaugeClick}
					tabIndex={0}
					role="button">
					<div className="product-box">
						<ImgContentMainIcon>
							<img src={addGaugeIcon} alt="dashlet gauge img" />
						</ImgContentMainIcon>
						<TextContent>New Gauge Chart</TextContent>
					</div>
				</div>

				<div
					className="ibox"
					onClick={onMapClick}
					onKeyDown={onMapClick}
					tabIndex={0}
					role="button"
					style={{ marginTop: '30px' }}>
					<div className="product-box">
						<ImgContentMainIcon>
							<img src={addMapIcon} alt="dashlet map img" />
						</ImgContentMainIcon>
						<TextContent>New Map Widget</TextContent>
					</div>
				</div>
			</div>
		);
	}
}

export default DashletGridView;
