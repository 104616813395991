import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import ArrowBack from '@material-ui/icons/ArrowBack';
import { History } from 'history';
import * as QueryString from 'query-string';
import React, { PureComponent } from 'react';
import { Layout } from 'react-grid-layout';
import { connect } from 'react-redux';
import styled from 'styled-components';
import AlertSnackbar, {
	AlertInfo,
} from '../../Components/AlertSnackbar/AlertSnackbar';
import WorkspaceGrid from '../../Components/Info360/WorkspaceGrid/WorkspaceGrid';
import RouteLeavingGuard from '../../Components/RouteLeavingGuard';
import { SiteConfigContext } from '../../Components/SiteConfiguration/SiteConfigProvider';
import WarningDialog from '../../Components/WarningDialog';
import AddItemDrawer from '../../Components/Workspace/AddItemDrawer';
import SaveButtonWithMenu from '../../Components/Workspace/SaveButtonWithMenu';
import WorkspacePropertiesDrawer from '../../Components/Workspaces/WorkspacePropertiesDrawer';
import {
	addItemDrawerActions,
	addWorkspaceItem,
	closeAlert,
	deleteWorkspace,
	drawerCancelClicked,
	drawerDescriptionBlurred,
	drawerNameBlurred,
	fetchWorkspace,
	initDashletItemToFocusMode,
	initDashletItemToWorkspace,
	openSettingsDrawer,
	removeWorkspaceItem,
	saveWorkspace,
	saveWsItemsToWorkspace,
	updateDashletItemToWorkspace,
	workspaceLayoutChanged,
} from '../../store/actions/workspace';
import { RootState } from '../../store/types/root';
import {
	ChartDashletOptions,
	DashletType,
	Display,
	GaugeDashletOptions,
	MapDashletOptions,
	SidePanelContent,
	SidePanelOption,
	Workspace,
	WorkspaceError,
	WorkspaceGridMode,
	WorkspaceItem,
} from '../../types/workspace';
import Loading from '../../Components/Loading/Loading';
import { defaultWsItem } from '../../Components/Info360/WorkspaceGrid/WorkspaceGridService';

interface State {
	deleteConfirmationOpen: boolean;
	cancelConfirmationOpen: boolean;
}

export interface Props {
	alert?: AlertInfo;
	deleted: boolean;
	errors: Array<WorkspaceError>;
	fetching: boolean;
	history: History;
	isDirty: boolean;
	location: { search: string };
	match: { params: { id: string } };
	settingsDrawerOpen: boolean;
	settingsDrawerWorkspace: Workspace;
	workspace: Workspace;
	sidePanelOption: SidePanelOption;
	activeWorkspaceItem: WorkspaceItem;
	closeAlert: () => void;
	deleteWorkspace: (id: number, baseUrl: string) => void;
	drawerCancelClicked: () => void;
	drawerDescriptionBlurred: (value: string) => void;
	drawerNameBlurred: (value: string) => void;
	fetchWorkspace: (id: number, baseUrl: string) => void;
	openSettingsDrawer: () => void;
	saveWorkspace: (workspace: Workspace, baseUrl: string) => void;
	workspaceLayoutChanged: (newLayout: Array<Layout>) => void;
	addWorkspaceItem: (wsItemToAdd: WorkspaceItem) => void;
	initDashletItemToWorkspace: (
		baseUrl: string,
		wsItemToAdd: WorkspaceItem,
	) => void;
	removeWorkspaceItem: (itemIndex: number) => void;
	updateDashletItemToWorkspace: (
		baseUrl: string,
		wsItemToUpdate: WorkspaceItem,
	) => void;
	saveWsItemsToWorkspace: (
		dashlets: WorkspaceItem[],
		baseUrl: string,
	) => void;
	addItemDrawerActions: (sidePanelOption: SidePanelOption) => void;
	initDashletItemToFocusMode: (
		baseUrl: string,
		wsItemToAdd: WorkspaceItem,
	) => void;
}

//Component
export class WorkspacePage extends PureComponent<Props, State> {
	static contextType = SiteConfigContext;
	workspaceId = Number(this.props.match.params.id);
	printView =
		QueryString.parse(this.props.location.search).printView === 'true';

	constructor(props: Props) {
		super(props);
		this.state = {
			deleteConfirmationOpen: false,
			cancelConfirmationOpen: false,
		};

		this.handelAddWSItemToWorkspace = this.handelAddWSItemToWorkspace.bind(
			this,
		);
		this.handelDeleteWSItemToWorkspace = this.handelDeleteWSItemToWorkspace.bind(
			this,
		);
		this.handelUpdateDashletOption = this.handelUpdateDashletOption.bind(
			this,
		);

		this.handelInitWSItemToWorkspace = this.handelInitWSItemToWorkspace.bind(
			this,
		);

		this.handelSaveWsItemsToWorkspace = this.handelSaveWsItemsToWorkspace.bind(
			this,
		);

		this.handelItemDrawerAction = this.handelItemDrawerAction.bind(this);

		this.handelMenuChangeFromFocus = this.handelMenuChangeFromFocus.bind(
			this,
		);

		this.handelSwitchToFocusMode = this.handelSwitchToFocusMode.bind(this);

		this.handelHideSidePanelBlock = this.handelHideSidePanelBlock.bind(
			this,
		);

		this.handelInitWSItemToFocus = this.handelInitWSItemToFocus.bind(this);
	}

	componentDidMount() {
		this.props.fetchWorkspace(
			this.workspaceId,
			this.context.config.remoteUrl.baseUrl,
		);
	}

	componentDidUpdate() {
		if (this.props.deleted) {
			this.props.history.push('/workspaces');
		}
	}

	handelDeleteWSItemToWorkspace(itemIndex: number): void {
		this.props.removeWorkspaceItem(itemIndex);
	}

	handelUpdateDashletOption(wsItemToUpdate: WorkspaceItem): void {
		this.props.updateDashletItemToWorkspace(
			this.context.config.remoteUrl.baseUrl,
			wsItemToUpdate,
		);
	}

	handelInitWSItemToWorkspace(wsItemToInit: WorkspaceItem): void {
		this.props.initDashletItemToWorkspace(
			this.context.config.remoteUrl.baseUrl,
			wsItemToInit,
		);
	}

	handelInitWSItemToFocus(wsItemToInit: WorkspaceItem): void {
		this.props.initDashletItemToFocusMode(
			this.context.config.remoteUrl.baseUrl,
			wsItemToInit,
		);
	}

	handelAddWSItemToWorkspace(
		option: ChartDashletOptions | GaugeDashletOptions | MapDashletOptions,
	) {
		const getWidgetItemToAdd = (): WorkspaceItem => {
			let width;
			let height;
			switch (option.dashletTypeId) {
				case DashletType.Chart:
					width = 8;
					height = 4;
					break;
				case DashletType.Gauge:
					width = 4;
					height = 4;
					break;
				default:
					width = 4;
					height = 4;
			}
			let columnPosition = 0;
			let rowPosition = 0;
			const workspaceItem = this.props.workspace.workspaceItem;
			if (workspaceItem && workspaceItem.length > 0) {
				const item = workspaceItem[workspaceItem.length - 1];
				if (item.width + width + 1 > 12) {
					rowPosition = item.rowPosition + 1;
					columnPosition = item.columnPosition;
				} else {
					rowPosition = item.rowPosition + 1;
					columnPosition = item.columnPosition;
				}
				if (workspaceItem.length > 1) {
					const item = workspaceItem[workspaceItem.length - 2];
					if (item.width + width <= 11) {
						rowPosition = item.rowPosition + item.width;
						columnPosition = item.columnPosition;
					} else {
						rowPosition = item.rowPosition + item.width;
						columnPosition = item.columnPosition;
					}
				}
			}
			let newId = 1;
			if (workspaceItem && workspaceItem.length > 0) {
				newId = Math.max(...workspaceItem.map(item => item.id)) + 1;
			}
			const workspaceItemToAdd: WorkspaceItem = {
				id: newId,
				rowPosition,
				columnPosition,
				width,
				height,
				options: JSON.stringify(option),
				createBy: '',
				dashletId: 0,
				dashletTypeId: option.dashletTypeId,
				modifiedBy: '',
				status: '',
			};
			return workspaceItemToAdd;
		};

		const wsItemToAdd: WorkspaceItem = getWidgetItemToAdd();
		this.props.addWorkspaceItem(wsItemToAdd);
		setTimeout(() => {
			this.props.initDashletItemToWorkspace(
				this.context.config.remoteUrl.baseUrl,
				wsItemToAdd,
			);
		}, 500);
	}

	handelSaveWsItemsToWorkspace() {
		const dashlets = this.props.workspace.workspaceItem;
		if (dashlets && dashlets.length > 0) {
			this.props.saveWsItemsToWorkspace(
				dashlets,
				this.context.config.remoteUrl.baseUrl,
			);
		}
	}

	handelItemDrawerAction(sidePanelOption: SidePanelOption) {
		this.props.addItemDrawerActions(sidePanelOption);
	}

	handelMenuChangeFromFocus(displayMenuMode: Display) {
		const sidePanelOption = this.props.sidePanelOption;
		sidePanelOption.contentDisplayMode = displayMenuMode;
		this.handelItemDrawerAction(sidePanelOption);
	}

	handelHideSidePanelBlock(): void {
		const element: Element | null = document.querySelector(
			'div[role="presentation"]',
		);
		if (element != null) {
			let style = element.getAttribute('style');
			if (style != null) {
				style += 'width: 10px;';
				element.setAttribute('style', style);
			}

			const box: Element | null = element.querySelector(
				'div.MuiBackdrop-root',
			);
			if (box != null) {
				let style = box.getAttribute('style');
				if (style != null) {
					style += 'width: 10px;';
					box.setAttribute('style', style);
				}
			}
		}
	}

	handelSwitchToFocusMode(wsItem: WorkspaceItem) {
		const sidePanelOption = this.props.sidePanelOption;

		sidePanelOption.addItemDrawerOpen = true;
		sidePanelOption.action = WorkspaceGridMode.UPDATE_TO_WORKSPACE;
		new Promise(resolve => {
			if (wsItem.dashletTypeId === DashletType.Chart) {
				sidePanelOption.contentDisplayMode = Display.Properties;
				sidePanelOption.displayMode = SidePanelContent.CreateChart;
				const chartOption: ChartDashletOptions = JSON.parse(
					wsItem.options,
				);
				sidePanelOption.gaugeFormData = undefined;
				sidePanelOption.mapFormData = undefined;
				sidePanelOption.chartFormData = {
					channelId: parseInt(chartOption.sensorId),
					name: chartOption.name,
					description: chartOption.description,
					chartType: chartOption.chartType,
					series: chartOption.series,
				};
				this.handelInitWSItemToFocus(wsItem);
				resolve();
			} else if (wsItem.dashletTypeId === DashletType.Gauge) {
				sidePanelOption.contentDisplayMode = Display.Properties;
				sidePanelOption.displayMode = SidePanelContent.CreateGauge;
				const gaugeOption: GaugeDashletOptions = JSON.parse(
					wsItem.options,
				);
				sidePanelOption.mapFormData = undefined;
				sidePanelOption.chartFormData = undefined;
				sidePanelOption.gaugeFormData = {
					channelId: parseInt(gaugeOption.sensorId),
					name: gaugeOption.name,
					description: gaugeOption.description,
					gaugeType: gaugeOption.chartType,
					needleColor: gaugeOption.color,
					thresholdTo: gaugeOption.thresholdTo,
					thresholdFrom: gaugeOption.thresholdFrom,
					showLast24Hr: gaugeOption.showLast24Hr
						? gaugeOption.showLast24Hr
						: true,
				};
				this.handelInitWSItemToFocus(wsItem);
				resolve();
			} else if (wsItem.dashletTypeId === DashletType.Map) {
				sidePanelOption.contentDisplayMode = Display.Properties;
				sidePanelOption.displayMode = SidePanelContent.CreateMap;
				const mapOption: MapDashletOptions = JSON.parse(wsItem.options);
				sidePanelOption.gaugeFormData = undefined;
				sidePanelOption.chartFormData = undefined;
				sidePanelOption.mapFormData = {
					name: mapOption.name,
					description: mapOption.description,
				};
				this.handelInitWSItemToFocus(wsItem);
				resolve();
			} else {
				sidePanelOption.contentDisplayMode = Display.Properties;
				sidePanelOption.displayMode = SidePanelContent.MainGrid;
				sidePanelOption.gaugeFormData = undefined;
				sidePanelOption.chartFormData = undefined;
				sidePanelOption.mapFormData = undefined;
				resolve();
			}
		}).then(() => {
			this.handelItemDrawerAction(sidePanelOption);
			this.handelHideSidePanelBlock();
		});
	}

	render() {
		// Handlers
		const handleAddItemButton = () => {
			const sidePanelOption = this.props.sidePanelOption;
			sidePanelOption.addItemDrawerOpen = true;
			sidePanelOption.displayMode = SidePanelContent.MainGrid;
			this.handelItemDrawerAction(sidePanelOption);
		};
		const handleAddItemDrawerCancel = () => {
			const { sidePanelOption } = this.props;
			if (
				sidePanelOption.action === WorkspaceGridMode.UPDATE_TO_WORKSPACE
			) {
				this.setState({ cancelConfirmationOpen: true });
			} else {
				sidePanelOption.addItemDrawerOpen = false;
				sidePanelOption.displayMode = SidePanelContent.MainGrid;
				sidePanelOption.gaugeFormData = undefined;
				sidePanelOption.mapFormData = undefined;
				sidePanelOption.chartFormData = undefined;
				this.handelItemDrawerAction(sidePanelOption);
			}
		};
		const handleAddItemDrawerOk = () => {
			const sidePanelOption = this.props.sidePanelOption;
			sidePanelOption.addItemDrawerOpen = false;
			sidePanelOption.displayMode = SidePanelContent.MainGrid;
			this.handelItemDrawerAction(sidePanelOption);
		};

		const handleDeleteConfirmYes = () => {
			this.props.deleteWorkspace(
				this.props.workspace.id,
				this.context.config.remoteUrl.baseUrl,
			);
			this.setState({ deleteConfirmationOpen: false });
		};

		const handleDeleteConfirmNo = () => {
			this.setState({ deleteConfirmationOpen: false });
		};

		const handleCancelConfirmNo = () => {
			this.setState({ cancelConfirmationOpen: false });
		};

		const handleCancelConfirmYes = () => {
			const sidePanelOption = this.props.sidePanelOption;

			if (
				sidePanelOption.action === WorkspaceGridMode.UPDATE_TO_WORKSPACE
			) {
				sidePanelOption.addItemDrawerOpen = false;
				sidePanelOption.displayMode = SidePanelContent.MainGrid;
				sidePanelOption.action = WorkspaceGridMode.ADD_TO_WORKSPACE;
				this.handelItemDrawerAction(sidePanelOption);
				this.setState({ cancelConfirmationOpen: false });
			}
		};

		const handleSaveClick = () => {
			this.handelSaveWsItemsToWorkspace();
		};

		const handleDeleteClick = () => {
			this.setState({ deleteConfirmationOpen: true });
		};

		const handlePrintViewClick = () => {
			const newPathname = `/workspace/${this.workspaceId}?printView=true`;
			window.open(
				this.props.history.createHref({
					pathname: newPathname,
				}),
			);
		};

		const handleSettingsClick = () => {
			this.props.openSettingsDrawer();
		};

		const handleDrawerOk = () => {
			this.props.saveWorkspace(
				this.props.settingsDrawerWorkspace,
				this.context.config.remoteUrl.baseUrl,
			);
		};

		const handleDrawerCancel = () => {
			this.props.drawerCancelClicked();
		};

		const handleItemLayoutChange = (layout: Layout[]) => {
			this.props.workspaceLayoutChanged(layout);
		};

		return (
			<Loading loading={this.props.fetching}>
				<WorkspaceContainer>
					{this.props.sidePanelOption.action ===
						WorkspaceGridMode.UPDATE_TO_WORKSPACE && (
						<WorkspaceBackTitle onClick={handleAddItemDrawerCancel}>
							<ArrowBack
								style={{
									fontSize: 10,
									color: 'rgba(0, 0, 0, 0.54)',
								}}
							/>
							WORKSPACE {this.props.workspace.name}
						</WorkspaceBackTitle>
					)}
					<TitleAndSaveContainer>
						<Title>{this.props.workspace.name}</Title>
						<SaveButtonWithMenu
							onSaveClick={handleSaveClick}
							onDeleteClick={handleDeleteClick}
							onPrintViewClick={handlePrintViewClick}
							onSettingsClick={handleSettingsClick}
						/>
					</TitleAndSaveContainer>
					<Description>
						{this.props.workspace.description}
					</Description>
					{!this.printView &&
						this.props.sidePanelOption.action ===
							WorkspaceGridMode.ADD_TO_WORKSPACE && (
							<Button
								color="primary"
								variant="contained"
								startIcon={<AddIcon />}
								onClick={handleAddItemButton}>
								ADD TO WORKSPACE
							</Button>
						)}

					<WorkspaceGrid
						workspace={this.props.workspace}
						onLayoutChange={handleItemLayoutChange}
						onDeleteWidgetHandler={
							this.handelDeleteWSItemToWorkspace
						}
						onUpdateDashletHandler={this.handelUpdateDashletOption}
						onInitDashletHandler={this.handelInitWSItemToWorkspace}
						sidePanelOption={this.props.sidePanelOption}
						activeWorkspaceItem={this.props.activeWorkspaceItem}
						handelMenuChangeFromFocus={
							this.handelMenuChangeFromFocus
						}
						handelSwitchToFocusMode={this.handelSwitchToFocusMode}
					/>
				</WorkspaceContainer>
				<AddItemDrawer
					onButtonCancel={handleAddItemDrawerCancel}
					onButtonOk={handleAddItemDrawerOk}
					sidePanelOption={this.props.sidePanelOption}
					activeWorkspaceItem={this.props.activeWorkspaceItem}
					handelAddWSItemToWorkspace={this.handelAddWSItemToWorkspace}
					handelItemDrawerAction={this.handelItemDrawerAction}
					handelUpdateDashletOption={this.handelUpdateDashletOption}
					handelInitWSItemToFocus={this.handelInitWSItemToFocus}
				/>
				<WorkspacePropertiesDrawer
					buttonOkDisabled={false}
					open={this.props.settingsDrawerOpen}
					workspace={this.props.workspace}
					workspaceErrors={this.props.errors}
					onButtonOk={handleDrawerOk}
					onButtonCancel={handleDrawerCancel}
					onNameBlur={event =>
						this.props.drawerNameBlurred(event.target.value)
					}
					onDescriptionBlur={event =>
						this.props.drawerDescriptionBlurred(event.target.value)
					}
				/>
				<WarningDialog
					open={this.state.cancelConfirmationOpen}
					titleText="Leaving without adding?"
					contentText="You have unsaved changes. Are you sure you want to leave this page without adding the chart?"
					confirmButtonText="Confirm"
					onConfirm={handleCancelConfirmYes}
					onCancel={handleCancelConfirmNo}
				/>
				<WarningDialog
					open={this.state.deleteConfirmationOpen}
					titleText="Delete Workspace?"
					contentText="Are you sure you want to delete this workspace?"
					confirmButtonText="Delete"
					onConfirm={handleDeleteConfirmYes}
					onCancel={handleDeleteConfirmNo}
				/>
				<AlertSnackbar
					alert={this.props.alert}
					onClose={() => this.props.closeAlert()}
				/>
				<RouteLeavingGuard
					when={this.props.isDirty}
					navigate={path => this.props.history.push(path)}
					// Use as "message" prop of Prompt of React-Router
					shouldBlockNavigation={() => this.props.isDirty}
				/>
			</Loading>
		);
	}
}

//Styles
const Title = styled.div`
	font-size: 1.5rem;
	font-weight: 600;
`;
const TitleAndSaveContainer = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
`;
const Description = styled.div`
	font-size: 0.875rem;
	color: #768d95;
	margin-bottom: 1rem;
`;
const WorkspaceContainer = styled.div`
	padding: 2rem 1.25rem;
`;

const WorkspaceBackTitle = styled.div`
	width: 149px;
	height: 14px;
	left: 113.54px;
	margin-bottom: 14px;
	font-style: normal;
	font-weight: normal;
	font-size: 10px;
	color: rgba(0, 0, 0, 0.54);
`;

//Map State
const mapStateToProps = (state: RootState) => {
	const { workspace } = state;
	return {
		alert: workspace.alert,
		deleted: workspace.deleted,
		errors: workspace.errors,
		fetching: workspace.fetching,
		isDirty: workspace.isDirty,
		settingsDrawerOpen: workspace.settingsDrawerOpen,
		settingsDrawerWorkspace: workspace.settingsDrawerWorkspace,
		workspace: workspace.workspace,
		sidePanelOption: workspace.sidePanelOption,
		activeWorkspaceItem: workspace.activeWorkspaceItem
			? workspace.activeWorkspaceItem
			: defaultWsItem,
	};
};

//Map Dispatch
const mapDispatchToProps = {
	closeAlert,
	deleteWorkspace,
	fetchWorkspace,
	workspaceLayoutChanged,
	saveWorkspace,
	drawerCancelClicked,
	drawerDescriptionBlurred,
	drawerNameBlurred,
	openSettingsDrawer,
	addWorkspaceItem,
	initDashletItemToWorkspace,
	updateDashletItemToWorkspace,
	removeWorkspaceItem,
	saveWsItemsToWorkspace,
	addItemDrawerActions,
	initDashletItemToFocusMode,
};

//Export
export default connect(mapStateToProps, mapDispatchToProps)(WorkspacePage);
