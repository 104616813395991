import { createStyles, Grid, makeStyles, Theme } from '@material-ui/core';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import React from 'react';
import { Link } from 'react-router-dom';
import { AlertSeverity, Alert } from '../../types/alert';
import { getObjectTitle } from './utils';
const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: {
			height: '100%',
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'space-between',
			overflowY: 'auto',
		},
		title: {
			fontWeight: 600,
			fontSize: '1.125rem',
		},
		row: {
			alignItems: 'space-between',
		},
		alertNameGrid: {
			textOverflow: 'ellipsis',
			whiteSpace: 'nowrap',
			overflow: 'hidden',
		},
		alertNameLink: {
			color: theme.palette.common.black,
			textDecoration: 'none',
			fontSize: '1rem',
		},
		viewAll: {
			display: 'flex',
			alignItems: 'center',
			fontSize: '0.75rem',
			fontWeight: 600,
			textDecoration: 'none',
			color: theme.palette.primary.main,
		},
		viewAllText: {
			marginRight: theme.spacing(1),
		},
		viewAllIcon: {
			fontSize: '1rem',
		},
		severity1Label: {
			fontSize: '0.625rem',
			borderRadius: '0.125rem',
			color: '#B78400',
			border: '1px solid #EFB304',
			textAlign: 'center',
			textTransform: 'uppercase',
			padding: '0.125rem',
		},
		severity2Label: {
			fontSize: '0.625rem',
			borderRadius: '0.125rem',
			color: '#E66B45',
			border: '1px solid #E66B45',
			textAlign: 'center',
			textTransform: 'uppercase',
			padding: '0.125rem',
		},
		severity3Label: {
			fontSize: '0.625rem',
			borderRadius: '0.125rem',
			color: '#AE3C1B',
			border: '1px solid #AE3C1B',
			textAlign: 'center',
			textTransform: 'uppercase',
			padding: '0.125rem',
		},
	}),
);

interface Props {
	dataSource: Array<Alert>;
}

const LatestAlertList = ({ dataSource }: Props) => {
	const classes = useStyles();

	const getAlertSeverityTitle = (severity: AlertSeverity) => {
		switch (severity) {
			case AlertSeverity.Warning:
				return 'Warning';
			case AlertSeverity.High:
				return 'High';
			case AlertSeverity.Critical:
				return 'Critical';
		}
	};

	const getAlertSeverityClass = (severity: AlertSeverity) => {
		switch (severity) {
			case AlertSeverity.Warning:
				return classes.severity1Label;
			case AlertSeverity.High:
				return classes.severity2Label;
			case AlertSeverity.Critical:
				return classes.severity3Label;
		}
	};

	const getAlertAssetName = (primaryAssetID: number) => {
		return 'Asset-' + primaryAssetID;
	};

	const renderLatestAlerts = (recentAlert: Alert) => {
		const { id, name, displayName, primaryAssetID, priority } = recentAlert;
		return (
			<Grid container className={classes.row} key={id}>
				<Grid item xs={6} className={classes.alertNameGrid}>
					<Link className={classes.alertNameLink} to={`alert/${id}`}>
						{getObjectTitle(name, displayName)}
					</Link>
				</Grid>
				<Grid item xs={4} className={classes.alertNameLink}>
					<span>{getAlertAssetName(primaryAssetID)}</span>
				</Grid>
				<Grid item xs={2}>
					<span className={getAlertSeverityClass(priority)}>
						{getAlertSeverityTitle(priority)}
					</span>
				</Grid>
			</Grid>
		);
	};

	return (
		<div className={classes.container}>
			<div className={classes.title}>Latest Alerts</div>
			{dataSource.map(latestAlerts => renderLatestAlerts(latestAlerts))}
			<Link className={classes.viewAll} to="/alerts">
				<span className={classes.viewAllText}>View all</span>
				<ArrowForwardIcon className={classes.viewAllIcon} />
			</Link>
		</div>
	);
};

export default LatestAlertList;
