/// <reference types="geojson" />
import { Sources, Layer } from 'mapbox-gl';

export interface ServiceLayer extends Layer {
	displayName?: string;
	cluster?: boolean;
	icon?: string;
	color?: string;
}

export interface StyleDataType {
	sources: Sources;
	layers: ServiceLayer[];
	sprite?: string;
	sprites?: string[];
}

export type Coordinates = GeoJSON.Position;

export interface Properties {
	[key: string]: string | number | Properties[] | undefined;
}

export interface Features {
	geometry: GeoJSON.Geometry;
	properties: Properties;
	type?: string;
}

export interface HarviResponseWrapper<T> {
	content: T;
	status_code: number;
	message: string;
}

export interface GeoJsonDataType {
	type: string;
	features: Features[];
}

export type DataType = StyleDataType | GeoJsonDataType | GeoJsonDataType[];
export type DataLikeType = Partial<StyleDataType & GeoJsonDataType>;
export enum SourceType {
	STYLE = 'style',
	GEOJSON = 'geojson',
	HARVI = 'harvi',
}
export type Service = DataType | string | undefined;

export type Either<T, U = { [K in keyof T]: Pick<T, K> }> = Partial<T> &
	U[keyof U];

export type LayerGrouping = {
	displayName: string;
} & Pick<Layer, 'type'> &
	Either<{
		groupBy: string;
		filterBy: string;
	}>;

export type DataServiceType = {
	type: SourceType;
	id: string;
	displayName: string;
	requestHeaders?: string[][];
	layerGrouping?: LayerGrouping[];
} & Either<{
	url: string;
	data: DataType;
}>;

export interface ServiceError {
	error: string;
	status?: string | number;
	statusText?: string;
}

export interface ServiceLoadIcon {
	id: string;
}
