import mapboxgl, {
	PointLike,
	MapMouseEvent,
	RequestParameters,
	LngLatLike,
	LngLat,
	LngLatBoundsLike,
	LngLatBounds,
	GeoJSONSource,
	IControl,
} from 'mapbox-gl';

import defineElement from './defineElement';
import ShadowElement from './ShadowElement';
import {
	DataServiceType,
	SourceType,
	GeoJsonDataType,
	StyleDataType,
	ServiceError,
	ServiceLoadIcon,
} from './services/types';
import BackgroundControl, {
	BackgroundChangeEvent,
	Background,
} from './BackgroundControl';

import styles from './styles/map.scss';
import ServiceManger, { ServicesType } from './services/ServiceManager';
import SymbolLoader, { SymbolCallback } from './symbols/SymbolLoader';
import LayerManager, {
	DataLayer,
	MapHiddenLayers,
} from './layers/LayerManager';
import { deserialiseObjectValues, normalizeCoordinates, dedupe } from './utils';
import { CompositeLayerProps } from './layers/CompositeLayer';
import { BackgroundRegistry } from './BackgroundRegistry';
import { MapModes } from './MapModes';
import { MapProps } from '../types';

const PROXY_SERVER = process.env.PROXY_SERVER;
const SERVER = process.env.SERVER;

type MapElement = HTMLElement;

export interface FeatureItems {
	[name: string]: unknown;
}
export interface FeatureProperties {
	[name: string]: string;
}
interface SelectedLayerIds {
	[key: string]: string[];
}

type MapBounds = number[][];

type MapSelectedAssets = string[];

@defineElement('inno-map')
export default class Map extends ShadowElement
	implements
		Required<Omit<MapProps, 'background' | 'mapKey' | 'mode' | 'panel'>> {
	private static readonly BackgroundAttributeName = 'background';
	private static readonly MapKeyAttributeName = 'mapKey';
	private static readonly ModeAttributeName = 'mode';
	private static readonly PanelAttributeName = 'panel';
	private static readonly MapPaddingTop = 50;
	private static readonly MapPaddingRight = 50;
	private static readonly MapPaddingLeft = 50;
	private static readonly MapPaddingBottom = 50;
	private static readonly MapPaddingLeftPanelOpen = 500;
	private static readonly MapPaddingRightPanelOpen = 370;

	private _container: MapElement;
	private _map: mapboxgl.Map | null = null;
	private _serviceManager = new ServiceManger();
	private _mapLoaded = false;
	private _layerManager: LayerManager | null = null;
	private _backgroundControl: BackgroundControl | null = null;
	private _background: Background | null = null;
	private _bounds: LngLatBounds | null = null;
	private _boundsSetExternally = false;
	private _selectedAssetIds: string[] = [];
	private _selectAssets = false;
	private _propertyPanelEnabled = false;
	private _propertyPanelOpen = false;
	private _hiddenLayers: MapHiddenLayers = [];

	get baseMap(): string | undefined {
		return this._background?.uri;
	}

	constructor() {
		super();
		this._container = document.createElement('div');
		this._container.id = 'root';
		this.rootElement = this._container;
		this.addStyles(styles);
		this.attachElement();
	}

	connectedCallback(): void {
		this.init();
	}

	static get observedAttributes(): string[] {
		return [Map.BackgroundAttributeName];
	}

	private set selectedAssetIds(assetIds: string[]) {
		this._selectedAssetIds = assetIds.sort();
	}

	private get selectedAssetIds(): string[] {
		return this._selectedAssetIds;
	}

	attributeChangedCallback(
		name: string,
		_oldValue: string,
		newValue: string,
	): void {
		switch (name) {
			case Map.BackgroundAttributeName: {
				this._backgroundControl?.setBackground(newValue);
			}
		}
	}

	init(): void {
		mapboxgl.accessToken = this.getAttribute(Map.MapKeyAttributeName) || '';
		const backgroundKey =
			this.getAttribute(Map.BackgroundAttributeName) || '';
		this._background = BackgroundRegistry.getStyleWithFallback(
			backgroundKey,
		);

		this._propertyPanelEnabled =
			this.getAttribute(Map.PanelAttributeName) === 'true';

		const displayMode =
			this.getAttribute(Map.ModeAttributeName) || MapModes.Full;

		this._map = new mapboxgl.Map({
			container: this._container,
			zoom: 11.545763435795546,
			style: this._background.uri,
			interactive: displayMode !== MapModes.Static,
			transformRequest: (url: string): RequestParameters => {
				// rewrite the absolute url returned from proxy server
				// to use server for CORS
				if (PROXY_SERVER && SERVER) {
					if (url.match(PROXY_SERVER)) {
						url = url.replace(PROXY_SERVER, SERVER);
					}
				}
				// can only include credentials if not CORS
				const credentials = url.match(window.location.host)
					? 'include'
					: undefined;
				return {
					url,
					credentials,
				};
			},
		});

		// deals with loading the symbol/icons for the map
		const symbolLoader = new SymbolLoader();
		const symbolLoaded: SymbolCallback = (image, id) => {
			// if the image is now in the map but wasn't when the event
			// first fired then don't add again to the map
			if (!this._map?.hasImage(id)) this._map?.addImage(id, image);
		};

		this._layerManager = new LayerManager(this._map);

		this._backgroundControl = new BackgroundControl({
			currentStyle: this._background,
			symbolLoader,
		});

		if (displayMode !== MapModes.Static) {
			this._addDefaultVisualControls([this._backgroundControl]);

			// Allow features to be selected in non-static mode.
			this._map.on('click', this.mapClick);
		}

		this._map.on('moveend', this._moveEnd);

		// event fired when styles have loaded or changed
		this._map.on('styledata', () => {
			this.updateMap(false);
		});

		// the this._map.loaded function doesn't
		// seem to work after the style has been changed
		// so tracking the loaded state here
		this._map.on('load', () => {
			this._mapLoaded = true;
			this.updateMap();
		});

		this._map.on('styledataloading', () => {
			this._mapLoaded = false;
		});

		this._map.on('style.load', () => {
			symbolLoader.loadAll(symbolLoaded);
			this._mapLoaded = true;
		});

		// load missing icons from a specified url
		this._map.on('styleimagemissing', ({ id }) => {
			// don't load the image if no id
			if (!id) return;
			// no point drawing image if it already exists
			if (this._map?.hasImage(id)) return;
			// returning image as a buffer as this loads quicker
			// than using a HTMLElement with base64 image
			// the issue is documentated here:
			// https://github.com/mapbox/mapbox-gl-js/issues/8335
			symbolLoader.getBuffer(id, symbolLoaded);
		});

		this._backgroundControl.on('backgroundChange', this.backgroundChange);

		// update the map once the service data has been loaded
		this._serviceManager.on('service-loaded', () => {
			this.updateMap();
		});

		// listen for service errors
		this._serviceManager.on(
			'service-error',
			({ error, status, statusText }: ServiceError) => {
				// TODO: something sensible with this error
				console.warn(error, ' - ', status, statusText);
			},
		);

		this._serviceManager.on('load-icon', ({ id }: ServiceLoadIcon) => {
			// don't load the image if no id
			if (!id) return;
			// no point drawing image if it already exists
			if (this._map?.hasImage(id)) return;
			symbolLoader.getImage(id, symbolLoaded);
			symbolLoader.getImage(`${id}-selected`, symbolLoaded);
		});

		this._layerManager.on('layers-loaded', () => {
			this._layerManager?.setLayerVisibility(this._hiddenLayers);
			if (this._layerManager?.layers && this._backgroundControl) {
				this._backgroundControl.layers = this._layerManager.layers;
			}
		});

		this._map.on('idle', () => {
			this.findSelectedFeatures();
		});

		this._backgroundControl.on('layer-toggle', this.layerVisibilityChange);
	}

	fitBoundsFromCoords(coordinates: LngLatLike[]): void {
		const bounds = coordinates.reduce(
			// the type definitions for bounds.extend appear to be wrong
			// found this usage from the example found here:
			// https://docs.mapbox.com/mapbox-gl-js/example/zoomto-linestring/
			(bounds, coord) => bounds.extend((coord as unknown) as LngLat),
			new mapboxgl.LngLatBounds(coordinates[0], coordinates[0]),
		);

		const padding = {
			top: Map.MapPaddingTop,
			bottom: Map.MapPaddingBottom,
			left: this.propertyPaneOpen()
				? Map.MapPaddingLeftPanelOpen
				: Map.MapPaddingLeft,
			right: this.layerPanelOpen()
				? Map.MapPaddingRightPanelOpen
				: Map.MapPaddingRight,
		};

		this._map?.fitBounds(bounds, { padding, duration: 0, maxZoom: 15 });
	}

	propertyPaneOpen(): boolean {
		return this._propertyPanelEnabled && this._propertyPanelOpen;
	}

	layerPanelOpen(): boolean {
		return !!this._backgroundControl?.panelOpen;
	}

	backgroundChange = ({ background }: BackgroundChangeEvent): void => {
		this._background = background;
		this.rootElement?.dispatchEvent(
			new CustomEvent('background', {
				bubbles: true,
				composed: true,
				detail: background,
			}),
		);
	};

	setDataServices(dataServices: DataServiceType[]): void {
		this._serviceManager.deleteAll();
		this._serviceManager.addMany(dataServices);
	}

	addDataService(dataService: DataServiceType): void {
		this._serviceManager.addOne(dataService);
	}

	getDataServices(): ServicesType[] {
		return this._serviceManager.getAll();
	}

	setBounds(bounds: MapBounds): void {
		const lngLatBounds = this._convertBounds(bounds);
		if (lngLatBounds && this._boundsChanged(bounds)) {
			this._bounds = lngLatBounds;
			this._boundsSetExternally = true;
			this._map?.fitBounds(bounds as LngLatBoundsLike, { duration: 0 });
		}
	}

	private _boundsChanged(bounds: MapBounds): boolean {
		if (!this._bounds) return true;
		const currentBounds = this._bounds.toArray()?.flat();

		if (!currentBounds) return true;

		return !!bounds.flat().filter((v, i) => currentBounds[i] !== v).length;
	}

	private _convertBounds(
		bounds: MapBounds | undefined,
	): LngLatBounds | undefined {
		if (!bounds) return;
		try {
			return mapboxgl.LngLatBounds.convert(bounds as LngLatBoundsLike);
		} catch (e) {
			console.warn('Invalid bounds supplied');
			return;
		}
	}

	setSelectedAssets(selectedAssetIds: MapSelectedAssets): void {
		if (!this._assetsChanged(selectedAssetIds)) return;
		this.selectedAssetIds = selectedAssetIds;
		this._selectAssets = true;
		this.findSelectedFeatures();
	}

	private _assetsChanged(assets: MapSelectedAssets): boolean {
		if (this.selectedAssetIds && !assets) return true;
		if (!this.selectedAssetIds && assets) return true;
		if (this.selectedAssetIds.length !== assets.length) return true;
		return !!assets.sort().filter((v, i) => this.selectedAssetIds[i] !== v)
			.length;
	}

	setHiddenLayers(hiddenLayers: MapHiddenLayers): void {
		this._hiddenLayers = hiddenLayers;
		this._layerManager?.setLayerVisibility(hiddenLayers);
	}

	updateMap(fitToExtents = true): void {
		// cannot update the map while it is loading
		if (!this._mapLoaded) return;

		const styleServices = this._serviceManager.getByType([
			SourceType.STYLE,
		]);
		const geoJsonServices = this._serviceManager.getByType([
			SourceType.GEOJSON,
			SourceType.HARVI,
		]);
		const mapboxServiceStyles = styleServices
			.map(service => service.data as StyleDataType)
			.filter(Boolean)
			.reduce(
				(
					{ sources, layers = [], sprites = [] },
					{
						sources: cSources,
						layers: cLayers = [],
						sprite: cSprite = '',
					},
				) => ({
					sprites: [...sprites, cSprite],
					sources: { ...sources, ...cSources },
					layers: [...layers, ...cLayers],
				}),
				{
					sources: {},
					layers: [],
					sprites: [],
				},
			);

		// add each source to the map
		Object.keys(mapboxServiceStyles.sources).map(sourceId => {
			const source = mapboxServiceStyles.sources[sourceId];
			if (!this._map?.getSource(sourceId)) {
				this._map?.addSource(sourceId, source);
			}
		});

		this._layerManager?.addMany(
			mapboxServiceStyles.layers as CompositeLayerProps[],
		);

		// load the GeoJSON layers
		geoJsonServices.map(async service => {
			const { layers, dataSources, loaded } = service;
			if (!loaded) return;
			dataSources?.map(({ id, source }) => {
				if (!this._map?.getSource(id)) {
					this._map?.addSource(id, source);
				}
			});
			this._layerManager?.addMany(
				(layers as CompositeLayerProps[]) ?? [],
			);
		});

		if (fitToExtents && !this._bounds) {
			this._fitToExtents();
		}
	}

	private _addDefaultVisualControls(customControls: IControl[] = []): void {
		if (!this._map) {
			return;
		}

		this._map.addControl(
			new mapboxgl.NavigationControl({ showCompass: false }),
		);

		this._map.addControl(
			new mapboxgl.GeolocateControl({
				positionOptions: {
					enableHighAccuracy: true,
				},
				trackUserLocation: true,
			}),
		);

		this._map.addControl(
			new mapboxgl.ScaleControl({
				maxWidth: 80,
				unit: 'imperial',
			}),
		);

		customControls.map(c => {
			if (c) {
				this._map?.addControl(c);
			}
		});
	}

	private _fitToExtents(): void {
		const geoJsonServices = this._serviceManager.getByType([
			SourceType.GEOJSON,
			SourceType.HARVI,
		]);
		// currently only displaying geoJSON on the map so
		// will need to update this once properly
		// supporting other service types
		// NB: for style services with the source types: vector, raster and raster-dem
		// can support `bounds` properties:
		// https://docs.mapbox.com/mapbox-gl-js/style-spec/sources/#vector-bounds
		const coordinates = geoJsonServices
			.map(service => service.data as GeoJsonDataType)
			.map(data =>
				data?.features?.map(({ geometry }) =>
					// GeometryCollection doesn't have coordinates - will ignore for now
					'coordinates' in geometry ? geometry.coordinates : [],
				),
			)
			.filter(Boolean);

		if (coordinates.length) {
			const normalised = normalizeCoordinates(
				coordinates,
			) as LngLatLike[];

			this.fitBoundsFromCoords(normalised);
		}
	}

	mapClick = (e: MapMouseEvent): void => {
		const {
			point: { x, y },
		} = e;
		const bbox: [PointLike, PointLike] = [
			[x - 5, y - 5],
			[x + 5, y + 5],
		];

		const clusters = this._map?.queryRenderedFeatures(bbox, {
			layers: this._layerManager?.clusterLayerIds,
		});

		if (clusters?.length) {
			this.zoomToCluster(clusters);
			return;
		}

		const features = this._map?.queryRenderedFeatures(bbox, {
			layers: this._layerManager?.layerIds,
		});
		this.selectFeature(features);
	};

	zoomToCluster(clusters: mapboxgl.MapboxGeoJSONFeature[]): void {
		if (clusters?.length) {
			const cluster = clusters?.[0];
			const clusterId = cluster.properties?.cluster_id;
			const source = cluster.source;
			if (clusterId && source) {
				(this._map?.getSource(
					source,
				) as GeoJSONSource).getClusterExpansionZoom(
					clusterId,
					(err, zoom) => {
						if (err) return;
						this._map?.easeTo({
							center: (cluster.geometry as GeoJSON.Point)
								.coordinates as mapboxgl.CameraOptions['center'],
							zoom,
						});
					},
				);
			}
		}
	}

	zoomToFeatures(
		features: mapboxgl.MapboxGeoJSONFeature[] | undefined,
	): void {
		if (!features?.length) return;

		const coordinates = features
			?.map(({ geometry }) =>
				// GeometryCollection doesn't have coordinates - will ignore for now
				'coordinates' in geometry ? geometry.coordinates : [],
			)
			.filter(coords => coords.length);

		if (coordinates.length) {
			const normalised = normalizeCoordinates([
				coordinates,
			]) as LngLatLike[];

			this.fitBoundsFromCoords(normalised);
		}
	}

	findSelectedFeatures(): void {
		if (
			!this.selectedAssetIds ||
			!this._selectAssets ||
			!this._layerManager?.layerIds.length
		)
			return;
		const features = this._layerManager?.layerIds.flatMap(layerId =>
			this._findAssetsInLayer(layerId, this.selectedAssetIds),
		) as mapboxgl.MapboxGeoJSONFeature[];

		// prevent selecting assets multiple times
		this._selectAssets = false;

		this.selectFeature(features, !this._boundsSetExternally);
	}

	private _findAssetsInLayer(
		layerId: string,
		assetIds: string[],
	): mapboxgl.MapboxGeoJSONFeature[] | undefined {
		return this._map
			?.querySourceFeatures(layerId, {
				filter: ['in', 'id', ...assetIds],
			})
			.map(feature => ({
				...feature,
				// getters don't spread so manually adding
				geometry: feature.geometry,
				layer: {
					id: layerId,
				},
			}));
	}

	selectFeature(
		features: mapboxgl.MapboxGeoJSONFeature[] | undefined,
		zoomToSelected = false,
	): void {
		this._layerManager?.clearSelectedLayers();

		if (!features?.length) {
			this.selectedAssetIds = [];
			this.selectedAssetsChanged([]);
			this._propertyPanelOpen = false;
			return;
		}

		// filter out features that are not from the sources and
		// ignore anything outside of the properties
		const dataSourceFeatures = features
			.map(({ properties }) => properties as FeatureProperties)
			// filter out items containing no properties
			.filter(properties => Object.keys(properties).length)
			// nested values are stringified when going through map
			// so need to convert them back to objects
			.map(deserialiseObjectValues)
			.map(({ id, ...other }) => ({
				...other,
				id: `${id}`,
			}));

		const selectedFeatures = dedupe(dataSourceFeatures, 'id').sort(
			(a, b) => {
				if (a.id < b.id) return -1;
				if (a.id > b.id) return 1;
				return 0;
			},
		);

		this.selectedAssetIds = selectedFeatures.map(({ id }) => id);
		this.selectedAssetsChanged(selectedFeatures as FeatureItems[]);
		this._propertyPanelOpen = true;

		const selected = features
			.map(({ properties, layer }) => ({
				id: properties?.id,
				layerId: layer.id,
			}))
			.reduce(
				(previous, { id, layerId }) => ({
					...previous,
					[layerId]: [...(previous[layerId] || []), id],
				}),
				{} as SelectedLayerIds,
			);

		Object.entries(selected).map(([layerId, ids]) => {
			this._layerManager?.setSelectedItemsOnLayer(layerId, ids);
		});

		if (zoomToSelected) {
			this.zoomToFeatures(features);
		}
	}

	selectedAssetsChanged(features: FeatureItems[] | undefined): void {
		this.rootElement?.dispatchEvent(
			new CustomEvent('selectedassets', {
				bubbles: true,
				composed: true,
				detail: features,
			}),
		);
	}

	_moveEnd = (): void => {
		const bounds = this._map?.getBounds();
		if (bounds) {
			this._bounds = bounds;
			this.rootElement?.dispatchEvent(
				new CustomEvent('bounds', {
					bubbles: true,
					composed: true,
					detail: bounds.toArray(),
				}),
			);
		}
	};

	layerVisibilityChange = ({ id }: DataLayer): void => {
		this._layerManager?.toggleLayer(id);
		const layeVisilibity = this._layerManager?.hiddenLayers;
		if (layeVisilibity) {
			this.rootElement?.dispatchEvent(
				new CustomEvent('hiddenlayers', {
					bubbles: true,
					composed: true,
					detail: layeVisilibity,
				}),
			);
		}
	};
}
