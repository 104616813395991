import { WorkspacesMode } from '../../../src/types/workspaces';
import { Action } from '../actions/workspaces';
import {
	CHECK_WORKING_WORKSPACE_ERRORS,
	CLOSE_ALERT,
	CREATE_WORKSPACE_FAILED,
	DRAWER_CANCEL_CLICKED,
	DRAWER_DESCRIPTION_BLURRED,
	DRAWER_NAME_BLURRED,
	FETCHING_WORKSPACES,
	FETCH_WORKSPACES_FAILED,
	FILTER_WORKSPACES,
	NEW_WORKSPACE_CLICKED,
	SEARCH_CANCELED,
	SEARCH_VALUE_CHANGED,
	SELECTION_CHANGED,
	WORKSPACES_FETCHED,
	WORKSPACE_CREATING,
	WORKSPACE_CREATED,
	DISPLAY_WORKSPACE_DELETED_INFO,
} from '../constants/workspaces';
import { WorkspacesState } from '../types/workspaces';
import { checkWorkspaceErrors } from '../utils/workspace';

export const initialWorkspacesState: WorkspacesState = {
	mode: WorkspacesMode.Fetching,
	newWorkspaceDrawerOpen: false,
	searchValue: '',
	workingWorkspace: {
		id: 0,
		name: '',
		description: '',
		ownerId: '',
		permission: 0,
		options: '',
		status: '',
		createBy: '',
		modifiedBy: '',
	},
	workingWorkspaceErrors: [],
	workspaces: [],
	workspacesInDataGrid: [],
};

//Reducer
const workspacesReducer = (
	state: WorkspacesState = initialWorkspacesState,
	action: Action,
): WorkspacesState => {
	switch (action.type) {
		case FILTER_WORKSPACES: {
			const reg = new RegExp(state.searchValue, 'gi');
			const workspacesInDataGrid = state.workspaces.filter(workspace => {
				const { name, description, ownerId } = workspace;
				return (
					name.match(reg) ||
					description.match(reg) ||
					ownerId.match(reg)
				);
			});

			return { ...state, workspacesInDataGrid };
		}
		case SELECTION_CHANGED: {
			return {
				...state,
				mode: WorkspacesMode.Select,
				workingWorkspace:
					state.workspaces.find(
						workspace => workspace.id === action.payload,
					) || state.workingWorkspace,
			};
		}
		case WORKSPACE_CREATING: {
			return {
				...state,
				mode: WorkspacesMode.Creating,
			};
		}
		case WORKSPACE_CREATED: {
			return initialWorkspacesState;
		}
		case CREATE_WORKSPACE_FAILED: {
			return {
				...state,
				alert: {
					text: 'Unable to create workspace. Please try again.',
					severity: 'error',
				},
			};
		}
		case FETCHING_WORKSPACES: {
			return {
				...initialWorkspacesState,
				alert: state.alert, // keep alert
			};
		}
		case WORKSPACES_FETCHED: {
			return {
				...state,
				mode: WorkspacesMode.Select,
				workspaces: action.payload,
				workspacesInDataGrid: action.payload,
			};
		}
		case FETCH_WORKSPACES_FAILED: {
			return {
				...state,
				alert: {
					text: 'Unable to load data. Please reload this page.',
					severity: 'error',
				},
			};
		}
		case NEW_WORKSPACE_CLICKED: {
			return {
				...state,
				newWorkspaceDrawerOpen: true,
				workingWorkspace: initialWorkspacesState.workingWorkspace,
				workingWorkspaceErrors: [],
			};
		}
		case SEARCH_CANCELED: {
			return {
				...state,
				searchValue: '',
				workspacesInDataGrid: state.workspaces,
			};
		}
		case SEARCH_VALUE_CHANGED: {
			return {
				...state,
				searchValue: action.payload,
			};
		}
		case DRAWER_CANCEL_CLICKED: {
			return {
				...state,
				mode: WorkspacesMode.Select,
			};
		}
		case DRAWER_DESCRIPTION_BLURRED: {
			return {
				...state,
				workingWorkspace: {
					...state.workingWorkspace,
					description: action.payload,
				},
			};
		}
		case DRAWER_NAME_BLURRED: {
			const newWorkspace = {
				...state.workingWorkspace,
				name: action.payload,
			};
			return {
				...state,
				workingWorkspace: newWorkspace,
				workingWorkspaceErrors: checkWorkspaceErrors(newWorkspace),
			};
		}
		case CHECK_WORKING_WORKSPACE_ERRORS: {
			return {
				...state,
				workingWorkspaceErrors: checkWorkspaceErrors(
					state.workingWorkspace,
				),
			};
		}
		case DISPLAY_WORKSPACE_DELETED_INFO: {
			return {
				...state,
				alert: { text: 'Workspace deleted.', severity: 'success' },
			};
		}
		case CLOSE_ALERT: {
			return {
				...state,
				alert: undefined,
			};
		}
		default:
			return state;
	}
};

export default workspacesReducer;
