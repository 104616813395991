export interface Alert {
	id: number;
	name: string;
	displayName: string;
	primaryAssetID: number;
	priority: AlertSeverity;
}

export enum AlertSeverity {
	//Low,
	Warning = 1,
	High = 2,
	Critical = 3,
}
