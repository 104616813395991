// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
//@ts-ignore
import icons from './icons';
import WaterTank from './WaterTank';
import { BufferImage } from './Draw';

interface Symbols {
	[key: string]: string;
}

interface BufferCache {
	[key: string]: BufferImage;
}

export type SymbolCallback = (
	image: HTMLImageElement | BufferImage,
	id: string,
) => void;

interface ParsedId {
	id: string;
	selected: boolean;
	value?: number;
	type?: string;
}

export default class SymbolLoader {
	_symbols: Symbols;
	_bufferCache: BufferCache = {};

	constructor(symbolBaseSet: Symbols = icons) {
		this._symbols = this.buildSelectSet(symbolBaseSet);
	}

	buildSelectSet(symbols: Symbols): Symbols {
		const symbolSet = { ...symbols };
		Object.entries(symbols).map(([id, svg]) => {
			// don't create selected state symbol if one already exists or if this is a selected state symbol
			if (`${id}-selected` in symbolSet || id.match(/-selected$/)) return;
			symbolSet[`${id}-selected`] = svg.replace(
				/%2300abd1/gi,
				'%23764abc',
			);
		});
		return symbolSet;
	}

	getImage(symbolId: string, callback: SymbolCallback): void {
		const image = document.createElement('img');
		image.onload = (): void => {
			callback(image, symbolId);
		};
		image.src = this._getSrc(symbolId);
	}

	getBuffer(symbolId: string, callback: SymbolCallback): void {
		const { id, value, type, selected } = this._parseId(symbolId);
		if (type === 'tank' && value) {
			if (this._bufferCache[id]) {
				callback(this._bufferCache[id], id);
			} else {
				const waterTank = new WaterTank({
					fill: value,
					selectedVariant: selected,
				});
				const buffer = waterTank.asBuffer();
				this._bufferCache[id] = buffer;
				callback(buffer, id);
			}
		}
	}

	getSvg(symbolId: string | undefined): string {
		return symbolId ? this._symbols[symbolId] : '';
	}

	loadAll(callback: SymbolCallback): void {
		Object.keys(this._symbols).map(id => {
			this.getImage(id, callback);
		});
	}

	private _parseId(id: string): ParsedId {
		const match = id.match(/tank-([0-9]{1,})/);
		const selected = !!id.match(/selected/);
		if (match) {
			return {
				id,
				value: parseInt(match[1], 10),
				type: 'tank',
				selected,
			};
		}
		return { id, selected };
	}

	private _getSrc(symbolId: string): string {
		const { id, value, type, selected } = this._parseId(symbolId);
		if (type === 'tank' && value) {
			if (this._symbols[id]) {
				return this._symbols[id];
			}
			const waterTank = new WaterTank({
				fill: value,
				selectedVariant: selected,
			});
			const image = waterTank.asImage();
			this._symbols[id] = image;
			return image;
		} else {
			return this._symbols[id];
		}
	}
}
