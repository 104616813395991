import {
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	TextField,
	Grid,
} from '@material-ui/core';
import { CirclePicker } from 'react-color';
import React, { ChangeEvent, useState } from 'react';
import styled from 'styled-components';
import {
	Channel,
	Display,
	GaugeFormData,
	EventTypeFromGaugeForm,
} from '../../../types/workspace';
import {
	ChartDisplayType,
	gaugeChartDisplayTypeData,
	Series,
} from './CreateDashletService';
import DataSourceInput from './DataSourceInput';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../store';
import {
	gaugeFormInputBlurred,
	updateActiveWsItemGaugeFormData,
} from '../../../store/actions/workspace';

//here any are the event declared by Devextreme which does not have a type
interface Props {
	menuDisplayMode: number;
	action: string;
	gaugeFormData: GaugeFormData;
}

enum GaugeFormInputTypeEnum {
	name = 1,
	description = 2,
	thresholdTo = 3,
	thresholdFrom = 4,
}
interface ColorListType {
	id: number;
	color: string;
}
const colorList: ColorListType[] = [
	{ id: 1, color: '#00ABD1' },
	{ id: 2, color: '#E66B45' },
	{ id: 3, color: '#EFB304' },
	{ id: 4, color: '#83BC40' },
	{ id: 5, color: '#768D95' },
];

const GaugeDashletForm = ({ menuDisplayMode, gaugeFormData }: Props) => {
	const dispatch = useDispatch<AppDispatch>();

	//name errors
	const foundNameError = gaugeFormData.errors?.find(
		err => err.fieldName === 'name',
	);
	const nameErrorText = foundNameError ? foundNameError.errorMessage : '';

	//thresholdFrom error
	const foundThresholdFromError = gaugeFormData.errors?.find(
		err => err.fieldName === 'thresholdFrom',
	);
	const thresholdFromErrorText = foundThresholdFromError
		? foundThresholdFromError.errorMessage
		: '';

	//thresholdTo error
	const foundThresholdToError = gaugeFormData.errors?.find(
		err => err.fieldName === 'thresholdTo',
	);
	const thresholdToErrorText = foundThresholdToError
		? foundThresholdToError.errorMessage
		: '';

	const [gaugeName, setGaugeName] = useState(gaugeFormData.name);

	const onTextFiledBlur = (
		event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>,
		inputFieldType: GaugeFormInputTypeEnum,
	) => {
		if (inputFieldType === GaugeFormInputTypeEnum.name) {
			const newGaugeFormData: GaugeFormData = {
				...gaugeFormData,
				name: event.target.value,
			};
			setGaugeName(event.target.value);
			dispatch(gaugeFormInputBlurred(newGaugeFormData));
		}
		//check description
		else if (inputFieldType === GaugeFormInputTypeEnum.description) {
			const newGaugeFormData: GaugeFormData = {
				...gaugeFormData,
				description: event.target.value,
			};
			dispatch(gaugeFormInputBlurred(newGaugeFormData));
		}
		//check thresholdFrom
		else if (inputFieldType === GaugeFormInputTypeEnum.thresholdFrom) {
			const newGaugeFormData: GaugeFormData = {
				...gaugeFormData,
				thresholdFrom: parseInt(event.target.value),
			};
			dispatch(gaugeFormInputBlurred(newGaugeFormData));
			dispatch(
				updateActiveWsItemGaugeFormData(
					newGaugeFormData,
					EventTypeFromGaugeForm.CHANGE_THRESHOLD_FROM,
				),
			);
		}
		//check thresholdTo
		else if (inputFieldType === GaugeFormInputTypeEnum.thresholdTo) {
			const newGaugeFormData: GaugeFormData = {
				...gaugeFormData,
				thresholdTo: parseInt(event.target.value),
			};
			dispatch(gaugeFormInputBlurred(newGaugeFormData));
			dispatch(
				updateActiveWsItemGaugeFormData(
					newGaugeFormData,
					EventTypeFromGaugeForm.CHANGE_THRESHOLD_TO,
				),
			);
		}
	};

	const onHandelOnDataSourceChange = (channel: Channel): void => {
		if (channel.id !== -1) {
			const newGaugeFormData: GaugeFormData = {
				...gaugeFormData,
				name: channel.displayName,
				selectedChannel: channel,
			};
			setGaugeName(channel.displayName);
			dispatch(gaugeFormInputBlurred(newGaugeFormData));
		}
	};

	const onGaugeNameChange = (
		event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
	): void => {
		const name =
			event.target && event.target.value ? event.target.value : '';
		const newGaugeFormData: GaugeFormData = {
			...gaugeFormData,
			name: name,
		};
		setGaugeName(name);
		dispatch(gaugeFormInputBlurred(newGaugeFormData));
	};

	const onGaugeTypeChange = (
		event: React.ChangeEvent<{
			name?: string | undefined;
			value: unknown;
		}>,
	): void => {
		const gaugeTypeName =
			event.target && event.target.value ? event.target.value : '';
		const gaugeType = gaugeChartDisplayTypeData.find(
			charDisplayType => charDisplayType.id == gaugeTypeName,
		);
		const newGaugeFormData: GaugeFormData = {
			...gaugeFormData,
			gaugeType:
				gaugeType && gaugeType.name
					? gaugeType.name
					: gaugeChartDisplayTypeData[0].name,
		};
		dispatch(gaugeFormInputBlurred(newGaugeFormData));
	};

	const onGaugeColorChange = (
		event: React.ChangeEvent<{
			name?: string | undefined;
			value: unknown;
		}>,
	): void => {
		const gaugeColorName =
			event.target && event.target.value ? event.target.value : '';
		const gaugeColor = colorList.find(item => item.color == gaugeColorName);
		const newGaugeFormData: GaugeFormData = {
			...gaugeFormData,
			needleColor:
				gaugeColor && gaugeColor.color
					? gaugeColor.color
					: colorList[0].color,
		};
		dispatch(gaugeFormInputBlurred(newGaugeFormData));
		dispatch(
			updateActiveWsItemGaugeFormData(
				newGaugeFormData,
				EventTypeFromGaugeForm.CHANGE_NEEDLE_COLOR,
			),
		);
	};

	switch (menuDisplayMode) {
		case Display.Properties:
			return (
				<>
					<Content>
						<DataSourceInput
							handelOnDataSourceChange={
								onHandelOnDataSourceChange
							}
							errors={gaugeFormData.errors}
							selectedValue={
								gaugeFormData.selectedChannel
							}></DataSourceInput>
						<TextField
							error={Boolean(foundNameError)}
							helperText={nameErrorText}
							label="Gauge Name"
							value={gaugeName}
							onBlur={event =>
								onTextFiledBlur(
									event,
									GaugeFormInputTypeEnum.name,
								)
							}
							onChange={onGaugeNameChange}
							required
							variant="outlined"></TextField>
						<TextField
							defaultValue={gaugeFormData.description}
							label="Gauge Description"
							variant="outlined"
							onBlur={event =>
								onTextFiledBlur(
									event,
									GaugeFormInputTypeEnum.description,
								)
							}
						/>
						<Grid container>
							<Grid item xs={12} sm={6}>
								<TextField
									defaultValue={gaugeFormData.thresholdTo}
									error={Boolean(foundThresholdToError)}
									helperText={thresholdToErrorText}
									label="Threshold"
									variant="outlined"
									style={{ width: '215px' }}
									type="number"
									onBlur={event =>
										onTextFiledBlur(
											event,
											GaugeFormInputTypeEnum.thresholdTo,
										)
									}
								/>
							</Grid>
							<Grid item xs={12} sm={6}>
								<TextField
									defaultValue={gaugeFormData.thresholdFrom}
									error={Boolean(foundThresholdFromError)}
									helperText={thresholdFromErrorText}
									variant="outlined"
									style={{ width: '215px' }}
									type="number"
									onBlur={event =>
										onTextFiledBlur(
											event,
											GaugeFormInputTypeEnum.thresholdFrom,
										)
									}
								/>
							</Grid>
						</Grid>

						<FormControl variant="outlined" className="formControl">
							<InputLabel id="demo-simple-chart-type-outlined-label">
								Needle Color
							</InputLabel>
							<Select
								defaultValue={gaugeFormData.needleColor}
								labelId="demo-simple-chart-color-outlined-label"
								id="demo-simple-chart-color-outlined"
								label="Needle Color"
								onChange={onGaugeColorChange}>
								{colorList.map((item: ColorListType) => {
									return (
										<MenuItem
											key={item.color}
											value={item.color}>
											<CirclePicker
												colors={[
													item.color,
												]}></CirclePicker>
										</MenuItem>
									);
								})}
							</Select>
						</FormControl>

						<FormControl
							required
							variant="outlined"
							className="formControl">
							<InputLabel id="demo-simple-chart-type-outlined-label">
								Gauge Type
							</InputLabel>
							<Select
								labelId="demo-simple-chart-type-outlined-label"
								id="demo-simple-chart-type-outlined"
								value={gaugeFormData.gaugeType}
								onChange={onGaugeTypeChange}
								label="Chart Type">
								{gaugeChartDisplayTypeData.map(
									(chartDisplayItem: ChartDisplayType) => {
										return (
											<MenuItem
												key={chartDisplayItem.name}
												value={chartDisplayItem.name}>
												{chartDisplayItem.name}
											</MenuItem>
										);
									},
								)}
							</Select>
						</FormControl>
					</Content>
				</>
			);
		default:
			return <></>;
	}
};

export default GaugeDashletForm;

const Content = styled.div`
	padding: 12px;
	display: flex;
	flex-direction: column;
	flex: 1;
	& > div {
		width: 100%;
		margin-bottom: 24px;
	}
`;
