import MapboxLayer, { UniqueLayerProps } from './MapboxLayer';
import { ServiceLayer } from '../services/types';

export default class ClusterCountLayer extends MapboxLayer {
	protected _type: ServiceLayer['type'] = 'symbol';

	layerProperties(): UniqueLayerProps {
		return {
			layout: {
				'text-field': '{point_count_abbreviated}',
				'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
				'text-size': 12,
			},
		};
	}
}
