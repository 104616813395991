"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const styled_components_1 = require("styled-components");
exports.em = (px, basePx = 16) => `${px / basePx}em`;
exports.rem = (px, basePx = 16) => `${px / basePx}rem`;
exports.borderBox = styled_components_1.css `
	box-sizing: border-box;
	* {
		box-sizing: border-box;
	}
`;
