import axios from 'axios';
import moment from 'moment';
import { Layout } from 'react-grid-layout';
import { AppThunk } from '..';
import {
	defaultDashletFooterOption,
	defaultDashletHeaderOption,
	filterNavData,
} from '../../Components/Info360/WorkspaceGrid/WorkspaceGridService';
import {
	Channel,
	ChartDashletOptions,
	DashletFooterOption,
	DashletType,
	GaugeDashletOptions,
	SidePanelOption,
	Workspace,
	WorkspaceItem,
	ChartFormData,
	MapFormData,
	GaugeFormData,
	EventTypeFromChartForm,
	EventTypeFromGaugeForm,
} from '../../types/workspace';
import * as constants from '../constants/workspace';

// Action Types
export type Action =
	| CloseAlert
	| OpenSettingsDrawer
	| WorkspaceDeleted
	| DeleteWorkspaceFailed
	| FetchingWorkspace
	| WorkspaceFetched
	| FetchWorkspaceFailed
	| SavingWorkspace
	| WorkspacesSaved
	| SaveWorkspacesFailed
	| WorkspaceLayoutChanged
	| DrawerCancelClicked
	| DrawerDescriptionBlurred
	| DrawerNameBlurred
	| CheckWorkspaceSettingsErrors
	| AddWorkspaceItem
	| FetchLatestSensorList
	| FetchLatestSensorListFail
	| InitDashletItemToWorkspace
	| RemoveWorkspaceItem
	| UpdateDashletItemToWorkspace
	| SaveWsItemsToWorkspace
	| AddItemDrawerActions
	| InitDashletItemToFocusMode
	| LoadingWorkspaceItem
	| LoadingActiveWorkspaceItem
	| MapFormInputBlurred
	| CheckMapFormErrors
	| ChartFormInputBlurred
	| CheckChartFormErrors
	| GaugeFormInputBlurred
	| CheckGaugeFormErrors
	| UpdateActiveWsItemChartFormData
	| UpdateActiveWsItemGaugeFormData;

// Action Definition
export interface CloseAlert {
	type: constants.CLOSE_ALERT;
}

export interface OpenSettingsDrawer {
	type: constants.OPEN_SETTINGS_DRAWER;
}

export interface WorkspaceDeleted {
	type: constants.WORKSPACE_DELETED;
}

export interface DeleteWorkspaceFailed {
	type: constants.DELETE_WORKSPACE_FAILED;
}

export interface WorkspaceFetched {
	type: constants.WORKSPACE_FETCHED;
	payload: Workspace;
}

export interface FetchingWorkspace {
	type: constants.FETCHING_WORKSPACE;
}

export interface FetchWorkspaceFailed {
	type: constants.FETCH_WORKSPACE_FAILED;
}

export interface SavingWorkspace {
	type: constants.SAVING_WORKSPACE;
}

export interface WorkspacesSaved {
	type: constants.WORKSPACE_SAVED;
}

export interface SaveWorkspacesFailed {
	type: constants.SAVE_WORKSPACE_FAILED;
}

export interface WorkspaceLayoutChanged {
	type: constants.WORKSPACE_LAYOUT_CHANGED;
	payload: Array<Layout>;
}

export interface DrawerCancelClicked {
	type: constants.DRAWER_CANCEL_CLICKED;
}

export interface DrawerDescriptionBlurred {
	type: constants.DRAWER_DESCRIPTION_BLURRED;
	payload: string;
}

export interface DrawerNameBlurred {
	type: constants.DRAWER_NAME_BLURRED;
	payload: string;
}

export interface DrawerCancelClicked {
	type: constants.DRAWER_CANCEL_CLICKED;
}

export interface DrawerDescriptionBlurred {
	type: constants.DRAWER_DESCRIPTION_BLURRED;
	payload: string;
}

export interface DrawerNameBlurred {
	type: constants.DRAWER_NAME_BLURRED;
	payload: string;
}
export interface CheckWorkspaceSettingsErrors {
	type: constants.CHECK_WORKSPACE_SETTINGS_ERRORS;
}

export interface AddWorkspaceItem {
	type: constants.ADD_WORKSPACE_ITEM;
	payload: WorkspaceItem;
}

export interface RemoveWorkspaceItem {
	type: constants.REMOVE_WORKSPACE_ITEM;
	payload: number;
}

export interface FetchLatestSensorList {
	type: constants.FOCUS_MODE_FETCH_SENSOR_LIST;
	payload: Channel[];
}

export interface FetchLatestSensorListFail {
	type: constants.FOCUS_MODE_FETCH_SENSOR_LIST_FAIL;
	payload: string;
}

export interface InitDashletItemToWorkspace {
	type: constants.INIT_WSITEM_TO_WORKSPACE;
	payload: WorkspaceItem;
}

export interface InitDashletItemToFocusMode {
	type: constants.INIT_WSITEM_TO_FOCUS_MODE;
	payload: WorkspaceItem;
}

export interface UpdateDashletItemToWorkspace {
	type: constants.UPDATE_WSITEM_TO_WORKSPACE;
	payload: WorkspaceItem;
}

export interface SaveWsItemsToWorkspace {
	type: constants.SAVE_WSITEM_TO_WORKSPACE;
	payload: Workspace;
}

export interface AddItemDrawerActions {
	type: constants.ADD_ITEM_DRAWER_ACTION;
	payload: SidePanelOption;
}

export interface LoadingWorkspaceItem {
	type: constants.LOADING_WSITEM;
	payload: WorkspaceItem;
}

export interface LoadingActiveWorkspaceItem {
	type: constants.LOADING_ACTIVE_WSITEM;
	payload: WorkspaceItem;
}

export interface MapFormInputBlurred {
	type: constants.MAP_FORM_INPUT_BLURRED;
	payload: MapFormData;
}

export interface CheckMapFormErrors {
	type: constants.CHECK_MAP_FORM_ERRORS;
}

export interface ChartFormInputBlurred {
	type: constants.CHART_FORM_INPUT_BLURRED;
	payload: ChartFormData;
}

export interface CheckChartFormErrors {
	type: constants.CHECK_CHART_FORM_ERRORS;
}

export interface GaugeFormInputBlurred {
	type: constants.GAUGE_FORM_INPUT_BLURRED;
	payload: GaugeFormData;
}

export interface CheckGaugeFormErrors {
	type: constants.CHECK_GAUGE_FORM_ERRORS;
}

export interface UpdateActiveWsItemChartFormData {
	type: constants.UPDATE_ACTIVE_WSITEM_CHART_FORM_DATA;
}

export interface UpdateActiveWsItemGaugeFormData {
	type: constants.UPDATE_ACTIVE_WSITEM_GAUGE_FORM_DATA;
}

// Action Creators
export const closeAlert = (): Action => {
	return { type: constants.CLOSE_ALERT };
};

export const openSettingsDrawer = (): Action => {
	return { type: constants.OPEN_SETTINGS_DRAWER };
};

const workspaceDeleted = (): Action => {
	return { type: constants.WORKSPACE_DELETED };
};

const deleteWorkspaceFailed = (): Action => {
	return { type: constants.DELETE_WORKSPACE_FAILED };
};

const fetchingWorkspace = (): Action => {
	return { type: constants.FETCHING_WORKSPACE };
};

const workspaceFetched = (workspace: Workspace): Action => {
	return { type: constants.WORKSPACE_FETCHED, payload: workspace };
};

const fetchWorkspaceFailed = (): Action => {
	return { type: constants.FETCH_WORKSPACE_FAILED };
};

const savingWorkspace = (): Action => {
	return { type: constants.SAVING_WORKSPACE };
};

const workspaceSaved = (): Action => {
	return { type: constants.WORKSPACE_SAVED };
};

const saveWorkspaceFailed = (): Action => {
	return { type: constants.SAVE_WORKSPACE_FAILED };
};

export const workspaceLayoutChanged = (newLayout: Array<Layout>): Action => {
	return {
		type: constants.WORKSPACE_LAYOUT_CHANGED,
		payload: newLayout,
	};
};

export const drawerCancelClicked = (): Action => {
	return { type: constants.DRAWER_CANCEL_CLICKED };
};

export const drawerDescriptionBlurred = (newDescription: string) => {
	return {
		type: constants.DRAWER_DESCRIPTION_BLURRED,
		payload: newDescription,
	};
};

export const drawerNameBlurred = (newName: string): Action => {
	return {
		type: constants.DRAWER_NAME_BLURRED,
		payload: newName,
	};
};

const checkWorkspaceSettingsErrors = (): Action => {
	return { type: constants.CHECK_WORKSPACE_SETTINGS_ERRORS };
};

export const deleteWorkspace = (
	id: number,
	baseUrl: string,
): AppThunk => async dispatch => {
	try {
		await axios.delete(`${baseUrl}Workspace/${id}`);
		dispatch(workspaceDeleted());
	} catch (error) {
		dispatch(deleteWorkspaceFailed());
	}
};

export const saveWorkspace = (
	workspace: Workspace,
	baseUrl: string,
): AppThunk => async (dispatch, getState) => {
	const { settingsDrawerOpen, errors } = getState().workspace;
	if (settingsDrawerOpen) {
		dispatch(checkWorkspaceSettingsErrors());
	}
	if (errors.length === 0) {
		dispatch(savingWorkspace());
		try {
			await axios.put(`${baseUrl}Workspace/${workspace.id}`, workspace);
			dispatch(workspaceSaved());
		} catch (error) {
			dispatch(saveWorkspaceFailed());
		}
	}
};

export const addWorkspaceItem = (
	wsItemToAdd: WorkspaceItem,
): AppThunk => async dispatch => {
	dispatch({
		type: constants.ADD_WORKSPACE_ITEM,
		payload: wsItemToAdd,
	});
};

export const removeWorkspaceItem = (
	itemIndex: number,
): AppThunk => async dispatch => {
	dispatch({
		type: constants.REMOVE_WORKSPACE_ITEM,
		payload: itemIndex,
	});
};

export const updateDashletItemToWorkspace = (
	baseUrl: string,
	wsItemToInit: WorkspaceItem,
): AppThunk => async dispatch => {
	const newWsItem: WorkspaceItem = {
		...wsItemToInit,
	};

	if (newWsItem.dashletTypeId === DashletType.Chart) {
		const chartData: ChartDashletOptions = JSON.parse(newWsItem.options);
		try {
			if (!chartData.dateTimeFrom) {
				chartData.dateTimeFrom = '3/3/2020';
			}

			if (!chartData.dateTimeTo) {
				chartData.dateTimeTo = '5/5/2020';
			}

			chartData.isLoading = true;
			newWsItem.options = JSON.stringify(chartData);
			dispatch({
				type: constants.LOADING_WSITEM,
				payload: newWsItem,
			});

			const response = await axios.get(`${baseUrl}TimeSeries`, {
				params: {
					channelID: 12,
					baseUnit: 2000,
					dateTimeFrom: chartData.dateTimeFrom,
					dateTimeTo: chartData.dateTimeTo,
					resultLimit: 300,
					chartDataLimitPortion: 0,
					navData: false,
				},
			});
			chartData.chartData = response.data.chartData;
			chartData.isLoading = false;
			newWsItem.options = JSON.stringify(chartData);
			dispatch({
				type: constants.UPDATE_WSITEM_TO_WORKSPACE,
				payload: newWsItem,
			});
		} catch (error) {
			console.log('updateDashletItemToWorkspace action error', error);
		}
	} else if (newWsItem.dashletTypeId === DashletType.Gauge) {
		const gaugeData: GaugeDashletOptions = JSON.parse(newWsItem.options);
		try {
			if (!gaugeData.dateTimeFrom) {
				gaugeData.dateTimeFrom = '3/3/2020';
			}

			if (!gaugeData.dateTimeTo) {
				gaugeData.dateTimeTo = '5/5/2020';
			}

			gaugeData.isLoading = true;
			newWsItem.options = JSON.stringify(gaugeData);
			dispatch({
				type: constants.LOADING_WSITEM,
				payload: newWsItem,
			});

			const response = await axios.get(`${baseUrl}TimeSeries`, {
				params: {
					channelID: 12,
					baseUnit: 2000,
					dateTimeFrom: gaugeData.dateTimeFrom,
					dateTimeTo: gaugeData.dateTimeTo,
					resultLimit: 300,
					chartDataLimitPortion: 0,
					navData: false,
				},
			});

			if (response.data.chartData && response.data.chartData.length > 0) {
				gaugeData.dashletInit = true;
				gaugeData.gaugeValue = response.data.chartData[96].high;
				gaugeData.chartData = response.data.chartData;
				gaugeData.isLoading = false;
				newWsItem.options = JSON.stringify(gaugeData);
			}
			dispatch({
				type: constants.UPDATE_WSITEM_TO_WORKSPACE,
				payload: newWsItem,
			});
		} catch (error) {
			console.log('updateDashletItemToWorkspace action error', error);
		}
	}
};

export const initDashletItemToWorkspace = (
	baseUrl: string,
	wsItemToInit: WorkspaceItem,
): AppThunk => async dispatch => {
	const newWsItem: WorkspaceItem = {
		...wsItemToInit,
	};

	if (newWsItem.dashletTypeId === DashletType.Chart) {
		const chartData: ChartDashletOptions = JSON.parse(newWsItem.options);
		try {
			if (!chartData.dateTimeFrom) {
				chartData.dateTimeFrom = '3/3/2020';
			}

			if (!chartData.dateTimeTo) {
				chartData.dateTimeTo = '5/5/2020';
			}

			chartData.isLoading = true;
			newWsItem.options = JSON.stringify(chartData);
			dispatch({
				type: constants.LOADING_WSITEM,
				payload: newWsItem,
			});

			const response = await axios.get(`${baseUrl}TimeSeries`, {
				params: {
					channelID: 12,
					baseUnit: 2000,
					dateTimeFrom: chartData.dateTimeFrom,
					dateTimeTo: chartData.dateTimeTo,
					resultLimit: 300,
					chartDataLimitPortion: 0,
					navData: true,
				},
			});

			let rangeSelectorOption: DashletFooterOption = defaultDashletFooterOption;
			filterNavData(response.data.navData).then(newNavData => {
				const navData = newNavData;

				if (navData && navData.length > 0) {
					const rangeBeginValue = navData[0].time;
					//const rangeEndValue = navData[navData.length - 1].time;
					rangeSelectorOption = {
						rangeStartDateTime: moment(rangeBeginValue).format(
							'MM-DD-YYYY',
						),
						rangeEndDateTime: moment(moment.now()).format(
							'MM-DD-YYYY',
						),
						visible: true,
						navData: navData,
						dashletId: newWsItem.id,
					};
				}

				chartData.dashletHeaderOption = defaultDashletHeaderOption;
				chartData.dashletFooterOption = rangeSelectorOption;
				chartData.dashletInit = true;
				chartData.isLoading = false;
				chartData.chartData = response.data.chartData;
				newWsItem.options = JSON.stringify(chartData);
				dispatch({
					type: constants.INIT_WSITEM_TO_WORKSPACE,
					payload: newWsItem,
				});
			});
		} catch (error) {
			console.log('initDashletItemToWorkspace action error', error);
		}
	} else if (newWsItem.dashletTypeId === DashletType.Gauge) {
		const gaugeData: GaugeDashletOptions = JSON.parse(newWsItem.options);
		try {
			if (!gaugeData.dateTimeFrom) {
				gaugeData.dateTimeFrom = '3/3/2020';
			}

			if (!gaugeData.dateTimeTo) {
				gaugeData.dateTimeTo = '5/5/2020';
			}

			gaugeData.isLoading = true;
			newWsItem.options = JSON.stringify(gaugeData);
			dispatch({
				type: constants.LOADING_WSITEM,
				payload: newWsItem,
			});

			const response = await axios.get(`${baseUrl}TimeSeries`, {
				params: {
					channelID: 12,
					baseUnit: 2000,
					dateTimeFrom: gaugeData.dateTimeFrom,
					dateTimeTo: gaugeData.dateTimeTo,
					resultLimit: 300,
					chartDataLimitPortion: 0,
					navData: false,
				},
			});

			const chartData = response.data.chartData;

			if (chartData && chartData.length > 0) {
				gaugeData.dashletInit = true;
				gaugeData.gaugeValue = response.data.chartData[96].high;
				gaugeData.chartData = response.data.chartData;
				gaugeData.isLoading = false;
				newWsItem.options = JSON.stringify(gaugeData);
			}

			dispatch({
				type: constants.UPDATE_WSITEM_TO_WORKSPACE,
				payload: newWsItem,
			});
		} catch (error) {
			console.log('initDashletItemToWorkspace action error', error);
		}
	}
};

export const fetchLatestSensorList = (
	baseUrl: string,
): AppThunk => async dispatch => {
	try {
		const response = await axios.get(`${baseUrl}Channel`, {
			params: { siteID: 7, channelTypeID: 1, count: 100 },
		});
		dispatch({
			type: constants.FOCUS_MODE_FETCH_SENSOR_LIST,
			payload: response.data,
		});
	} catch (error) {
		dispatch({ type: constants.FOCUS_MODE_FETCH_SENSOR_LIST_FAIL });
	}
};

export const saveWsItemsToWorkspace = (
	dashlets: WorkspaceItem[],
	baseUrl: string,
): AppThunk => async (dispatch, getState) => {
	const currentWs = getState().workspace.workspace;
	const getSerializedWorkspace = (workspace: Workspace): Workspace => {
		const wsItems = workspace.workspaceItem;
		const wsItemsToSave: WorkspaceItem[] = [];
		if (wsItems && wsItems.length > 0) {
			wsItems.map((wsItem: WorkspaceItem) => {
				if (wsItem.id !== -1) {
					if (wsItem.dashletTypeId === DashletType.Chart) {
						const options: ChartDashletOptions = JSON.parse(
							wsItem.options,
						);
						const footerOption = options.dashletFooterOption;
						if (footerOption) {
							const newOptions: ChartDashletOptions = {
								...options,
								chartData: [],
								dashletInit: undefined,
								dashletFooterOption: {
									...footerOption,
									navData: [],
								},
							};
							const newWsItem: WorkspaceItem = {
								...wsItem,
								id: 0,
								dashletId: 1,
								options: JSON.stringify(newOptions),
							};
							wsItemsToSave.push(newWsItem);
						}
					} else if (wsItem.dashletTypeId === DashletType.Gauge) {
						const options: GaugeDashletOptions = JSON.parse(
							wsItem.options,
						);

						const newOptions: GaugeDashletOptions = {
							...options,
							dashletInit: false,
						};
						const newWsItem: WorkspaceItem = {
							...wsItem,
							id: 0,
							dashletId: 1,
							options: JSON.stringify(newOptions),
						};
						wsItemsToSave.push(newWsItem);
					} else {
						const newWsItem: WorkspaceItem = {
							...wsItem,
							id: 0,
							dashletId: 1,
						};
						wsItemsToSave.push(newWsItem);
					}
				}
			});
		}
		const newWorkspace = {
			...workspace,
			workspaceItem: wsItemsToSave,
		};
		return newWorkspace;
	};

	const newWorkspace: Workspace = getSerializedWorkspace(currentWs);
	const wsToSave = {
		Id: newWorkspace.id,
		Name: newWorkspace.name,
		Description: newWorkspace.description,
		Permission: newWorkspace.permission,
		Options: newWorkspace.options,
		WorkspaceItem: newWorkspace.workspaceItem,
	};
	try {
		await axios.put(`${baseUrl}Workspace/${wsToSave.Id}`, wsToSave);
		dispatch({ type: constants.WORKSPACE_SAVED });
		dispatch({
			type: constants.SAVE_WSITEM_TO_WORKSPACE,
			payload: wsToSave,
		});
	} catch (error) {
		dispatch({ type: constants.SAVE_WORKSPACE_FAILED });
	}
};

export const fetchWorkspace = (
	id: number,
	baseUrl: string,
): AppThunk => async dispatch => {
	dispatch(fetchingWorkspace());
	try {
		const { data } = await axios.get(`${baseUrl}Workspace/${id}`);
		dispatch(workspaceFetched(data));

		const { workspaceItem } = data;
		if (workspaceItem && workspaceItem.length > 0) {
			const workspaceItems = workspaceItem;
			workspaceItems.map((wsItem: WorkspaceItem) => {
				setTimeout(() => {
					const newWsItem: WorkspaceItem = {
						...wsItem,
					};

					if (newWsItem.dashletTypeId === DashletType.Chart) {
						const chartData: ChartDashletOptions = JSON.parse(
							newWsItem.options,
						);
						try {
							if (!chartData.dateTimeFrom) {
								chartData.dateTimeFrom = '3/3/2020';
							}

							if (!chartData.dateTimeTo) {
								chartData.dateTimeTo = '5/5/2020';
							}

							chartData.isLoading = true;
							newWsItem.options = JSON.stringify(chartData);
							dispatch({
								type: constants.LOADING_WSITEM,
								payload: newWsItem,
							});
							axios
								.get(`${baseUrl}TimeSeries`, {
									params: {
										channelID: 12,
										baseUnit: 2000,
										dateTimeFrom: chartData.dateTimeFrom,
										dateTimeTo: chartData.dateTimeTo,
										resultLimit: 300,
										chartDataLimitPortion: 0,
										navData: true,
									},
								})
								.then(response => {
									let rangeSelectorOption: DashletFooterOption = defaultDashletFooterOption;

									filterNavData(response.data.navData).then(
										newNavData => {
											const navData = newNavData;

											if (navData && navData.length > 0) {
												const rangeBeginValue =
													navData[0].time;
												//const rangeEndValue = navData[navData.length - 1].time;
												rangeSelectorOption = {
													rangeStartDateTime: moment(
														rangeBeginValue,
													).format('MM-DD-YYYY'),
													rangeEndDateTime: moment(
														moment.now(),
													).format('MM-DD-YYYY'),
													visible: true,
													navData: navData,
													dashletId: newWsItem.id,
												};
											}

											chartData.dashletHeaderOption = defaultDashletHeaderOption;
											chartData.dashletFooterOption = rangeSelectorOption;
											chartData.dashletInit = true;
											chartData.isLoading = false;
											chartData.chartData =
												response.data.chartData;
											newWsItem.options = JSON.stringify(
												chartData,
											);
											dispatch({
												type:
													constants.INIT_WSITEM_TO_WORKSPACE,
												payload: newWsItem,
											});
										},
									);
								});
						} catch (error) {
							console.log('fetchWorkspace action error', error);
						}
					} else if (newWsItem.dashletTypeId === DashletType.Gauge) {
						const gaugeData: GaugeDashletOptions = JSON.parse(
							newWsItem.options,
						);
						try {
							if (!gaugeData.dateTimeFrom) {
								gaugeData.dateTimeFrom = '3/3/2020';
							}

							if (!gaugeData.dateTimeTo) {
								gaugeData.dateTimeTo = '5/5/2020';
							}

							gaugeData.isLoading = true;
							newWsItem.options = JSON.stringify(gaugeData);
							dispatch({
								type: constants.LOADING_WSITEM,
								payload: newWsItem,
							});

							axios
								.get(`${baseUrl}TimeSeries`, {
									params: {
										channelID: 12,
										baseUnit: 2000,
										dateTimeFrom: gaugeData.dateTimeFrom,
										dateTimeTo: gaugeData.dateTimeTo,
										resultLimit: 300,
										chartDataLimitPortion: 0,
										navData: false,
									},
								})
								.then(response => {
									const chartData = response.data.chartData;

									if (chartData && chartData.length > 0) {
										gaugeData.dashletInit = true;
										gaugeData.gaugeValue =
											response.data.chartData[96].high;
										gaugeData.chartData =
											response.data.chartData;
										gaugeData.isLoading = false;
										newWsItem.options = JSON.stringify(
											gaugeData,
										);
									}

									dispatch({
										type:
											constants.UPDATE_WSITEM_TO_WORKSPACE,
										payload: newWsItem,
									});
								});
						} catch (error) {
							console.log('fetchWorkspace action error', error);
						}
					}
				}, 1000);
			});
		}
	} catch (error) {
		dispatch(fetchWorkspaceFailed());
	}
};

export const addItemDrawerActions = (
	sidePanelOption: SidePanelOption,
): Action => {
	return {
		type: constants.ADD_ITEM_DRAWER_ACTION,
		payload: sidePanelOption,
	};
};

export const mapFormInputBlurred = (mapFormData: MapFormData): Action => {
	return {
		type: constants.MAP_FORM_INPUT_BLURRED,
		payload: mapFormData,
	};
};

export const checkMapFormErrors = (): Action => {
	return {
		type: constants.CHECK_MAP_FORM_ERRORS,
	};
};

export const chartFormInputBlurred = (chartFormData: ChartFormData): Action => {
	return {
		type: constants.CHART_FORM_INPUT_BLURRED,
		payload: chartFormData,
	};
};

export const checkChartFormErrors = (): Action => {
	return {
		type: constants.CHECK_CHART_FORM_ERRORS,
	};
};

export const gaugeFormInputBlurred = (gaugeFormData: GaugeFormData): Action => {
	return {
		type: constants.GAUGE_FORM_INPUT_BLURRED,
		payload: gaugeFormData,
	};
};

export const checkGaugeFormErrors = (): Action => {
	return {
		type: constants.CHECK_GAUGE_FORM_ERRORS,
	};
};

export const updateActiveWsItemChartFormData = (
	chartFormData: ChartFormData,
	eventType: EventTypeFromChartForm,
): AppThunk => async (dispatch, getState) => {
	const activeWSItem = getState().workspace.activeWorkspaceItem;
	if (activeWSItem && activeWSItem.dashletTypeId === DashletType.Chart) {
		const chartOption: ChartDashletOptions = JSON.parse(
			activeWSItem.options,
		);

		if (eventType === EventTypeFromChartForm.CHANGE_NAME) {
			chartOption.name = chartFormData.name;
		}

		if (eventType === EventTypeFromChartForm.CHANGE_DESCRIPTION) {
			chartOption.description = chartFormData.description;
		}
		if (eventType === EventTypeFromChartForm.CHANGE_CHART_TYPE) {
			chartOption.chartType = chartFormData.chartType;
		}
		if (eventType === EventTypeFromChartForm.CHANGE_INTERVAL) {
			chartOption.series = chartFormData.series;
		}
		activeWSItem.options = JSON.stringify(chartOption);
		dispatch({
			type: constants.INIT_WSITEM_TO_FOCUS_MODE,
			payload: activeWSItem,
		});
	}
};

export const updateActiveWsItemGaugeFormData = (
	gaugeFormData: GaugeFormData,
	eventType: EventTypeFromGaugeForm,
): AppThunk => async (dispatch, getState) => {
	const activeWSItem = getState().workspace.activeWorkspaceItem;
	if (activeWSItem && activeWSItem.dashletTypeId === DashletType.Gauge) {
		const gaugeOption: GaugeDashletOptions = JSON.parse(
			activeWSItem.options,
		);

		if (eventType === EventTypeFromGaugeForm.CHANGE_NAME) {
			gaugeOption.name = gaugeFormData.name;
		}

		if (eventType === EventTypeFromGaugeForm.CHANGE_DESCRIPTION) {
			gaugeOption.description = gaugeFormData.description;
		}
		if (eventType === EventTypeFromGaugeForm.CHANGE_THRESHOLD_TO) {
			gaugeOption.thresholdTo = gaugeFormData.thresholdTo;
		}
		if (eventType === EventTypeFromGaugeForm.CHANGE_THRESHOLD_FROM) {
			gaugeOption.thresholdFrom = gaugeFormData.thresholdFrom;
		}
		if (eventType === EventTypeFromGaugeForm.CHANGE_NEEDLE_COLOR) {
			gaugeOption.color = gaugeFormData.needleColor;
		}

		activeWSItem.options = JSON.stringify(gaugeOption);
		dispatch({
			type: constants.INIT_WSITEM_TO_FOCUS_MODE,
			payload: activeWSItem,
		});
	}
};

export const initDashletItemToFocusMode = (
	baseUrl: string,
	wsItemToInit: WorkspaceItem,
): AppThunk => async dispatch => {
	const newWsItem: WorkspaceItem = {
		...wsItemToInit,
	};
	dispatch({
		type: constants.INIT_WSITEM_TO_FOCUS_MODE,
		payload: newWsItem,
	});
};
