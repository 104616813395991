import AssetRegistry, { assetUnknown } from './AssetRegistry';
import DataService from './DataService';

import { GeoJsonDataType, Features, Properties, ServiceLayer } from './types';
import MapboxDataSource from './MapboxDataSource';

export default class GeoJsonDataService extends DataService {
	get layers(): ServiceLayer[] {
		const sources = this.dataSources;
		if (!sources || (sources && !sources.length)) return [];

		return sources.map(({ id, displayName, type, icon, color }) => ({
			id,
			source: id,
			displayName,
			type,
			icon,
			cluster: type !== 'line',
			color,
		}));
	}

	getDisplayName(
		properties: Properties | undefined,
		groupBy: string,
	): string {
		const value = properties?.[groupBy];
		if (groupBy === 'asset_type_id') {
			return AssetRegistry.getDisplayName(value as number);
		}
		return `${value}`;
	}

	getColor(properties: Properties | undefined): string {
		const { asset_type_id: assetTypeId, type } = properties ?? {};
		if (assetTypeId) {
			return AssetRegistry.getColor(assetTypeId as number);
		}
		if (type === 'sensor') return '#FFEE58';

		return assetUnknown.color;
	}

	validData(data: GeoJsonDataType): boolean {
		if (
			typeof data === 'object' &&
			'features' in data &&
			Array.isArray(data.features) &&
			typeof data.type === 'string'
		) {
			return true;
		}
		return false;
	}

	remapProperties(data: GeoJsonDataType | null): GeoJsonDataType | null {
		if (!data) return null;
		const features = data.features.map(({ properties, ...other }) => {
			const id = `${properties.id ||
				properties.asset_id ||
				properties.sensor_id}`;
			const sensors = this.mapSensors(data.features, id);
			const icon = this.mapIcons(properties);
			const type = this.mapType(properties);
			if (icon) {
				this.fire('load-icon', { id: icon });
			}
			return {
				...other,
				properties: {
					...properties,
					id,
					icon,
					sensors,
					type,
				},
			};
		});
		return {
			...data,
			features,
		};
	}

	mapType(properties: Properties): string | undefined {
		const { asset_type_id: assetTypeId, sensor_id: sensorId } = properties;
		if (assetTypeId) return 'asset';
		else if (sensorId) return 'sensor';
		return;
	}

	mapIcons(properties: Properties): string | undefined {
		const {
			asset_type_id: assetTypeId,
			sensor_id: sensorId,
			diameter,
		} = properties;
		if (sensorId) return 'sensor';
		if (!assetTypeId && diameter) return 'pipe';
		if (!assetTypeId && !sensorId) {
			return;
		}

		return AssetRegistry.getIcon(assetTypeId as number);
	}

	mapSensors(
		features: Features[],
		parent: string | number,
	): Properties[] | undefined {
		if (!parent) return undefined;
		const filtered = features
			.filter(({ properties }) => properties.parent === parent)
			.map(({ properties }) => ({
				...properties,
				id:
					properties.id ||
					properties.asset_id ||
					properties.sensor_id,
			}));
		return filtered.length ? filtered : undefined;
	}

	/**
	 * Convert geojson to datasources with the option of splitting
	 * by group or a filter
	 * @param data data retrieved from geojson data service
	 */
	createDataSources(data: GeoJsonDataType): void {
		const features = (data as GeoJsonDataType)?.features;

		const defaultSource = [
			new MapboxDataSource({
				id: this.id,
				displayName: this.displayName,
				type: 'symbol',
				data,
				icon: this._findFirstIcon(features),
				color: this.getColor(features[0].properties),
			}),
		];

		const dataSources =
			this._layerGrouping?.flatMap(
				({ groupBy, filterBy, displayName, type }) => {
					if (groupBy) {
						return this._groupFeaturesForDataSources(
							features,
							groupBy,
							type,
						);
					} else if (filterBy) {
						return this._filterFeaturesForDataSources(
							features,
							filterBy,
							type,
							displayName,
						);
					}
					return [];
				},
			) ?? defaultSource;
		this._dataSources = dataSources.length ? dataSources : defaultSource;
	}

	/**
	 * Split the geojson into group by grouping on a particular property
	 * @param features geojson features
	 * @param groupBy feature property to group on
	 * @param type layer type
	 *
	 * @returns an array of data sources for mapbox
	 */
	private _groupFeaturesForDataSources(
		features: GeoJsonDataType['features'],
		groupBy: string,
		type: ServiceLayer['type'],
	): MapboxDataSource[] {
		return this._getUniqueValuesFromFeatureProperty(features, groupBy)
			.map(groupValue =>
				this._getFeaturesWithPropertyValue(
					features,
					groupBy,
					groupValue,
				),
			)
			.map(group => this._mapToDataSourceForAGroup(group, groupBy, type));
	}

	/**
	 * filtering the features to by a particular property
	 * @param features geojson features
	 * @param filterBy feature property to find
	 * @param type layer type
	 * @param displayName layer display name
	 *
	 * @returns an array of data sources for mapbox
	 */
	private _filterFeaturesForDataSources(
		features: GeoJsonDataType['features'],
		filterBy: string,
		type: ServiceLayer['type'],
		displayName: ServiceLayer['displayName'],
	): MapboxDataSource[] {
		const dataFeatures = this._getFeaturesWithProperty(features, filterBy);
		if (!dataFeatures.length) return [];
		return [
			new MapboxDataSource({
				id: `${this.id}-${filterBy}`,
				displayName,
				data: {
					type: 'FeatureCollection',
					features: dataFeatures,
				},
				type,
				icon: this._findFirstIcon(dataFeatures),
				color: this.getColor(dataFeatures[0]?.properties),
			}),
		];
	}

	/**
	 * Get unique values from a particular property of a feature
	 * @param features geojson features
	 * @param property feature property to group on
	 *
	 * @returns an array of the unique groups found
	 */
	private _getUniqueValuesFromFeatureProperty(
		features: GeoJsonDataType['features'],
		property: string,
	): string[] {
		return Array.from(
			new Set(features.map(({ properties }) => properties[property])),
		).filter(Boolean) as string[];
	}

	/**
	 * Create a MapboxDataSource object from features
	 * @param features geojson features
	 * @param groupBy feature property to group on
	 * @param type layer type
	 */
	private _mapToDataSourceForAGroup(
		features: GeoJsonDataType['features'],
		groupBy: string,
		type: ServiceLayer['type'],
	): MapboxDataSource {
		const groupValue = features[0]?.properties[groupBy];
		return new MapboxDataSource({
			id: `${this.id}-${groupBy}-${groupValue}`,
			displayName: this.getDisplayName(features[0]?.properties, groupBy),
			data: {
				type: 'FeatureCollection',
				features,
			},
			type,
			icon: this._findFirstIcon(features),
			color: this.getColor(features[0]?.properties),
		});
	}

	/**
	 * Get all features that have a particular property
	 * @param features geojson features
	 * @param property feature property to look for
	 */
	private _getFeaturesWithProperty(
		features: GeoJsonDataType['features'],
		property: string,
	): GeoJsonDataType['features'] {
		return features.filter(({ properties }) => property in properties);
	}

	/**
	 * Get all features that have a particular property with the value specified
	 * @param features geojson features
	 * @param property feature property to look for
	 * @param value value of property
	 */
	private _getFeaturesWithPropertyValue(
		features: GeoJsonDataType['features'],
		property: string,
		value: string,
	): GeoJsonDataType['features'] {
		return features.filter(
			({ properties }) => properties[property] === value,
		);
	}

	/**
	 * Find the first icon in the features
	 * @param features geojson features
	 */
	private _findFirstIcon(
		features: GeoJsonDataType['features'],
	): string | undefined {
		return `${features
			.map(({ properties }) => properties.icon)
			.filter(Boolean)[0] || 'unknown'}`;
	}
}
