import React from 'react';
import {
	WorkspaceItem,
	DashletType,
	MapDashletOptions,
} from '../../../../types/workspace';
import {
	defaultMapConfig,
	defaultMapToken,
} from '../../WorkspaceGrid/WorkspaceGridService';
import {
	ChartContainer,
	WidgetContainer,
	WidgetHeader,
	WidgetTitle,
	RangeContainer,
	MapContainer,
	GaugeContainer,
} from '../FocusToolBar.Style';
import { Grid } from '@material-ui/core';
import DefaultChartWidget from '../../Dashlet/DashletContent/DashletChart';
import DefaultGaugeWidget from '../../Dashlet/DashletContent/DashletGauge';
import { MapComp } from '../../Dashlet/DashletContent/DashletMap';
import ChartRangeSelector from '../../Dashlet/DashletContent/DashletChartRange';
import Loading from '../../../Loading/Loading';

interface Props {
	widgetItem: WorkspaceItem;
	onUpdateDashletHandler: (wsItem: WorkspaceItem) => void;
}

export enum DashletContentEnum {
	Chart = 1,
	Gauge = 2,
	Map = 3,
}

const FocusModeDashlet = ({ widgetItem, onUpdateDashletHandler }: Props) => {
	const onRangeOptionsChange = (wsItem: WorkspaceItem) => {
		onUpdateDashletHandler(wsItem);
	};

	if (widgetItem.dashletTypeId === DashletType.Chart) {
		return (
			<>
				<WidgetContainer>
					<Loading loading={widgetItem.id === -1}>
						<ChartContainer>
							<DefaultChartWidget
								item={widgetItem}
								isFocusModeOn={true}
							/>
						</ChartContainer>
						<RangeContainer>
							<ChartRangeSelector
								item={widgetItem}
								isFocusModeOn={true}
								onRangeOptionsChange={onRangeOptionsChange}
							/>
						</RangeContainer>
					</Loading>
				</WidgetContainer>
			</>
		);
	} else if (widgetItem.dashletTypeId === DashletType.Gauge) {
		return (
			<>
				<WidgetContainer>
					<Loading loading={widgetItem.id === -1}>
						<GaugeContainer>
							<DefaultGaugeWidget
								item={widgetItem}
								isFocusModeOn={true}></DefaultGaugeWidget>
						</GaugeContainer>
					</Loading>
				</WidgetContainer>
			</>
		);
	} else if (widgetItem.dashletTypeId === DashletType.Map) {
		const mapData: MapDashletOptions = JSON.parse(widgetItem.options);
		return (
			<>
				<WidgetContainer>
					<Loading loading={widgetItem.id === -1}>
						<WidgetHeader>
							<Grid container spacing={1}>
								<Grid item xs={9}>
									<WidgetTitle>{mapData.name}</WidgetTitle>
								</Grid>
							</Grid>
						</WidgetHeader>
						<MapContainer>
							<MapComp
								config={defaultMapConfig}
								token={defaultMapToken}
							/>
						</MapContainer>
					</Loading>
				</WidgetContainer>
			</>
		);
	} else {
		return <></>;
	}
};

export default FocusModeDashlet;
