import { combineReducers } from 'redux';
import dashboard, { initialDashboardState } from './dashboard';
import tools, { initialToolsState } from './tools';
import workspace, { initialWorkspaceState } from './workspace';
import workspaces, { initialWorkspacesState } from './workspaces';
import { RootState } from '../types/root';

const rootReducer = combineReducers({
	dashboard,
	tools,
	workspaces,
	workspace,
});

export const initialRootState: RootState = {
	dashboard: initialDashboardState,
	tools: initialToolsState,
	workspaces: initialWorkspacesState,
	workspace: initialWorkspaceState,
};

export default rootReducer;
