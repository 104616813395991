import styled from 'styled-components';

export const StyledMassBalanceDesignerHeader = styled.div`
	padding: 2rem 1.25rem;
`;

export const Title = styled.div`
	font-size: 1.5rem;
	font-weight: 600;
`;

export const TitleAndSaveContainer = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
`;

const StyledMassBalanceDesigner = styled.div`
	width: 100%;
	height: 25rem;
	background: #fff;
	margin: 0.625rem;
`;

export default StyledMassBalanceDesigner;
