import {
	MapBounds,
	BackgroundTypes,
	MapHiddenLayers,
} from '@innovyze/inno-map';
import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

interface MapState {
	bounds: MapBounds;
	background: BackgroundTypes;
	selected: string[];
	url: string;
	hiddenLayers: MapHiddenLayers;
}

export const useMapState = () => {
	const history = useHistory();
	const location = useLocation();

	const getParamsFromURL = (url: string) => {
		const params = url.split('/');
		if (params.length < 2) {
			throw new Error();
		}
		const [x1, y1, x2, y2] = params[2]
			.split(',')
			.map(item => parseFloat(item));
		const urlBounds = [
			[x1, y1],
			[x2, y2],
		];
		const urlBackground = params[3] as MapState['background'];

		let urlSelected: string[] = [];
		if (params[4] && params[4] !== '' && params[4] !== '-') {
			urlSelected = params[4].split(',') || [];
		}
		let urlHiddenLayers: MapHiddenLayers = [];
		if (params[5] && params[5] !== '') {
			urlHiddenLayers = params[5].split(',') || [];
		}
		return { urlBounds, urlBackground, urlSelected, urlHiddenLayers };
	};

	const {
		urlBounds,
		urlBackground,
		urlSelected,
		urlHiddenLayers,
	} = getParamsFromURL(location.pathname);

	const [state, setState] = useState<MapState>({
		bounds: urlBounds,
		background: urlBackground,
		selected: urlSelected,
		hiddenLayers: urlHiddenLayers,
		url: location.pathname,
	});

	const formatUrl = ({
		bounds,
		background,
		selected,
		hiddenLayers,
	}: MapState) => {
		const url = ['map', bounds, background];
		if (!selected.length && hiddenLayers.length) {
			url.push('-');
		} else if (selected.length) {
			url.push(`${selected}`);
		}
		if (hiddenLayers.length) {
			url.push(`${hiddenLayers}`);
		}

		return `/${url.join('/')}`;
	};

	const setBounds = (newBounds: MapState['bounds']) => {
		const newState = { ...state, bounds: newBounds };
		const url = formatUrl(newState);
		setState({ ...newState, url });
	};

	const setBackground = (newBackground: MapState['background']) => {
		const newState = { ...state, background: newBackground };
		const url = formatUrl(newState);
		setState({ ...newState, url });
	};

	const setSelected = (newSelected: MapState['selected']) => {
		const newState = { ...state, selected: newSelected };
		const url = formatUrl(newState);
		setState({ ...newState, url });
	};

	const setHiddenLayers = (newHiddenLayers: MapState['hiddenLayers']) => {
		const newState = { ...state, hiddenLayers: newHiddenLayers };
		const url = formatUrl(newState);
		setState({ ...newState, url });
	};

	useEffect(() => {
		if (location.pathname !== state.url) {
			history.push(state.url);
		}
	}, [state.url]);

	useEffect(() => {
		if (location.pathname !== state.url) {
			let newState = { ...state, url: location.pathname };
			if (`${urlBounds}` !== `${state.bounds}`) {
				newState = { ...newState, bounds: urlBounds };
			}
			if (urlBackground !== state.background) {
				newState = { ...newState, background: urlBackground };
			}
			if (`${urlSelected}` !== `${state.selected}`) {
				newState = { ...newState, selected: urlSelected };
			}
			if (`${urlHiddenLayers}` !== `${state.hiddenLayers}`) {
				newState = { ...newState, hiddenLayers: urlHiddenLayers };
			}
			setState(newState);
		}
	}, [location.pathname]);

	return {
		...state,
		setBounds,
		setBackground,
		setSelected,
		setHiddenLayers,
	};
};
