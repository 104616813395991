import React from 'react';
import { WorkspaceItem, DashletType } from '../../../../types/workspace';
import DefaultChartWidget from '../DashletContent/DashletChart';
import ChartRangeSelector from '../DashletContent/DashletChartRange';
import DefaultGaugeWidget from '../DashletContent/DashletGauge';
import * as Dashlet from './GenericDashlet.style';
import { MapComp } from '../DashletContent/DashletMap';
import {
	defaultMapConfig,
	defaultMapToken,
} from '../../WorkspaceGrid/WorkspaceGridService';
import DefaultChartHeader from '../DashletContent/DashletChartHeader';

interface Props {
	widgetItem: WorkspaceItem;
	onUpdateDashletHandler: (wsItem: WorkspaceItem) => void;
}

const DashletContent = ({ widgetItem, onUpdateDashletHandler }: Props) => {
	const onRangeOptionsChange = (wsItem: WorkspaceItem) => {
		onUpdateDashletHandler(wsItem);
	};

	const onHeaderDateTimeChange = (wsItem: WorkspaceItem) => {
		onUpdateDashletHandler(wsItem);
	};

	switch (widgetItem.dashletTypeId) {
		case DashletType.Chart:
			return (
				<>
					<Dashlet.WidgetContent
						id={'widgetContent' + widgetItem.id}
						className="react-grid-item-content">
						<Dashlet.ChartHeader>
							<DefaultChartHeader
								item={widgetItem}
								onHeaderDateTimeChange={onHeaderDateTimeChange}
							/>
						</Dashlet.ChartHeader>
						<Dashlet.ChartView>
							<DefaultChartWidget item={widgetItem} />
							<Dashlet.ChartRangeView>
								<ChartRangeSelector
									item={widgetItem}
									onRangeOptionsChange={onRangeOptionsChange}
								/>
							</Dashlet.ChartRangeView>
						</Dashlet.ChartView>
					</Dashlet.WidgetContent>
				</>
			);
		case DashletType.Gauge:
			return (
				<>
					<Dashlet.WidgetContent id={'widgetContent' + widgetItem.id}>
						<Dashlet.GaugeView>
							<DefaultGaugeWidget item={widgetItem} />
						</Dashlet.GaugeView>
					</Dashlet.WidgetContent>
				</>
			);
		case DashletType.Map:
			return (
				<>
					<Dashlet.WidgetContent id={'widgetContent' + widgetItem.id}>
						<MapComp
							config={defaultMapConfig}
							token={defaultMapToken}
						/>
					</Dashlet.WidgetContent>
				</>
			);

		default:
			return <></>;
	}
};

export default DashletContent;
