export interface DrawProps {
	width: number;
	height: number;
}

export enum Side {
	Both = 2,
	One = 1,
}

export interface BufferImage {
	width: number;
	height: number;
	data: Uint8Array | Uint8ClampedArray;
}

export default class Draw {
	static padding = 2;
	private _width = 0;
	private _height = 0;
	canvas: HTMLCanvasElement;
	ctx: CanvasRenderingContext2D | null;
	fillDefault = '#000';

	constructor({ width, height }: DrawProps) {
		this.canvas = document.createElement('canvas');
		this.width = width;
		this.height = height;
		// to prevent the icon being cutoff on the edges padding is to be added
		this.canvas.width = this.addPadding(width, Side.Both);
		this.canvas.height = this.addPadding(height, Side.Both);
		this.ctx = this.canvas.getContext('2d');
	}

	set width(width: number) {
		this._width = width;
	}

	get width(): number {
		return this._width;
	}

	set height(height: number) {
		this._height = height;
	}

	get height(): number {
		return this._height;
	}

	addPadding(size: number, side: Side = Side.One): number {
		return size + Draw.padding * side;
	}

	adjustOffset(x: number, y: number): [number, number] {
		return [this.addPadding(x), this.addPadding(y)];
	}

	asImage(): string {
		return this.canvas.toDataURL();
	}

	asBuffer(): BufferImage {
		const { width, height } = this.canvas;
		const img = this.ctx?.getImageData(0, 0, width, height);
		const data = img?.data as Uint8ClampedArray;
		return { width, height, data };
	}

	rect(
		x: number,
		y: number,
		width: number,
		height: number,
		style?: string,
	): void {
		if (!this.ctx) return;
		// automatically move drawing inside padded area to avoid cut-off
		[x, y] = this.adjustOffset(x, y);
		this.ctx.fillStyle = style || this.fillDefault;
		this.ctx.fillRect(x, y, width, height);
	}

	roundedRect(
		x: number,
		y: number,
		width: number,
		height: number,
		radius: number,
		mode: 'fill' | 'stroke',
		style?: string,
		lineWidth?: number,
	): void {
		if (!this.ctx) return;
		// automatically move drawing inside padded area to avoid cut-off
		[x, y] = this.adjustOffset(x, y);
		this.ctx.beginPath();
		this.ctx.moveTo(x, y + radius);
		this.ctx.lineTo(x, y + height - radius);
		this.ctx.arcTo(x, y + height, x + radius, y + height, radius);
		this.ctx.lineTo(x + width - radius, y + height);
		this.ctx.arcTo(
			x + width,
			y + height,
			x + width,
			y + height - radius,
			radius,
		);
		this.ctx.lineTo(x + width, y + radius);
		this.ctx.arcTo(x + width, y, x + width - radius, y, radius);
		this.ctx.lineTo(x + radius, y);
		this.ctx.arcTo(x, y, x, y + radius, radius);
		if (mode === 'fill') {
			this.ctx.fillStyle = style || this.fillDefault;
		} else {
			this.ctx.strokeStyle = style || this.fillDefault;
		}
		if (lineWidth) this.ctx.lineWidth = lineWidth;
		this.ctx[mode]();
	}

	write(text: string, style?: string): void {
		if (!this.ctx) return;
		this.ctx.fillStyle = style || this.fillDefault;
		this.ctx.font = '16px sans-serif';
		const textMetrics = this.ctx.measureText(text);
		const x = (this.canvas.width - textMetrics.width) / 2;
		const y = (this.canvas.height + 9) / 2;
		this.ctx.fillText(text, x, y);
	}
}
