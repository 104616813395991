import Draw, { DrawProps } from './Draw';

type WaterTankProps = {
	fill: number;
	selectedVariant?: boolean;
} & Partial<DrawProps>;

export default class WaterTank extends Draw {
	private _waterOneSidePadding = 4;
	private _radius = 4;
	private _fill = 0;
	private _selectedVariant: boolean;

	constructor({
		fill,
		selectedVariant = false,
		width = 65,
		height = 72,
	}: WaterTankProps) {
		super({ width, height });
		this.fill = fill;
		this._selectedVariant = selectedVariant;
		this.draw();
	}

	set fill(fill: number) {
		this._fill = fill;
	}

	get fill(): number {
		return this._fill;
	}

	get fillPercent(): number {
		return this._fill / 100;
	}

	get waterPadding(): number {
		return this._waterOneSidePadding * 2;
	}

	get waterOffset(): [number, number] {
		const x = this._waterOneSidePadding;
		const y = this.height - (this.waterHeight + this._waterOneSidePadding);
		return [x, y];
	}

	get waterHeight(): number {
		return this.height * this.fillPercent - this.waterPadding;
	}

	get waterWidth(): number {
		return this.width - this.waterPadding;
	}

	draw(): void {
		// draw tank
		this.roundedRect(
			0,
			0,
			this.width,
			this.height,
			this._radius,
			'fill',
			'#fff',
		);
		this.roundedRect(
			0,
			0,
			this.width,
			this.height,
			this._radius,
			'stroke',
			this._selectedVariant ? '#764abc' : '#00abd1',
			3,
		);
		// draw water level
		this._drawWater();

		this.write(this.fill + '%');
	}

	private _drawWater(): void {
		const [x, y] = this.waterOffset;
		this.rect(
			x,
			y,
			this.waterWidth,
			this.waterHeight,
			this._selectedVariant ? '#9771d250' : '#00a7ff40',
		);
	}
}
