import React from 'react';
import { StyledLoading } from './Loading.styles';
import { CircularProgress } from '@material-ui/core';

interface LoadingProps {
	loading?: boolean;
	children?: React.ReactNode;
}

const Loading = ({ loading, children }: LoadingProps) => {
	if (loading) {
		return (
			<>
				<StyledLoading data-testing-id={'loading-component'}>
					<CircularProgress />
				</StyledLoading>
			</>
		);
	}
	return <>{children}</>;
};

export default Loading;
