import styled from 'styled-components';
import { rem } from '../../util/styles';

const StyledPages = styled.div`
	display: flex;
	height: calc(100% - ${rem(50)});
`;

export default StyledPages;

export const Page = styled.main`
	width: 100%;
	overflow-y: auto;
`;
