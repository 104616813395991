import { AppBar, Card, CardContent, Tab, Tabs } from '@material-ui/core';
import React from 'react';
import {
	Display,
	SidePanelOption,
	WorkspaceItem,
	SidePanelContent,
} from '../../../types/workspace';
import FocusModeDashlet from './Dashlet/FocusModeDashlet';
import { StyledDashletDesigner } from './FocusToolBar.Style';

interface Props {
	sidePanelOption: SidePanelOption;
	activeWorkspaceItem: WorkspaceItem;
	handelMenuChangeFromFocus: (mode: number) => void;
}

function a11yProps(index: number) {
	return {
		id: `dashletDesigner-tab-${index}`,
		'aria-controls': `dashletDesigner-tabpanel-${index}`,
	};
}

const FocusToolBar = ({
	sidePanelOption,
	activeWorkspaceItem,
	handelMenuChangeFromFocus,
}: Props) => {
	const handleTabChange = (
		event: React.ChangeEvent<{}>,
		newValue: number,
	) => {
		switch (newValue) {
			case 0:
				handelMenuChangeFromFocus(Display.Properties);
				break;
			case 1:
				handelMenuChangeFromFocus(Display.ReferenceChart);
				break;
			case 2:
				handelMenuChangeFromFocus(Display.DisplayOptions);
				break;
			case 3:
				handelMenuChangeFromFocus(Display.ToggleView);
				break;
			default:
				break;
		}
	};

	const onUpdateDashletHandler = (wsItem: WorkspaceItem) => {
		console.log(' (wsItem: WorkspaceItem) ', wsItem);
	};

	const { displayMode, contentDisplayMode } = sidePanelOption;

	const getTabValue = (): number => {
		switch (contentDisplayMode) {
			case Display.Properties:
				return 0;
			case Display.ReferenceChart:
				return 1;
			case Display.DisplayOptions:
				return 2;
			default:
				return 3;
		}
	};

	if (displayMode === SidePanelContent.CreateChart) {
		return (
			<>
				<AppBar position="static" color="default">
					<Tabs
						value={getTabValue()}
						onChange={handleTabChange}
						indicatorColor="primary"
						textColor="primary"
						aria-label="Dashlet Designer Tabs">
						<Tab label="Properties" {...a11yProps(0)} />
						<Tab label="Reference Chart" {...a11yProps(1)} />
						<Tab label="Display Options" {...a11yProps(2)} />
						<Tab label="Toggle View" {...a11yProps(3)} />
					</Tabs>
				</AppBar>
				<Card>
					<CardContent>
						<StyledDashletDesigner>
							<FocusModeDashlet
								widgetItem={activeWorkspaceItem}
								onUpdateDashletHandler={onUpdateDashletHandler}
							/>
						</StyledDashletDesigner>
					</CardContent>
				</Card>
			</>
		);
	} else if (
		displayMode === SidePanelContent.CreateMap ||
		displayMode === SidePanelContent.CreateGauge
	) {
		return (
			<>
				<AppBar position="static" color="default">
					<Tabs
						value={getTabValue()}
						onChange={handleTabChange}
						indicatorColor="primary"
						textColor="primary"
						aria-label="Dashlet Designer Tabs">
						<Tab label="Properties" {...a11yProps(0)} />
					</Tabs>
				</AppBar>
				<Card>
					<CardContent>
						<StyledDashletDesigner>
							<FocusModeDashlet
								widgetItem={activeWorkspaceItem}
								onUpdateDashletHandler={onUpdateDashletHandler}
							/>
						</StyledDashletDesigner>
					</CardContent>
				</Card>
			</>
		);
	} else {
		return <></>;
	}
};

export default FocusToolBar;
