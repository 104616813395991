// using webpack dependency-management to load all the files in the directory
// https://webpack.js.org/guides/dependency-management/#require-context
const importAll = require =>
	require.keys().reduce((acc, next) => {
		acc[next.replace('./', '').replace(/\.svg$/, '')] = require(next);
		return acc;
	}, {});

// arguments passed to require.context must be literals
const icons = importAll(require.context('.', false, /\.svg$/));

module.exports = icons;
